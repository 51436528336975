import React, { useRef, useCallback, useState, useEffect } from 'react';
import EmailEditor from 'react-email-editor';
import Button from '../../../../components/Button/Button';
import supabaseConnect from '../../../../utils/supabaseConnect';
import { Menu } from '@headlessui/react';
import TemplateSelectionModal from './TemplateSelectionModal';
import { EmailTemplate, TEMPLATE_CATEGORIES, TemplateCategory, UnlayerTools } from './types';
import useUser from '../../../../utils/supabase/useUser';
import { DynamicContentBlock, ContentType, DisplayMode, TimeRange } from './ContentSelector/types';
import { generateDynamicContent } from './ContentSelector/generateDynamicContent';
import { fetchDynamicContent } from './utils/fetchDynamicContent';

declare global {
  interface Window {
    supabaseConnect?: typeof supabaseConnect;
    generateDynamicContent?: typeof generateDynamicContent;
    fetchDynamicContent?: typeof fetchDynamicContent;
    dynamicContentRenderer?: {
      render: (values: any) => string;
      exporters: {
        web: (values: any) => string;
        email: (values: any) => string;
      };
    };
  }
}

interface EmailTemplateWithDynamicContent extends EmailTemplate {
  id: number;
  name: string;
  design: {
    // Existing Unlayer design structure
    dynamicContent?: DynamicContentBlock[];
  };
  html: string | null;
  created_at: string;
  updated_at: string;
  category: string;
  profile_id: string | null;
}

const DropdownMenu: React.FC<{
  label: string;
  items: { label: string; onClick: () => void; disabled?: boolean; preventClose?: boolean }[];
}> = ({ label, items }) => (
  <Menu as="div" className="relative">
    <Menu.Button className="px-3 py-1 text-white hover:bg-communityBlue rounded cursor-pointer bg-gray-700 bg-opacity-50">
      {label}
    </Menu.Button>
    <Menu.Items className="absolute left-0 mt-1 w-56 bg-gray-700 border border-[#2a2828] rounded-md shadow-lg z-50">
      {items.map((item, index) => (
        <Menu.Item key={index}>
          {({ active, close }) => (
            <button
              onClick={(e) => {
                if (item.preventClose) {
                  e.preventDefault();
                }
                item.onClick();
                if (!item.preventClose) {
                  close();
                }
              }}
              disabled={item.disabled}
              className={`${
                active ? 'bg-communityBlue text-white cursor-pointer' : 'text-white bg-gray-700'
              } ${
                item.disabled ? 'opacity-50 cursor-not-allowed' : ''
              } group flex w-full items-center px-4 py-2 text-sm`}
            >
              {item.label}
            </button>
          )}
        </Menu.Item>
      ))}
    </Menu.Items>
  </Menu>
);

/**
 * EmailTemplateBuilder component that provides a drag-and-drop interface for creating email templates
 * Uses Unlayer's EmailEditor component for the main functionality
 * Customized with site colors and theme preferences
 */
const EmailTemplateBuilder: React.FC = (): JSX.Element => {
  const emailEditorRef = useRef<any>(null);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isDynamicContentModalOpen, setIsDynamicContentModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editingTitle, setEditingTitle] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [dynamicContentConfig, setDynamicContentConfig] = useState<{
    contentType: ContentType;
    displayMode: DisplayMode;
    columns: number;
    showImages: boolean;
    showDates: boolean;
    showDescriptions: boolean;
    truncateDescription: number;
    timeRange: TimeRange;
  }>({
    contentType: 'ANNOUNCEMENT',
    displayMode: 'GRID',
    columns: 2,
    showImages: true,
    showDates: true,
    showDescriptions: true,
    truncateDescription: 150,
    timeRange: 'LAST_7_DAYS'
  });

  const user = useUser();
  const supabase = supabaseConnect();  // Create Supabase client once

  // Expose supabaseConnect to window for the editor iframe to access
  useEffect(() => {
    window.supabaseConnect = () => supabase;
    return () => {
      delete window.supabaseConnect;
    };
  }, [supabase]);

  // Add event listener for dynamic content modal
  useEffect(() => {
    const handleOpenDynamicContentModal = (event: MessageEvent) => {
      console.log('Modal open event received in React component:', event.data);
      if (event.data.type === 'openDynamicContentModal') {
        setIsDynamicContentModalOpen(true);
        // Store the block ID in state
        setActiveBlockId(event.data.blockId);
      }
    };

    console.log('Setting up event listener for openDynamicContentModal');
    window.addEventListener('message', handleOpenDynamicContentModal);

    return () => {
      console.log('Removing event listener for openDynamicContentModal');
      window.removeEventListener('message', handleOpenDynamicContentModal);
    };
  }, []);

  // Add state for tracking active block
  const [activeBlockId, setActiveBlockId] = useState<string | null>(null);

  // Filter templates based on selected category
  const filteredTemplates = selectedCategory === 'All' 
    ? templates 
    : templates.filter(template => template.category === selectedCategory);

  /**
   * Loads all templates from the database
   */
  const loadTemplates = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('email_templates')
        .select('*')
        .order('updated_at', { ascending: false });

      if (error) throw error;
      setTemplates(data || []);
    } catch (error) {
      console.error('Error loading templates:', error);
    } finally {
      setIsLoading(false);
    }
  }, [supabase]);

  /**
   * Loads templates when component mounts
   */
  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  /**
   * Saves the current design as a new template
   */
  const handleSaveDesign = useCallback(async (providedName?: string) => {
    if (!emailEditorRef.current || !user) return;

    try {
      setIsLoading(true);
      const templateName = providedName || window.prompt('Enter template name:');
      if (!templateName) return;

      const design = await new Promise((resolve) => {
        emailEditorRef.current.editor.saveDesign((design: any) => resolve(design));
      });

      const html = await new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml((data: any) => resolve(data.html));
      });

      const { data, error } = await supabase
        .from('email_templates')
        .insert([
          {
            name: templateName,
            category: 'General',
            design,
            html,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            profile_id: user.profile?.id || '',
          },
        ])
        .select()
        .single();

      if (error) throw error;
      
      if (data) {
        setSelectedTemplate(data);
        setEditingTitle(templateName);
      }
      
      await loadTemplates();
    } catch (error) {
      console.error('Error saving template:', error);
    } finally {
      setIsLoading(false);
    }
  }, [supabase, user, loadTemplates]);

  /**
   * Loads a selected template into the editor
   */
  const handleLoadTemplate = useCallback((template: EmailTemplate) => {
    if (!emailEditorRef.current || !template.design) return;
    
    emailEditorRef.current.editor.loadDesign(template.design);
    setSelectedTemplate(template);
  }, []);

  /**
   * Updates an existing template
   */
  const handleUpdateTemplate = useCallback(async () => {
    if (!emailEditorRef.current || !selectedTemplate) return;

    try {
      setIsLoading(true);
      const design = await new Promise((resolve) => {
        emailEditorRef.current.editor.saveDesign((design: any) => resolve(design));
      });

      const html = await new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml((data: any) => resolve(data.html));
      });

      const { error } = await supabase
        .from('email_templates')
        .update({
          design,
          html,
          updated_at: new Date().toISOString(),
        })
        .eq('id', selectedTemplate.id);

      if (error) throw error;
      await loadTemplates();
    } catch (error) {
      console.error('Error updating template:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedTemplate, supabase, loadTemplates]);

  const handleUpdateTemplateName = async (templateId: number, newName: string) => {
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('email_templates')
        .update({ name: newName })
        .eq('id', templateId);

      if (error) throw error;
      await loadTemplates();
      
      // Update selected template name if it's the current template
      if (selectedTemplate?.id === templateId) {
        setSelectedTemplate({ ...selectedTemplate, name: newName });
      }
    } catch (error) {
      console.error('Error updating template name:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartTitleEdit = () => {
    setIsEditingTitle(true);
    setEditingTitle(selectedTemplate?.name || 'Untitled template');
  };

  const handleSaveTitle = async () => {
    const newTitle = editingTitle.trim() || 'Untitled template';
    
    if (selectedTemplate?.id) {
      await handleUpdateTemplateName(selectedTemplate.id, newTitle);
    } else {
      // If no template is selected, create a new one with this name
      await handleSaveDesign(newTitle);
    }
    
    // Update selected template name if it's the current template
    if (selectedTemplate) {
      setSelectedTemplate({
        ...selectedTemplate,
        name: newTitle
      });
    }
    
    setIsEditingTitle(false);
    setEditingTitle(newTitle);
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveTitle();
    } else if (e.key === 'Escape') {
      setIsEditingTitle(false);
      setEditingTitle(selectedTemplate?.name || 'Untitled template');
    }
  };

  const fileMenuItems = [
    {
      label: 'New Template',
      onClick: async () => {
        if (emailEditorRef.current?.editor) {
          await emailEditorRef.current.editor.loadDesign({
            body: {
              rows: []
            },
            supabaseConnect: supabase.toString()
          });
          setSelectedTemplate(null);
          setEditingTitle('Untitled template');
        }
      }
    },
    {
      label: 'Save',
      onClick: handleUpdateTemplate,
      disabled: !selectedTemplate
    },
    {
      label: 'Save As...',
      onClick: handleSaveDesign
    },
    {
      label: 'Open Template',
      onClick: () => setIsTemplateModalOpen(true)
    },
    {
      label: isConfirmingDelete ? 'Confirm Delete?' : 'Delete',
      onClick: () => {
        if (isConfirmingDelete) {
          if (selectedTemplate?.id) {
            handleDeleteTemplate(selectedTemplate.id);
          }
          setIsConfirmingDelete(false);
        } else {
          setIsConfirmingDelete(true);
          setTimeout(() => setIsConfirmingDelete(false), 3000);
        }
      },
      disabled: !selectedTemplate,
      preventClose: !isConfirmingDelete
    }
  ];

  const helpMenuItems = [
    {
      label: 'Keyboard Shortcuts',
      onClick: () => {
        alert('Keyboard shortcuts coming soon!');
      }
    },
    {
      label: 'Documentation',
      onClick: () => {
        window.open('https://docs.unlayer.com/', '_blank');
      }
    }
  ];

  const handleDeleteTemplate = async (templateId: number) => {
    
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('email_templates')
        .delete()
        .eq('id', templateId);

      if (error) throw error;
      
      // If the deleted template was selected, clear it
      if (selectedTemplate?.id === templateId) {
        setSelectedTemplate(null);
        setEditingTitle('Untitled template');
        emailEditorRef.current?.editor.loadDesign({
          body: {
            rows: []
          },
          supabaseConnect: supabase.toString()
        });
      }
      
      await loadTemplates();
    } catch (error) {
      console.error('Error deleting template:', error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles the insertion of dynamic content blocks into the email template
   * Converts the dynamic content block into an Unlayer compatible format and inserts it
   */
  const handleInsertDynamicContent = useCallback(async (block: DynamicContentBlock) => {
    if (!emailEditorRef.current?.editor) return;

    try {
      setIsLoading(true);
      console.log('Fetching dynamic content with block:', block);
      console.log('Active block ID:', activeBlockId);

      // Fetch the data using our utility function
      const data = await fetchDynamicContent({ block });
      console.log('[DEBUG] Fetched dynamic content data:', data?.length, 'items');

      // If no data is returned, create a placeholder message but continue with the insertion
      let contentHtml;
      if (!data?.length) {
        console.warn('No content found for the selected time range');
        contentHtml = `
          <div class="no-content-message" style="text-align: center; padding: 30px; background-color: #f8f9fa; border: 1px dashed #dee2e6; border-radius: 8px; margin: 20px 0;">
            <h3 style="color: #6c757d; font-size: 18px; margin-bottom: 10px;">No Content Available</h3>
            <p style="color: #6c757d; font-size: 14px;">No content was found for the selected time range. This message will be replaced with actual content when it becomes available.</p>
          </div>
        `;
      } else {
        // Generate the HTML content
        contentHtml = generateDynamicContent(data, block);
      }
      
      console.log('[DEBUG] Generated content HTML length:', contentHtml?.length);

      // Create the final HTML with proper styling - simplified to avoid nesting issues
      const html = `
        <style>
          .dynamic-content-container {
            font-family: Arial, sans-serif;
            color: #333;
            padding: 20px;
          }
          .dynamic-content-grid {
            display: grid;
            grid-template-columns: repeat(${block.layout.columns}, 1fr);
            gap: 20px;
          }
          .dynamic-content-list {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
          .content-item {
            background: #fff;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            padding: 16px;
            margin-bottom: 16px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          }
          .content-item img {
            width: 100%;
            height: auto;
            max-height: 200px;
            object-fit: cover;
            border-radius: 4px;
            margin-bottom: 12px;
          }
          .content-item-body {
            padding: 8px 0;
          }
          .content-item-title {
            font-size: 18px;
            font-weight: 600;
            color: #2d3748;
            margin: 0 0 8px 0;
            line-height: 1.4;
          }
          .content-item-date {
            color: #718096;
            font-size: 14px;
            margin-bottom: 8px;
          }
          .content-item-description {
            color: #4a5568;
            font-size: 14px;
            line-height: 1.6;
          }
          .no-content {
            text-align: center;
            padding: 40px;
            color: #718096;
            font-size: 16px;
            border: 2px dashed #e2e8f0;
            border-radius: 8px;
          }
        </style>
        ${contentHtml}
      `.trim();

      console.log('[DEBUG] Final HTML length:', html.length);

      // Generate a new block ID if one doesn't exist
      const blockId = activeBlockId || Math.random().toString(36).substr(2, 9);
      console.log('[DEBUG] Using block ID for insertion:', blockId);

      // Save the current design to get access to it
      emailEditorRef.current.editor.saveDesign(async (design: any) => {
        try {
          console.log('[DEBUG] Current design structure:', JSON.stringify({
            bodyRowsCount: design.body?.rows?.length || 0,
            hasBody: !!design.body
          }));

          const existingRows = design.body?.rows || [];
          console.log('\n[DEBUG] ---- BEFORE UPDATE ----');
          console.log('[DEBUG] Total rows:', existingRows.length);

          // Find the block to replace by searching for the exact block ID
          let targetRow = null;
          let targetIndex = -1;

          if (blockId) {
            console.log('\n[DEBUG] Searching for target block ID:', blockId);
            
            targetIndex = existingRows.findIndex((row: any) => {
              const content = row.columns?.[0]?.contents?.[0];
              if (!content) {
                return false;
              }
              
              // Parse values if it's a string
              let parsedValues = content.values;
              try {
                if (typeof content.values === 'string') {
                  parsedValues = JSON.parse(content.values);
                }
              } catch (e) {
                console.log('[DEBUG] Failed to parse values:', e);
              }
              
              // Check for blockId in various places
              const metaBlockId = parsedValues?._meta?.blockId;
              const directBlockId = parsedValues?.blockId;
              
              console.log('[DEBUG] Comparing block IDs:', {
                metaBlockId,
                directBlockId,
                targetBlockId: blockId,
                matches: metaBlockId === blockId || directBlockId === blockId
              });
              
              // Check if any of the blockIds match
              return metaBlockId === blockId || directBlockId === blockId;
            });

            console.log('[DEBUG] Target block found:', targetIndex !== -1 ? 'YES' : 'NO', 'at index:', targetIndex);

            if (targetIndex !== -1) {
              targetRow = existingRows[targetIndex];
            }
          }

          let updatedRows;
          if (targetRow) {
            console.log('\n[DEBUG] Updating existing row at index:', targetIndex);
            
            // Create updated row with the new content
            const updatedRow = {
              ...targetRow,
              columns: [{
                ...targetRow.columns[0],
                contents: [{
                  type: 'custom',
                  values: {
                    _meta: {
                      blockId: blockId,
                      htmlID: `u_content_custom_dynamic-content_${Date.now()}`,
                      htmlClassNames: 'u_content_custom_dynamic-content'
                    },
                    dynamicContent: html,
                    contentType: block.type,
                    displayMode: block.displayMode,
                    columns: block.layout.columns,
                    showImages: block.layout.showImages,
                    showDates: block.layout.showDates,
                    showDescriptions: block.layout.showDescriptions,
                    truncateDescription: block.layout.contentTransforms?.truncateDescription,
                    timeRange: block.filter.timeRange
                  }
                }]
              }]
            };

            updatedRows = [
              ...existingRows.slice(0, targetIndex),
              updatedRow,
              ...existingRows.slice(targetIndex + 1)
            ];
          } else {
            console.log('\n[DEBUG] Creating new row at the end');
            // Create a new row
            const newRow = {
              cells: [1],
              columns: [{
                contents: [{
                  type: 'custom',
                  values: {
                    _meta: {
                      blockId: blockId,
                      htmlID: `u_content_custom_dynamic-content_${Date.now()}`,
                      htmlClassNames: 'u_content_custom_dynamic-content'
                    },
                    dynamicContent: html,
                    contentType: block.type,
                    displayMode: block.displayMode,
                    columns: block.layout.columns,
                    showImages: block.layout.showImages,
                    showDates: block.layout.showDates,
                    showDescriptions: block.layout.showDescriptions,
                    truncateDescription: block.layout.contentTransforms?.truncateDescription,
                    timeRange: block.filter.timeRange
                  }
                }],
                values: {}
              }],
              values: {
                backgroundColor: '',
                columnsBackgroundColor: '',
                padding: '0px'
              }
            };
            updatedRows = [...existingRows, newRow];
          }

          // Create the updated design with a timestamp to force refresh
          const updatedDesign = {
            ...design,
            body: {
              ...design.body,
              rows: updatedRows,
              timestamp: Date.now()
            }
          };

          console.log('\n[DEBUG] ---- AFTER UPDATE ----');
          console.log('[DEBUG] Total rows:', updatedRows.length);

          // Load the updated design and force a refresh
          console.log('\n[DEBUG] Loading updated design...');
          await new Promise((resolve) => {
            emailEditorRef.current.editor.loadDesign(updatedDesign, () => {
              console.log('[DEBUG] Initial design load complete');
              // Force a re-render by exporting and notifying the editor
              emailEditorRef.current.editor.exportHtml((data: any) => {
                console.log('[DEBUG] HTML export complete');
                resolve(data);
              });
            });
          });

          // Force another refresh after a short delay
          console.log('[DEBUG] Scheduling delayed refresh...');
          setTimeout(() => {
            console.log('[DEBUG] Executing delayed refresh');
            emailEditorRef.current.editor.loadDesign(updatedDesign);
          }, 100);

        } catch (error) {
          console.error('Error updating design:', error);
          throw error;
        }
      });

    } catch (error: any) {
      console.error('Error inserting dynamic content:', error);
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
      alert(`Failed to insert dynamic content: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [activeBlockId]);

  const handleDynamicContentChange = (
    field: keyof typeof dynamicContentConfig,
    value: string | number | boolean
  ) => {
    setDynamicContentConfig(prev => ({
      ...prev,
      [field]: value as any // We know the types match from our controlled inputs
    }));

    // Handle dependent fields
    if (field === 'displayMode') {
      if (value === 'LIST') {
        setDynamicContentConfig(prev => ({
          ...prev,
          columns: 1,
          showImages: false
        }));
      }
    }
  };

  // Add debug logging for modal state changes
  useEffect(() => {
    console.log('Modal state changed:', { 
      isDynamicContentModalOpen, 
      activeBlockId 
    });
  }, [isDynamicContentModalOpen, activeBlockId]);

  return (
    <div className="flex flex-col h-full">
      {/* Google Docs-like Header */}
      <div className="flex flex-col bg-[#100f0f] border-b border-[#2a2828]">
        {/* Top Bar */}
        <div className="flex items-center px-4 py-2.5 border-b border-[#2a2828]">
          <div className="flex items-center gap-4">
            {/* Logo/Icon Placeholder */}
            <div className="w-6 h-6 bg-[#2a2828] rounded flex items-center justify-center">
              <svg 
                className="w-4 h-4 text-[#f1f2f6]" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" />
              </svg>
            </div>
            <div className="flex flex-col w-full">
              {isEditingTitle ? (
                <input
                  type="text"
                  value={editingTitle}
                  onChange={(e) => setEditingTitle(e.target.value)}
                  onBlur={handleSaveTitle}
                  onKeyDown={handleTitleKeyDown}
                  className="bg-[#2a2828] text-[#f1f2f6] text-lg font-medium leading-none px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-communityBlue"
                  autoFocus
                />
              ) : (
                <span 
                  className="text-[#f1f2f6] text-lg font-medium leading-none cursor-pointer hover:text-communityBlue"
                  onClick={handleStartTitleEdit}
                >
                  {selectedTemplate ? selectedTemplate.name : 'Untitled template'}
                </span>
              )}
              <span className="text-[#f1f2f6]/50 text-xs mt-0.5">
                Email Template
              </span>
            </div>
          </div>
        </div>
        
        <div className="flex items-center border-b border-[#2a2828]/50 mb-2">
          <div className="flex items-center gap-1 ml-14 my-1"> 
            <DropdownMenu label="File" items={fileMenuItems} />
          </div>
        </div>
      </div>

      {/* Editor */}
      <div className="flex-1 min-h-[700px]">
        <EmailEditor
          ref={emailEditorRef}
          onLoad={() => {
            console.log('[React] Editor onLoad triggered');
            
            // Set up message handler for the dynamic content modal
            const messageHandler = (event: MessageEvent) => {
              console.log('[React] Message received:', {
                type: event.data?.type,
                blockId: event.data?.blockId,
                source: event.data?.source,
                origin: event.origin,
                data: event.data
              });
              
              // Only handle messages from our editor
              if (event.origin.includes('editor.unlayer.com') || event.origin.includes('localhost')) {
                if (event.data?.type === 'openDynamicContentModal') {
                  console.log('[React] Processing openDynamicContentModal message');
                  const blockId = event.data.blockId || null;
                  console.log('[React] Setting modal state with blockId:', blockId);
                  
                  // Use requestAnimationFrame to ensure we're in a clean state
                  requestAnimationFrame(() => {
                    setIsDynamicContentModalOpen(true);
                    setActiveBlockId(blockId);
                  });
                }
              }
            };
            
            console.log('[React] Setting up message event listener');
            // Remove any existing handler to avoid duplicates
            window.removeEventListener('message', messageHandler);
            window.addEventListener('message', messageHandler);
            
            // Cleanup function
            return () => {
              console.log('[React] Cleaning up message event listener');
              window.removeEventListener('message', messageHandler);
            };
          }}
          minHeight="700px"
          options={{
            appearance: {
              theme: 'dark',
              panels: {
                tools: {
                  dock: 'right'
                }
              }
            },
            customJS: `
              (function() {
                console.log('[CustomJS] Initializing dynamic content tool setup...');

                // Define the window function before registering the tool
                if (!window.openDynamicContentModal) {
                  window.openDynamicContentModal = function(blockId, event) {
                    console.log('[CustomJS] openDynamicContentModal called with blockId:', blockId);
                    if (event) {
                      event.stopPropagation();
                      event.preventDefault();
                    }
                    
                    try {
                      console.log('[CustomJS] Attempting to post message to parent');
                      window.parent.postMessage({
                        type: 'openDynamicContentModal',
                        blockId: blockId || '',
                        source: 'unlayer-editor'
                      }, '*');
                      console.log('[CustomJS] Message posted successfully');
                    } catch (error) {
                      console.error('[CustomJS] Error posting message:', error);
                    }
                    
                    return false;
                  };
                }

                function createConfigButton(blockId) {
                  console.log('[CustomJS] Creating config button for block:', blockId);
                  return \`
                    <div 
                      class="dynamic-content-config-button" 
                      style="position: absolute; top: 10px; right: 10px; background: rgba(0,0,0,0.1); color: #333; padding: 5px 10px; border-radius: 4px; cursor: pointer; z-index: 10;"
                      onclick="window.openDynamicContentModal('\${blockId}', event)"
                    >
                      Configure
                    </div>
                  \`;
                }

                function wrapContent(content, blockId) {
                  console.log('[CustomJS] Wrapping content for block:', blockId);
                  console.log('[CustomJS] Content to wrap:', content?.substring(0, 100) + '...');
                  return \`<div class="dynamic-content-wrapper" data-block-id="\${blockId}" style="position: relative;">\${createConfigButton(blockId)}\${content}</div>\`;
                }

                function renderDynamicContent(values) {
                  console.log('[CustomJS] renderDynamicContent called with values:', JSON.stringify(values, null, 2));
                  
                  try {
                    const blockId = values._meta?.blockId || values.blockId || Math.random().toString(36).substr(2, 9);
                    console.log('[CustomJS] Using block ID:', blockId);
                    
                    // Check each possible content source
                    if (values.dynamicContent) {
                      console.log('[CustomJS] Found dynamicContent property');
                      return wrapContent(values.dynamicContent, blockId);
                    }
                    
                    if (values.html) {
                      console.log('[CustomJS] Found html property');
                      return wrapContent(values.html, blockId);
                    }
                    
                    if (values.source) {
                      console.log('[CustomJS] Found source property');
                      return wrapContent(values.source, blockId);
                    }
                    
                    if (values.content) {
                      console.log('[CustomJS] Found content property');
                      return wrapContent(values.content, blockId);
                    }

                    console.log('[CustomJS] No content found, rendering placeholder');
                    return \`
                      <div class="dynamic-content-placeholder" style="padding: 20px; text-align: center; background-color: #f8f9fa; border: 1px dashed #dee2e6; border-radius: 8px; position: relative;">
                        <p style="color: #6c757d; margin-bottom: 10px;">Dynamic Content Block</p>
                        <button 
                          onclick="return window.openDynamicContentModal('\${blockId}', event)" 
                          style="background-color: #3b82f6; color: white; border: none; padding: 8px 16px; border-radius: 4px; cursor: pointer;"
                        >
                          Configure Dynamic Content
                        </button>
                      </div>
                    \`;
                  } catch (error) {
                    console.error('[CustomJS] Error in renderDynamicContent:', error);
                    return \`
                      <div style="padding: 20px; text-align: center; background-color: #fee2e2; border: 1px solid #fecaca; border-radius: 8px;">
                        <p style="color: #dc2626; margin-bottom: 10px;">Error rendering dynamic content</p>
                        <p style="color: #ef4444; font-size: 12px;">\${error.message}</p>
                      </div>
                    \`;
                  }
                }

                console.log('[CustomJS] Registering dynamic content tool...');
                try {
                  unlayer.registerTool({
                    name: 'dynamic-content',
                    label: 'Dynamic Content',
                    icon: 'fa-database',
                    supportedDisplayModes: ['email', 'web'],
                    blocks: [
                      {
                        name: 'dynamic-content',
                        label: 'Dynamic Content',
                        icon: 'fa-database',
                        category: 'Content',
                        onClick: () => {
                          console.log('[CustomJS] Dynamic content block clicked');
                          window.openDynamicContentModal(Math.random().toString(36).substr(2, 9), null);
                          return false;
                        }
                      }
                    ],
                    values: {},
                    renderer: {
                      Viewer: unlayer.createViewer({
                        render: renderDynamicContent
                      }),
                      exporters: {
                        web: renderDynamicContent,
                        email: renderDynamicContent
                      }
                    }
                  });
                  console.log('[CustomJS] Dynamic content tool registered successfully');
                } catch (error) {
                  console.error('[CustomJS] Error registering tool:', error);
                }
              })();
            `,
            tools: {
              'dynamic-content': {
                enabled: true
              },
              text: {
                enabled: true
              },
              image: {
                enabled: true
              },
              button: {
                enabled: true
              },
              divider: {
                enabled: true
              },
              columns: {
                enabled: true
              }
            },
            customCSS: `
              .blockbuilder-branding {
                display: none !important;
              }
              .tool-panel {
                background-color: #100f0f !important;
                color: #f1f2f6 !important;
              }
              .tool-panel-header {
                background-color: #2a2828 !important;
              }
              .tool-panel-body {
                background-color: #100f0f !important;
              }
              .button-primary {
                background-color: #3b82f6 !important;
              }
              .unlayer-item-icon {
                background-color: transparent !important;
              }
            `,
            displayMode: 'email',
            projectId: 1
          }}
        />
      </div>

      {/* Template Selection Modal */}
      <TemplateSelectionModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        templates={templates}
        onSelectTemplate={handleLoadTemplate}
        currentUserId={user?.profile?.id || ''}
      />

      {/* Dynamic Content Modal */}
      {isDynamicContentModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#100f0f] p-6 rounded-lg shadow-xl max-w-2xl w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-white">Configure Dynamic Content</h2>
              <button 
                onClick={() => {
                  console.log('Closing modal via X button');
                  setIsDynamicContentModalOpen(false);
                  setActiveBlockId(null);
                }}
                className="text-gray-400 hover:text-white"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="space-y-4">
              <div>
                <label className="text-white font-medium block mb-2">Content Type</label>
                <select 
                  className="bg-gray-700 text-white px-3 py-1 rounded w-full"
                  value={dynamicContentConfig.contentType}
                  onChange={(e) => handleDynamicContentChange('contentType', e.target.value)}
                >
                  <option value="ANNOUNCEMENT">Announcements</option>
                  <option value="DAILY_UPDATE">Daily Updates</option>
                </select>
              </div>

              <div>
                <label className="text-white font-medium block mb-2">Display Mode</label>
                <select 
                  className="bg-gray-700 text-white px-3 py-1 rounded w-full"
                  value={dynamicContentConfig.displayMode}
                  onChange={(e) => handleDynamicContentChange('displayMode', e.target.value)}
                >
                  <option value="GRID">Grid</option>
                  <option value="LIST">List</option>
                </select>
              </div>

              {dynamicContentConfig.displayMode === 'GRID' && (
                <div>
                  <label className="text-white font-medium block mb-2">Grid Columns</label>
                  <input 
                    type="number" 
                    min="1" 
                    max="3"
                    className="bg-gray-700 text-white px-3 py-1 rounded w-full"
                    value={dynamicContentConfig.columns}
                    onChange={(e) => handleDynamicContentChange('columns', parseInt(e.target.value))}
                  />
                </div>
              )}

              <div>
                <label className="text-white font-medium block mb-2">Display Options</label>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <input 
                      type="checkbox" 
                      id="showImages"
                      className="mr-2"
                      checked={dynamicContentConfig.showImages}
                      onChange={(e) => handleDynamicContentChange('showImages', e.target.checked)}
                    />
                    <label htmlFor="showImages" className="text-white">Show Images</label>
                  </div>
                  <div className="flex items-center">
                    <input 
                      type="checkbox" 
                      id="showDates"
                      className="mr-2"
                      checked={dynamicContentConfig.showDates}
                      onChange={(e) => handleDynamicContentChange('showDates', e.target.checked)}
                    />
                    <label htmlFor="showDates" className="text-white">Show Dates</label>
                  </div>
                  <div className="flex items-center">
                    <input 
                      type="checkbox" 
                      id="showDescriptions"
                      className="mr-2"
                      checked={dynamicContentConfig.showDescriptions}
                      onChange={(e) => handleDynamicContentChange('showDescriptions', e.target.checked)}
                    />
                    <label htmlFor="showDescriptions" className="text-white">Show Descriptions</label>
                  </div>
                </div>
              </div>

              {dynamicContentConfig.showDescriptions && (
                <div>
                  <label className="text-white font-medium block mb-2">Description Length</label>
                  <input 
                    type="number" 
                    min="50" 
                    max="500"
                    className="bg-gray-700 text-white px-3 py-1 rounded w-full"
                    value={dynamicContentConfig.truncateDescription}
                    onChange={(e) => handleDynamicContentChange('truncateDescription', parseInt(e.target.value))}
                  />
                </div>
              )}

              <div>
                <label className="text-white font-medium block mb-2">Time Range</label>
                <select 
                  className="bg-gray-700 text-white px-3 py-1 rounded w-full"
                  value={dynamicContentConfig.timeRange}
                  onChange={(e) => handleDynamicContentChange('timeRange', e.target.value)}
                >
                  <option value="TODAY">Today</option>
                  <option value="LAST_7_DAYS">Last 7 Days</option>
                  <option value="LAST_30_DAYS">Last 30 Days</option>
                  <option value="THIS_MONTH">This Month</option>
                  <option value="ALL">All Time</option>
                </select>
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  onClick={() => {
                    console.log('Closing modal via Cancel button');
                    setIsDynamicContentModalOpen(false);
                    setActiveBlockId(null);
                  }}
                  className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    console.log('Inserting dynamic content with config:', dynamicContentConfig);
                    console.log('Active block ID:', activeBlockId);
                    handleInsertDynamicContent({
                      type: dynamicContentConfig.contentType,
                      displayMode: dynamicContentConfig.displayMode,
                      layout: {
                        columns: dynamicContentConfig.columns,
                        showImages: dynamicContentConfig.showImages,
                        showDates: dynamicContentConfig.showDates,
                        showDescriptions: dynamicContentConfig.showDescriptions,
                        contentTransforms: {
                          truncateDescription: dynamicContentConfig.truncateDescription
                        }
                      },
                      filter: {
                        timeRange: dynamicContentConfig.timeRange
                      }
                    });
                    setIsDynamicContentModalOpen(false);
                  }}
                  className="px-4 py-2 bg-communityBlue text-white rounded hover:bg-blue-600"
                >
                  Insert Content
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailTemplateBuilder;