import React, { ReactElement } from 'react';

interface SidebarLinkProps {
  icon: React.ReactElement;
  text: string;
  isActive: boolean;
  onClick: () => void;
  collapsed: boolean;
  disabled: boolean;
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({ icon, text, isActive, onClick, collapsed, disabled }) => {
  return (
    <div
      className={`
        ${isActive ? "" : "opacity-[0.5]"}
        self-stretch flex flex-row items-center justify-start z-10 transition-colors duration-200 
        ${disabled ? "opacity-[0.4] cursor-default" : "cursor-pointer"}
      `}
      onClick={disabled ? undefined : onClick}
    >
      <a
        className={`${
          collapsed ? "justify-center" : "justify-start pl-[30px]"
        } self-stretch flex flex-row items-center w-full py-3`}
      >
        <div className={`flex flex-row items-center ${collapsed ? 'justify-center' : 'justify-start gap-[8px]'}`}>
          <div className="relative w-[18px] h-[18px] overflow-hidden shrink-0">
            {React.isValidElement(icon) ? React.cloneElement(icon) : icon}
          </div>
          {!collapsed && <div className="relative font-medium hover:text-[#099aad]">{text}</div>}
        </div>
      </a>
    </div>
  );
};