import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { finnhubClient, FinnhubProvider, useFinnhub, Quote } from 'react-finnhub';

interface StockData {
  symbol: string;
  price: number;
  change: number;
  percentChange: number;
  lastUpdated: number;
}

const CACHE_KEY = 'REAX_STOCK_DATA';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const StockTickerContent: React.FC = () => {
  const [stockData, setStockData] = useState<StockData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const finnhub = useFinnhub();

  useEffect(() => {
    const fetchStockData = async () => {
      if (!finnhub) {
        setError('Finnhub client not initialized');
        setLoading(false);
        return;
      }

      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const parsedData: StockData = JSON.parse(cachedData);
        const now = Date.now();
        if (now - parsedData.lastUpdated < CACHE_DURATION) {
          setStockData(parsedData);
          setLoading(false);
          return;
        }
      }

      try {
        const response = await finnhub.quote('REAX');
        if (response && response.data) {
          const quoteData: Quote = response.data;
          const newStockData: StockData = {
            symbol: 'REAX',
            price: quoteData.c ?? 0,
            change: quoteData.d ?? 0,
            percentChange: quoteData.dp ?? 0,
            lastUpdated: Date.now()
          };
          setStockData(newStockData);
          localStorage.setItem(CACHE_KEY, JSON.stringify(newStockData));
        } else {
          throw new Error('Invalid response from Finnhub');
        }
      } catch (err: any) {
        console.error('Error fetching stock data:', err);
        if (err.response && err.response.status === 401) {
          setError('Unauthorized: Please check your API key');
        } else {
          setError(`Failed to fetch stock data: ${err.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, [finnhub]);

  if (loading) return <div className="text-white text-xs px-4">Loading...</div>;
  if (error) return <div className="text-red-500 text-xs px-4">{error}</div>;
  if (!stockData) return null;

  const isPositiveChange = stockData.change >= 0;
  const changeColor = isPositiveChange ? 'text-green-500' : 'text-red-500';
  const symbolLetter = stockData.symbol.charAt(0);

  const cardStyle = {
    background: 'linear-gradient(to right, #1a1a1a, #2a2a2a)',
    border: '1px solid',
    borderImage: 'linear-gradient(45deg, #3a3a3a, #4a4a4a) 1',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div className="w-full text-white rounded-lg" style={cardStyle}>
      <div className="p-2 px-2 ml-2"> 
        <div className="flex justify-between items-center"> 
          <div className="flex items-center space-x-2">
            <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center"> 
              <span className="text-gray-900 font-bold text-xs">{symbolLetter}</span> 
            </div>
            <div className="flex flex-col">
              <h2 className="text-xs font-semibold -ml-[3.3rem] -mb-2">{stockData.symbol}</h2> 
              <p className="text-[10px] text-gray-400 -ml-[0.1rem]">Real Brokerage Inc</p> 
            </div>
          </div>
          <div className={`flex items-center ${changeColor}`}>
            {isPositiveChange ? (
              <FaArrowUp className="w-2 h-2 mr-1" /> // Smaller icon
            ) : (
              <FaArrowDown className="w-2 h-2 mr-1" /> // Smaller icon
            )}
            <span className="text-xs font-semibold"> 
              {Math.abs(stockData.percentChange).toFixed(2)}%
            </span>
          </div>
        </div>
        <div className="mt-1 flex flex-col items-start"> 
          <div className="text-lg font-bold">${stockData.price.toFixed(2)}</div> 
          <div className="text-[8px] text-gray-400">
            Last updated: {new Date(stockData.lastUpdated).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

const StockTicker: React.FC = () => {
  const apiKey = process.env.REACT_APP_FINN_API_KEY || '';
  const client = finnhubClient(apiKey);

  if (!apiKey) {
    return <div className="text-red-500">Error: Finnhub API key is missing</div>;
  }

  return (
    <FinnhubProvider client={client}>
      <StockTickerContent />
    </FinnhubProvider>
  );
};

export default StockTicker;
