import React, { useEffect, useState } from 'react';
import supabaseConnect from '../../../../../utils/supabaseConnect';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Box,
  Typography,
  Alert,
  TextField,
  TablePagination,
  InputAdornment
} from '@mui/material';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';

interface SurveyStats {
  totalResponses: number;
  totalRespondents: number;
  responseRate: number;
  averageResponsesPerUser: number;
}

interface SurveyQuestion {
  id: string;
  question: string;
}

interface SurveyResponseWithQuestion {
  id: string;
  profile_id: string;
  response: string;
  survey_date: string;
  survey_questions: {
    id: string;
    question: string;
  };
}

interface UserProfile {
  id: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  job_title: string | null;
}

interface UserResponse {
  id: string;
  question: string;
  response: string;
  survey_date: string;
}

interface UserSurveyData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  job_title: string | null;
  responseCount: number;
  monthlyResponses: {
    [key: string]: UserResponse[];
  };
}

interface ExpandableRowProps {
  user: UserSurveyData;
}

/**
 * Converts numeric ratings to a more readable format with emojis
 * @param response - The response string to convert
 * @param question - The question text to check if it's a rating question
 * @returns Formatted response string with emoji and description
 */
const formatResponse = (response: string, question: string): JSX.Element => {
  // Check if it's a rating question by looking for specific patterns
  const isRatingQuestion = 
    // General rating patterns
    question.toLowerCase().includes('rate') || 
    question.toLowerCase().includes('rating') ||
    question.toLowerCase().includes('score') ||
    question.toLowerCase().includes('satisfaction') ||
    // Specific question patterns from the screenshot
    question.toLowerCase().includes('how would you rate') ||
    question.toLowerCase().includes('how satisfied are you');

  // Check if response is a valid rating number (1, 2, or 3)
  const numericResponse = parseInt(response, 10);
  if ((isRatingQuestion || !isNaN(numericResponse)) && numericResponse >= 1 && numericResponse <= 3) {
    type Rating = {
      emoji: string;
      text: string;
      color: string;
    };

    type Ratings = {
      [key: number]: Rating;
    };

    const ratings: Ratings = {
      1: { emoji: '😢', text: 'Poor', color: 'text-red-400' },
      2: { emoji: '😐', text: 'Fair', color: 'text-yellow-400' },
      3: { emoji: '😊', text: 'Good', color: 'text-green-400' }
    };

    const rating = ratings[numericResponse];
    
    return (
      <div className="flex items-center gap-2">
        <span className="text-xl" role="img" aria-label={rating.text}>
          {rating.emoji}
        </span>
        <span className={`${rating.color}`}>
          {rating.text} ({numericResponse}/3)
        </span>
      </div>
    );
  }

  return <span>{response}</span>;
};

/**
 * ExpandableRow component displays user details and their monthly survey responses
 * 
 * @component
 */
const ExpandableRow: React.FC<ExpandableRowProps> = ({ user }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow 
        className="hover:bg-[#2a475e] transition-colors cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <TableCell className="text-white border-b border-[#66c0f4]/20">
          <IconButton size="small" className="text-white">
            {open ? <FaChevronUp /> : <FaChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell className="text-white border-b border-[#66c0f4]/20">
          {user.first_name} {user.last_name}
        </TableCell>
        <TableCell className="text-white border-b border-[#66c0f4]/20">
          {user.email}
        </TableCell>
        <TableCell className="text-white border-b border-[#66c0f4]/20">
          {user.job_title || 'N/A'}
        </TableCell>
        <TableCell className="text-white border-b border-[#66c0f4]/20">
          {user.responseCount}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="p-0 border-b border-[#66c0f4]/20" colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="p-4 bg-[#1b2838]">
              {Object.entries(user.monthlyResponses)
                .sort((a, b) => b[0].localeCompare(a[0])) // Sort months in descending order
                .map(([month, responses]) => (
                  <div key={month} className="mb-4">
                    <Typography className="text-[#66c0f4] font-semibold mb-2">
                      {new Date(month).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                    </Typography>
                    <div className="space-y-2">
                      {responses.map((response) => (
                        <div key={response.id} className="bg-[#2a475e] p-3 rounded">
                          <p className="text-gray-300 text-sm mb-1">{response.question}</p>
                          <div className="text-white">
                            {formatResponse(response.response, response.question)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

/**
 * SurveyAnalytics component displays analytics for survey responses and user profiles
 * Shows user-specific survey responses with monthly breakdown
 * 
 * @component
 */
export const SurveyAnalytics: React.FC = () => {
  const [surveyStats, setSurveyStats] = useState<SurveyStats>({
    totalResponses: 0,
    totalRespondents: 0,
    responseRate: 0,
    averageResponsesPerUser: 0
  });
  const [users, setUsers] = useState<UserSurveyData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Search and pagination state
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        setError(null);
        const supabase = supabaseConnect();

        // First, fetch active profiles
        const { data: profilesData, error: profilesError } = await supabase
          .from('profiles')
          .select('id, first_name, last_name, email, job_title')
          .eq('active', true);

        if (profilesError) throw new Error('Error fetching profiles: ' + profilesError.message);
        if (!profilesData) throw new Error('No profiles found');

        // Then, fetch survey responses for these profiles
        const { data: responsesData, error: responsesError } = await supabase
          .from('survey_responses')
          .select(`
            id,
            profile_id,
            response,
            survey_date,
            survey_questions (
              id,
              question
            )
          `)
          .in('profile_id', profilesData.map(p => p.id)) as { 
            data: SurveyResponseWithQuestion[] | null; 
            error: any 
          };

        if (responsesError) throw new Error('Error fetching responses: ' + responsesError.message);

        // Process and combine the data
        const processedUsers: UserSurveyData[] = profilesData.map(profile => {
          const userResponses = (responsesData || []).filter(r => r.profile_id === profile.id);
          
          const monthlyResponses = userResponses.reduce((acc: { [key: string]: UserResponse[] }, response) => {
            if (!response.survey_questions) return acc;
            
            const month = response.survey_date.substring(0, 7); // Get YYYY-MM
            if (!acc[month]) {
              acc[month] = [];
            }
            
            acc[month].push({
              id: response.id,
              question: response.survey_questions.question,
              response: response.response,
              survey_date: response.survey_date
            });
            return acc;
          }, {});

          return {
            id: profile.id,
            first_name: profile.first_name || '',
            last_name: profile.last_name || '',
            email: profile.email || '',
            job_title: profile.job_title,
            responseCount: userResponses.length,
            monthlyResponses
          };
        });

        setUsers(processedUsers);

        // Calculate overall stats
        const totalResponses = (responsesData || []).length;
        const respondents = processedUsers.filter(user => user.responseCount > 0).length;
        const totalUsers = processedUsers.length;

        setSurveyStats({
          totalResponses,
          totalRespondents: respondents,
          responseRate: (respondents / totalUsers) * 100,
          averageResponsesPerUser: totalResponses / respondents || 0
        });

      } catch (err) {
        console.error('Error in fetchAnalytics:', err);
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  // Filter and sort users based on search query
  const filteredUsers = users
    .filter(user => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        user.first_name.toLowerCase().includes(searchTerm) ||
        user.last_name.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm) ||
        (user.job_title?.toLowerCase() || '').includes(searchTerm)
      );
    })
    .sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
      const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });

  // Paginate users
  const paginatedUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  if (loading) {
    return (
      <div className="bg-[#1b2838] p-6 rounded-lg shadow-lg space-y-6">
        <div className="animate-pulse space-y-4">
          {/* Stats Grid Loading */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="bg-[#2a475e] p-4 rounded-lg h-24">
                <div className="h-4 bg-[#66c0f4]/20 rounded w-1/2 mb-2"></div>
                <div className="h-6 bg-[#66c0f4]/20 rounded w-3/4"></div>
              </div>
            ))}
          </div>
          
          {/* Table Loading */}
          <div className="bg-[#2a475e] p-4 rounded-lg">
            <div className="space-y-4">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="h-12 bg-[#66c0f4]/20 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-[#1b2838] p-6 rounded-lg shadow-lg">
        <Alert severity="error" className="bg-red-900/50 text-white">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="bg-[#1b2838] p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold text-white mb-6">Survey Analytics</h2>
      
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-[#2a475e] p-4 rounded-lg">
          <h3 className="text-sm text-gray-300 mb-1">Total Responses</h3>
          <p className="text-2xl font-bold text-[#66c0f4]">{surveyStats.totalResponses}</p>
        </div>
        <div className="bg-[#2a475e] p-4 rounded-lg">
          <h3 className="text-sm text-gray-300 mb-1">Unique Respondents</h3>
          <p className="text-2xl font-bold text-[#66c0f4]">{surveyStats.totalRespondents}</p>
        </div>
        <div className="bg-[#2a475e] p-4 rounded-lg">
          <h3 className="text-sm text-gray-300 mb-1">Response Rate</h3>
          <p className="text-2xl font-bold text-[#66c0f4]">{surveyStats.responseRate.toFixed(1)}%</p>
        </div>
        <div className="bg-[#2a475e] p-4 rounded-lg">
          <h3 className="text-sm text-gray-300 mb-1">Avg Responses/User</h3>
          <p className="text-2xl font-bold text-[#66c0f4]">
            {surveyStats.averageResponsesPerUser.toFixed(1)}
          </p>
        </div>
      </div>

      {/* Search Input */}
      <div className="mb-4">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search users..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch className="text-[#66c0f4]" />
              </InputAdornment>
            ),
            className: "bg-[#2a475e] rounded text-white",
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#66c0f4',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#66c0f4',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#66c0f4',
              },
              '& input': {
                color: 'white',
              },
              '& input::placeholder': {
                color: '#66c0f4',
                opacity: 0.7,
              },
            }
          }}
        />
      </div>

      {/* User Responses Table */}
      <TableContainer component={Paper} className="bg-[#2a475e]">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-[#66c0f4] font-semibold border-b border-[#66c0f4]/20" width={50}></TableCell>
              <TableCell className="text-[#66c0f4] font-semibold border-b border-[#66c0f4]/20">Name</TableCell>
              <TableCell className="text-[#66c0f4] font-semibold border-b border-[#66c0f4]/20">Email</TableCell>
              <TableCell className="text-[#66c0f4] font-semibold border-b border-[#66c0f4]/20">Job Title</TableCell>
              <TableCell className="text-[#66c0f4] font-semibold border-b border-[#66c0f4]/20">Total Responses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <ExpandableRow key={user.id} user={user} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          defaultValue={5}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="text-[#66c0f4]"
          sx={{
            '.MuiTablePagination-select': {
              color: '#66c0f4',
              backgroundColor: '#2a475e',
              '&:hover': {
                backgroundColor: '#355875'
              }
            },
            '.MuiTablePagination-selectIcon': {
              color: '#66c0f4'
            },
            '.MuiTablePagination-displayedRows': {
              color: '#66c0f4',
              fontSize: '0.95rem'
            },
            '.MuiTablePagination-actions': {
              color: '#66c0f4',
              '& .MuiIconButton-root': {
                color: '#66c0f4',
                '&:hover': {
                  backgroundColor: '#355875'
                },
                '&.Mui-disabled': {
                  color: '#66c0f440'
                }
              }
            },
            '.MuiTablePagination-menuItem': {
              color: '#66c0f4',
              backgroundColor: '#2a475e',
              '&:hover': {
                backgroundColor: '#355875'
              },
              '&.Mui-selected': {
                backgroundColor: '#355875'
              }
            }
          }}
        />
      </TableContainer>
    </div>
  );
}; 