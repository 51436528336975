import { info } from "console";
import React, { useState, FunctionComponent, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import AnnouncementModal from "../announcement/AnnouncementModal";
import supabaseConnect from "../../utils/supabaseConnect";
import { communityLogo1 } from "../../assets/index";
import EventModal from "../../components/Events/EventModal";

interface CardProps {
  cover_image: string;
  tag: string;
  title: string;
  description: string;
  id: number;
  info: string;
  table_name: string;
  docId: string;
  link: string;
  start_date?: string;
  location?: string;
}

interface AnnouncementData {
  id: string;
  title: string;
  description: string;
  created_at: string;
  info: string;
  cover_image: string;
}



const Card: React.FC<CardProps> = ({
  cover_image,
  tag,
  title,
  description,
  info,
  table_name,
  id,
  docId,
  link,
  start_date,
  location,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState<AnnouncementData[]>([]);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<any[]>([]);
  const [beingEdited, setBeingEdited] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const supabase = supabaseConnect();

  const handleCardClick = async (id:number, table_name:string, docId: string) => {
    if (table_name === "page" || table_name === "link") {
      return window.open(link, "_blank");
    } else if (table_name === "announcement") {
      const { data: announcement, error } = await supabase
        .from("announcement")
        .select("*")
        .eq("id", id)
        .single();

      if (error) return console.log(error);
      if (!announcement) return console.log("No announcement found");

      setActiveAnnouncement([announcement]);
      setIsModalOpen(true);
    } else if (table_name === "event") {
      const { data: event, error } = await supabase
        .from("event")
        .select("*")
        .eq("id", id)
        .single();

      if (error) return console.log(error);
      if (!event) return console.log("No event found");

      setActiveEvent([event]);
      setIsEventModalOpen(true);
    }
  };

  const getTitleClass = (title: string) => {
    if (title.length > 50) return "text-sm font-bold max-h-[3rem]";
    return "text-base font-bold";
  };

  const getDescriptionClass = (description: string) => {
    if (description.length > 100) return "text-xs max-h-[1rem] overflow-y-hidden";
    return "text-sm";
  };

  const scrollbarStyle = {
    scrollbarWidth: 'thin' as const,
    scrollbarColor: '#4A5568 #2D3748',
    overflowX: 'hidden' as const,
    wordWrap: 'break-word' as const,
  };

  return (
    <>
      {isModalOpen && (
        <AnnouncementModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          activeAnnouncment={activeAnnouncement}
        />
      )}
      
      {isEventModalOpen && (
        <EventModal
          isOpen={isEventModalOpen}
          onClose={() => setIsEventModalOpen(false)}
          activeEvent={activeEvent}
          setBeingEdited={setBeingEdited}
          setShowEventModal={setShowEventModal}
          setActiveEvent={setActiveEvent}
        />
      )}

      <div id="modal-root"></div>
      <div
        onClick={() => handleCardClick(id, table_name, docId)}
        className="flex flex-col rounded-lg shadow-lg overflow-hidden min-h-[19rem] max-h-[19rem] bg-black cursor-pointer"
      >
        <div className="flex">
          <img className="h-[8rem] w-full object-cover" src={communityLogo1} alt="" />
        </div>
        <div className="flex-1 bg-black p-3 flex flex-col justify-between">
          <div className="flex-1 text-[1rem]">
            <p className="text-sm font-medium text-blue-600 -mt-[.2rem] -mb-[.5rem]">
              <div className="no-underline text-tourquoise">{tag}</div>
            </p>
            <div className="block mt-2 no-underline text-gray-600">
              <p
                className={`${getTitleClass(title)} text-white -mb-[.3rem] break-words`}
                // style={scrollbarStyle}
              >
                {title}
              </p>
              <p
                className={`mt-[1rem] -mb-10 ${getDescriptionClass(description)} text-white break-words`}
                // style={scrollbarStyle}
              >
                {description}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-end mt-auto">
            <p className="text-sm font-medium">
              <div className="text-[.8rem] no-underline text-white">
                View Page <span>&rarr;</span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
  

interface CardGridProps {
  data: CardProps[];
}

const CardGrid: React.FC<CardGridProps> = ({ data }) => (
  <div className="flex flex-wrap responsive  mb-[13rem] w-full">
    {data?.map((card, index) => (
      <div className="p-4 w-[17rem] pr-[1rem] " key={index}>
        <Card {...card} />
      </div>
    ))}
  </div>
);

export default CardGrid;
