import React from 'react';

const CalendarSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse flex flex-col gap-5">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="h-20 bg-gray-700 rounded-lg"></div>
      ))}
    </div>
  );
};

export default CalendarSkeleton;
