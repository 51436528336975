import React, { useEffect, useState } from 'react';

interface CountdownOverlayProps {
  initialCountdown: number;
  onComplete: () => void;
}

const CountdownOverlay: React.FC<CountdownOverlayProps> = ({ initialCountdown, onComplete }) => {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      onComplete();
    }
  }, [countdown, onComplete]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="p-8 rounded-lg text-white text-center">
        <p className="text-2xl mb-4">Redirecting in {countdown} </p>
      </div>
    </div>
  );
};

export default CountdownOverlay;