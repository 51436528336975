import { DynamicContentBlock } from './types';

interface ContentItem {
  id: number;
  title: string;
  description?: string;
  image_url?: string;
  created_at: string;
  [key: string]: any;
}

export const generateDynamicContent = (data: ContentItem[], block: DynamicContentBlock): string => {
  console.log('[generateDynamicContent] Starting with data:', {
    itemCount: data?.length,
    block: {
      displayMode: block.displayMode,
      columns: block.layout.columns,
      showImages: block.layout.showImages,
      showDates: block.layout.showDates,
      showDescriptions: block.layout.showDescriptions
    }
  });

  try {
    if (!data || data.length === 0) {
      console.log('[generateDynamicContent] No data available');
      return `
        <div class="no-content" style="text-align: center; padding: 30px; background-color: #f8f9fa; border: 1px dashed #dee2e6; border-radius: 8px; margin: 20px 0;">
          <h3 style="color: #6c757d; font-size: 18px; margin-bottom: 10px;">No Content Available</h3>
          <p style="color: #6c757d; font-size: 14px;">No content was found for the selected time range. Content will appear here once available.</p>
        </div>
      `;
    }

    const {
      displayMode,
      layout: {
        columns = 2,
        showImages = true,
        showDates = true,
        showDescriptions = true,
        contentTransforms: { truncateDescription = 150 } = {}
      }
    } = block;

    console.log('[generateDynamicContent] Generating content with settings:', {
      displayMode,
      columns,
      showImages,
      showDates,
      showDescriptions,
      truncateDescription
    });

    const gridStyles = `
      .dynamic-content-grid {
        display: grid;
        grid-template-columns: repeat(${columns}, 1fr);
        gap: 20px;
        padding: 10px;
      }
      .dynamic-content-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px;
      }
      .content-item {
        background: white;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      }
      .content-item img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .content-item-body {
        padding: 15px;
      }
      .content-item-title {
        font-size: 16px;
        font-weight: 600;
        color: #111827;
        margin-bottom: 8px;
      }
      .content-item-date {
        font-size: 14px;
        color: #6b7280;
        margin-bottom: 8px;
      }
      .content-item-description {
        font-size: 14px;
        color: #4b5563;
        line-height: 1.5;
      }
    `;

    const formatDate = (dateString: string) => {
      try {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      } catch (error) {
        console.error('[generateDynamicContent] Error formatting date:', error);
        return dateString;
      }
    };

    const truncateText = (text: string, maxLength: number) => {
      if (!text) return '';
      if (text.length <= maxLength) return text;
      return text.substring(0, maxLength) + '...';
    };

    console.log('[generateDynamicContent] Generating HTML for', data.length, 'items');
    const itemsHtml = data.map((item, index) => {
      try {
        const imageHtml = showImages && item.image_url
          ? `<img src="${item.image_url}" alt="${item.title}" onerror="this.style.display='none'" />`
          : '';
        
        const dateHtml = showDates
          ? `<div class="content-item-date">${formatDate(item.created_at)}</div>`
          : '';
        
        const descriptionHtml = showDescriptions && item.description
          ? `<div class="content-item-description">${truncateText(item.description, truncateDescription)}</div>`
          : '';

        return `
          <div class="content-item">
            ${imageHtml}
            <div class="content-item-body">
              <div class="content-item-title">${item.title}</div>
              ${dateHtml}
              ${descriptionHtml}
            </div>
          </div>
        `;
      } catch (error) {
        console.error('[generateDynamicContent] Error generating item HTML:', error, 'Item:', item);
        return `
          <div class="content-item error">
            <div class="content-item-body">
              <div class="content-item-title">Error displaying item</div>
            </div>
          </div>
        `;
      }
    }).join('');

    const finalHtml = `
      <style>${gridStyles}</style>
      <div class="dynamic-content-${displayMode.toLowerCase()}">
        ${itemsHtml}
      </div>
    `;

    console.log('[generateDynamicContent] Generated HTML length:', finalHtml.length);
    return finalHtml;

  } catch (error) {
    console.error('[generateDynamicContent] Error generating content:', error);
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    return `
      <div style="text-align: center; padding: 30px; background-color: #fee2e2; border: 1px solid #fecaca; border-radius: 8px; margin: 20px 0;">
        <h3 style="color: #dc2626; font-size: 18px; margin-bottom: 10px;">Error Generating Content</h3>
        <p style="color: #ef4444; font-size: 12px;">${errorMessage}</p>
      </div>
    `;
  }
}; 