import React, {useState} from 'react';
import {
  bottomCornerLoginPage,
  communityNameLogo,
  topCornerLoginPage,
  backGroundLinesLoginPage2
} from '../../assets/index';
import supabaseConnect from '../../utils/supabaseConnect';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const Login = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [email, setEmail] = useState<string>('');
  const [showPasswordChange, setShowPasswordChange] = useState<boolean>(false);
  const supabase = supabaseConnect();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // build supabase login functionality here
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    const supabase = supabaseConnect();

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        if (error.message.includes("Invalid login credentials")) {
          // check users from the auth table in supabase to see email is confirmed or not there 
          const { data: userData, error: userError } = await supabase.from('profiles').select('*').eq('email', email);
          console.log('users', userData);
          if (!userData || userData?.length === 0) {
            setErrors(['The email you entered is incorrect. Please try again.']);
          } else {
            setErrors(['The password you entered is incorrect. Please try again.']);
          }

          console.log(userData, userError);
        } else {
          setErrors([error.message]);
        }
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      setErrors(["An unexpected error occurred. Please try again later."]);
    }
  }

  const handleForgotPassword = async () => {
    return window.location.href = '/password-change-request';
  };

  const handlePasswordReset = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        setErrors([error.message]);
      } else {
        console.log('Password reset email sent');
        setShowPasswordChange(false);
      }
    } catch (error) {
      console.error(error);
      setErrors(["An unexpected error occurred. Please try again later."]);
    }
  };

  const handlePasswordReveal = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowPassword(true);
  };

  const handlePasswordHide = () => {
    setShowPassword(false);
  };

  return (
    <body className="h-screen bg-gradient-to-tl from-gray to-black z-[-10] ">
      <div className="flex flex-col items-center justify-center h-screen">
        <img className="relative mr-[30rem] -mb-[1rem] w-full max-w-[3rem] sm:block hidden" src={topCornerLoginPage} alt="top corner login page" />
        <div className={`flex flex-col items-center justify-center sm:w-[30rem] w-full  
min-h-[${28 + errors.length}rem] bg-white rounded-sm shadow-2xl sm:p-1 px-5 `}>
          <img className="flex  -mt-[5rem] sm:-mt-[10rem] pb-[2rem] w-full max-w-[20rem]" src={communityNameLogo} alt="community name logo" />
          <span className="text-[.7rem] font-Poppins sm:mt-[6rem] mt-[2.5rem] text-darkBlue">Welcome back!</span>
          <h1 className="text-[1.2rem] font-bold mt-[1rem] font-Poppins">Login to your account</h1>
          {errors.length > 0 && (
            <div className="relative w-full top-0 bg-red-200 ml-center mt-[1.5rem] text-red-500 border-solid border-[1px]">
              {errors.map((error: string, i: number) => (
                <p key={i} className='pl-[1rem]'>{error}</p>
              ))}
            </div>
          )}
           <form
      className="flex flex-col items-center justify-center w-10/12 sm:w-80 h-80 z-[10]"
      onSubmit={handleSubmit}
    >
      <div className="flex w-full -mt-[2rem]">
        <label className="text-sm">Email Address</label>
      </div>
      <input
        name="email"
        className="w-full h-10 mt-1 mb-[1rem] px-2 focus:outline-none border-solid border-[.2px] border-lightGray rounded-md shadow"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {!showPasswordChange && (
        <>
          <div className="flex flex-col items-start justify-start w-full">
            <label className="text-sm">Password</label>
          </div>
          <div className="relative w-full">
            <input
              name="password"
              className="w-full h-10 mt-1 pl-2 -ml-2 border-solid border-[.2px] border-lightGray rounded-md shadow"
              type={showPassword ? "text" : "password"}
            />
            <button
              type="button"
              className="absolute inset-y-0 -right-2 flex items-center px-3 mt-1 text-gray-600"
              onMouseDown={handlePasswordReveal}
              onMouseUp={handlePasswordHide}
              onMouseLeave={handlePasswordHide}
            >
              {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
            </button>
          </div>
          <Link to="#" className="text-lightBlue underline mb-4" onClick={handleForgotPassword}>
            Forgot Password
          </Link>
        </>
      )}
      {showPasswordChange && (
        <>
          <div className="flex flex-col items-start justify-start w-full">
            <label className="text-sm">New Password</label>
          </div>
          <input
            className="w-full h-10 pl-2 mt-1 border-solid border-[.2px] border-lightGray rounded-md shadow"
            type="password"
          />
          <button
            className="w-full h-10 sm:mt-11 mt-14 text-white border-none bg-lightBlue rounded-md cursor-pointer"
            onClick={handlePasswordReset}
          >
            Reset Password
          </button>
        </>
      )}
      {!showPasswordChange && (
        <button
          className="w-full h-10 sm:mt-11 mt-14 text-white border-none bg-lightBlue rounded-md cursor-pointer"
          type="submit"
        >
          Sign In
        </button>
      )}
    </form>
          
        </div>
        <img className="relative -mt-[1rem] ml-[30rem] w-full max-w-[3rem] sm:block hidden 5xl:-mt-[2rem]" src={bottomCornerLoginPage} alt="bottom corner login page" />
      </div>
      <img className="absolute bottom-0 w-full z-[1]" src={backGroundLinesLoginPage2} alt="background lines" />
    </body>
  )
}

export default Login;
