import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ConfigurationPanel } from './shared/ConfigurationPanel';
import { FormField } from './shared/FormField';
import { DatabaseTriggerConfig as ConfigType } from './types';
import { validateDatabaseTrigger } from '../utils/validation';

interface DatabaseTriggerConfigProps {
  config: ConfigType;
  onSave: (config: ConfigType) => void;
  onClose: () => void;
}

export const DatabaseTriggerConfig: React.FC<DatabaseTriggerConfigProps> = ({
  config: initialConfig,
  onSave,
  onClose,
}) => {
  const [config, setConfig] = useState<ConfigType>(initialConfig);
  const [validation, setValidation] = useState(validateDatabaseTrigger(initialConfig));

  useEffect(() => {
    setValidation(validateDatabaseTrigger(config));
  }, [config]);

  const handleSave = () => {
    if (validation.isValid) {
      onSave(config);
    }
  };

  const addCondition = () => {
    setConfig({
      ...config,
      conditions: [
        ...config.conditions,
        { field: '', operator: 'equals', value: '' },
      ],
    });
  };

  const removeCondition = (index: number) => {
    setConfig({
      ...config,
      conditions: config.conditions.filter((_, i) => i !== index),
    });
  };

  const updateCondition = (index: number, field: keyof typeof config.conditions[0], value: string) => {
    const newConditions = [...config.conditions];
    newConditions[index] = { ...newConditions[index], [field]: value };
    setConfig({ ...config, conditions: newConditions });
  };

  const baseInputStyles = "block w-full p-2 bg-[#2a2828] text-white rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500";
  const constrainedInputStyles = `${baseInputStyles} w-full sm:max-w-[200px]`;

  return (
    <ConfigurationPanel
      title="Database Trigger Configuration"
      onClose={onClose}
      onSave={handleSave}
      isValid={validation.isValid}
    >
      <div className="space-y-4 w-full max-w-full overflow-hidden">
        <FormField
          label="Table Name"
          required
          error={validation.errors.table?.[0]}
        >
          <div className="w-full overflow-hidden">
            <input
              type="text"
              value={config.table}
              onChange={(e) => setConfig({ ...config, table: e.target.value })}
              className={constrainedInputStyles}
              placeholder="Enter table name"
            />
          </div>
        </FormField>

        <FormField
          label="Event Type"
          required
          error={validation.errors.event?.[0]}
        >
          <select
            value={config.event}
            onChange={(e) => setConfig({ ...config, event: e.target.value as any })}
            className={`${baseInputStyles} sm:max-w-[300px]`}
          >
            <option value="insert">Insert</option>
            <option value="update">Update</option>
            <option value="delete">Delete</option>
          </select>
        </FormField>

        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
            <label className="text-sm font-medium text-gray-200">Conditions</label>
            <button
              type="button"
              onClick={addCondition}
              className="flex items-center justify-center sm:justify-start gap-2 px-3 py-1 text-sm bg-communityBlue text-white rounded-md hover:bg-blue-600 transition-colors w-full sm:w-auto"
            >
              <FaPlus className="w-3 h-3" /> Add Condition
            </button>
          </div>

          <div className="space-y-4">
            {config.conditions.map((condition, index) => (
              <div 
                key={index} 
                className="grid grid-cols-1 sm:grid-cols-[1fr,1fr] lg:grid-cols-[1fr,1fr,1fr,auto] gap-4 items-start"
              >
                <div>
                  <input
                    type="text"
                    value={condition.field}
                    onChange={(e) => updateCondition(index, 'field', e.target.value)}
                    className={constrainedInputStyles}
                    placeholder="Field name"
                  />
                  {validation.errors[`conditions.${index}.field`]?.[0] && (
                    <p className="mt-1 text-sm text-red-400">
                      {validation.errors[`conditions.${index}.field`][0]}
                    </p>
                  )}
                </div>

                <div>
                  <select
                    value={condition.operator}
                    onChange={(e) => updateCondition(index, 'operator', e.target.value)}
                    className={constrainedInputStyles}
                  >
                    <option value="equals">Equals</option>
                    <option value="notEquals">Not Equals</option>
                    <option value="contains">Contains</option>
                    <option value="greaterThan">Greater Than</option>
                    <option value="lessThan">Less Than</option>
                  </select>
                  {validation.errors[`conditions.${index}.operator`]?.[0] && (
                    <p className="mt-1 text-sm text-red-400">
                      {validation.errors[`conditions.${index}.operator`][0]}
                    </p>
                  )}
                </div>

                <div>
                  <input
                    type="text"
                    value={condition.value}
                    onChange={(e) => updateCondition(index, 'value', e.target.value)}
                    className={constrainedInputStyles}
                    placeholder="Value"
                  />
                  {validation.errors[`conditions.${index}.value`]?.[0] && (
                    <p className="mt-1 text-sm text-red-400">
                      {validation.errors[`conditions.${index}.value`][0]}
                    </p>
                  )}
                </div>

                <button
                  type="button"
                  onClick={() => removeCondition(index)}
                  className="p-2 text-red-400 hover:text-red-300 transition-colors self-start w-full sm:w-auto flex justify-center sm:justify-start"
                  title="Remove condition"
                >
                  <FaTrash className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ConfigurationPanel>
  );
}; 