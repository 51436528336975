import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { EmailTemplate, TEMPLATE_CATEGORIES, TemplateView } from './types';
import { FaTrash, FaCheck, FaClock, FaFolder, FaSearch } from 'react-icons/fa';

interface TemplateSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  templates: EmailTemplate[];
  onSelectTemplate: (template: EmailTemplate) => void;
  currentUserId: string;
}

const TemplateSelectionModal: React.FC<TemplateSelectionModalProps> = ({
  isOpen,
  onClose,
  templates,
  onSelectTemplate,
  currentUserId,
}) => {
  const [view, setView] = useState<TemplateView>('recent');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredTemplates = templates.filter(template => {
    const matchesSearch = template.name.toLowerCase().includes(searchQuery.toLowerCase());
    if (view === 'recent') {
      return matchesSearch;
    }
    return matchesSearch && template.profile_id === currentUserId;
  });

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-4xl bg-white rounded-lg shadow-xl">
          {/* Header */}
          <div className="p-6 border-b">
            <Dialog.Title className="text-xl font-medium text-gray-900">
              Select a template
            </Dialog.Title>
            <div className="mt-4 relative">
              <div className="relative">
                <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search templates"
                  className="w-[90%] pl-10 pr-8 py-2 border rounded-lg focus:outline-none"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Navigation */}
          <div className="flex border-b px-6">
            <button
              onClick={() => setView('recent')}
              className={`px-6 py-3 text-sm font-medium border-b-2 ${
                view === 'recent'
                  ? 'border-blue-500 text-blue-600 cursor-pointer'
                  : 'border-transparent text-gray-500 hover:text-gray-700 cursor-pointer'
              }`}
            >
              <FaClock className="inline-block mr-2" />
              Recent
            </button>
            <button
              onClick={() => setView('my-templates')}
              className={`px-6 py-3 text-sm font-medium border-b-2 ${
                view === 'my-templates'
                  ? 'border-blue-500 text-blue-600 cursor-pointer'
                  : 'border-transparent text-gray-500 hover:text-gray-700 cursor-pointer'
              }`}
            >
              <FaFolder className="inline-block mr-2" />
              My templates
            </button>
          </div>

          {/* Templates Grid */}
          <div className="p-6 overflow-y-auto" style={{ maxHeight: '60vh' }}>
            <div className="grid grid-cols-3 gap-4">
              {filteredTemplates.map((template) => (
                <div
                  key={template.id}
                  onClick={() => {
                    onSelectTemplate(template);
                    onClose();
                  }}
                  className="p-4 border-gray-200 rounded-lg hover:bg-gray-50 cursor-pointer group shadow-md hover:shadow-lg transition-all duration-200"
                >
                  <div className="aspect-w-16 aspect-h-9 bg-gray-100 rounded-lg mb-2">
                    {/* Template Preview */}
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-gray-900">
                      {template.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Add footer with cancel button */}
          <div className="p-6 border-t flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer"
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default TemplateSelectionModal; 