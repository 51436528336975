import React, { useState } from "react";
import ReactDOM from "react-dom";
import supabaseConnect from "../../utils/supabaseConnect";
import "./AnnouncementCSS.css";
import { IoClose } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { communityLogo1 } from "../../assets";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  activeAnnouncment: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    cover_image: string;
  }[];
}

/**
 * AnnouncementModal component
 * 
 * A modal component that displays announcement details with support for admin editing.
 * Renders announcement content including title, date, description, and formatted HTML content.
 * Uses a portal for rendering the modal outside the normal DOM hierarchy.
 * 
 * @param {boolean} isOpen - Controls the visibility of the modal
 * @param {() => void} onClose - Callback function to close the modal
 * @param {Announcement[]} activeAnnouncment - Array containing the announcement data to display
 * @param {React.ReactNode} [children] - Optional child elements to render within the modal
 * 
 * @example
 * <AnnouncementModal
 *   isOpen={true}
 *   onClose={handleClose}
 *   activeAnnouncment={[announcement]}
 * />
 */
const AnnouncementModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  activeAnnouncment,
}) => {
  const [userAdmin, setUserAdmin] = useState(false);

  if (!isOpen) return null;
  const date = new Date(activeAnnouncment[0].created_at);
  const supabase = supabaseConnect();

  const dateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // months are zero indexed
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) setUserAdmin(true);
  };

  getUserAdmin();

  console.log(activeAnnouncment, "activeAnnouncment")

  // convert html string to jsx element
  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };

  const handleEditClick = () => {
    window.location.href = `/admin/announcements/edit/${activeAnnouncment[0].id}`;
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
      style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}
      onClick={onClose}
    >
      <div 

        className="bg-[#1b2838] w-full md:w-3/4 xl:w-2/3 mx-auto rounded-lg shadow-2xl max-h-[90vh] flex flex-col relative ring-1 ring-white/10 overflow-hidden"

        onClick={(e) => e.stopPropagation()}
      >
        {/* Navigation buttons */}
        <div className="absolute top-4 right-4 z-10 flex flex-col gap-2">
          <button
            onClick={onClose}
            className="bg-[#1b2838] text-gray-400 hover:text-white p-2 rounded cursor-pointer transition-colors hover:bg-communityBlue"
            aria-label="Close announcement"
          >
            <IoClose size={24} />
          </button>
          {userAdmin && (
            <button
              onClick={handleEditClick}
              className="bg-[#1b2838] text-gray-400 hover:text-white p-2 rounded cursor-pointer transition-colors hover:bg-communityBlue"
              aria-label="Edit announcement"
            >
              <FiEdit size={24} />
            </button>
          )}
        </div>

        {activeAnnouncment.map((announcement) => (

          <div key={announcement.id} className="flex flex-col h-full overflow-auto">
            <div className="w-full flex-shrink-0">
              <img
                src={announcement.cover_image.length > 2 ? announcement.cover_image : communityLogo1}
                alt={announcement.title}
                className="w-full h-[200px] object-cover rounded-t-lg"
              />
              <div className="bg-gradient-to-r from-[#2a475e] to-[#1b2838] p-4">
                <div className="text-gray-400 text-sm">
                  <span className="font-bold text-communityBlue text-[1rem]">Posted</span> on {dateToString(date)}
                </div>
                <h2 className="text-2xl font-bold text-white mb-2">
                  {announcement.title}
                </h2>
                {/* <div className="text-gray-300 mb-2 text-lg">
                  {announcement.description}
                </div> */}
              </div>
            </div>


            <div className="flex-1 p-6 overflow-y-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}>
              <div
                className="text-gray-300 prose prose-invert max-w-none [&_a]:text-communityBlue [&_a]:no-underline hover:[&_a]:underline"
                dangerouslySetInnerHTML={createMarkup(announcement.info)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default AnnouncementModal;