import { useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import supabaseConnect from '../supabaseConnect';
import { updateLastActive } from '../lastActive';

interface UserProfile {
  id: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: number | null;
  job_title: string | null;
  profile_image_url: string | null;
  active: boolean | null;
  created_at: string | null;
  last_active: string | null;
}

interface UseUserReturn {
  user: User | null;
  profile: UserProfile | null;
  isLoading: boolean;
  error: Error | null;
}

/**
 * Custom hook for managing Supabase user authentication and profile data
 * @returns Object containing user data, profile data, loading state, and error state
 * 
 * @example
 * ```tsx
 * const { user, profile, isLoading, error } = useUser();
 * 
 * if (isLoading) return <div>Loading...</div>;
 * if (error) return <div>Error: {error.message}</div>;
 * if (!user) return <div>Not logged in</div>;
 * 
 * return <div>Welcome {profile?.first_name}</div>;
 * ```
 */
const useUser = (): UseUserReturn => {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const supabase = supabaseConnect();

    // Get initial user
    supabase.auth.getUser()
      .then(({ data: { user }, error }) => {
        if (error) throw error;
        setUser(user);
        return user;
      })
      .then(async (user) => {
        if (user?.id) {
          // Get user profile
          const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', user.id)
            .single();

          if (error) throw error;
          setProfile(data);

          // Update last_active
          await updateLastActive(user.id);
        }
      })
      .catch((error) => {
        console.error('Error loading user:', error);
        setError(error instanceof Error ? error : new Error('Failed to load user'));
      })
      .finally(() => {
        setIsLoading(false);
      });

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null);

      if (session?.user) {
        try {
          // Get user profile
          const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', session.user.id)
            .single();

          if (error) throw error;
          setProfile(data);

          // Update last_active
          await updateLastActive(session.user.id);
        } catch (error) {
          console.error('Error loading profile:', error);
          setError(error instanceof Error ? error : new Error('Failed to load profile'));
        }
      } else {
        setProfile(null);
      }
    });

    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { user, profile, isLoading, error };
};

export default useUser; 