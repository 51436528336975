import supabaseConnect from '../../../../../utils/supabaseConnect';
import { DynamicContentBlock } from '../ContentSelector/types';

interface FetchDynamicContentParams {
  block: DynamicContentBlock;
}

export const fetchDynamicContent = async ({ block }: FetchDynamicContentParams) => {
  try {
    console.log('fetchDynamicContent called with block:', block);
    const supabase = supabaseConnect();
    
    // Determine the table based on content type
    const table = block.type === 'ANNOUNCEMENT' ? 'announcement' : 'daily_updates';
    console.log('Querying table:', table);
    
    // Calculate date range
    let startDate, endDate;
    const now = new Date();
    
    switch (block.filter.timeRange) {
      case 'TODAY':
        startDate = now.toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
        break;
      case 'LAST_7_DAYS':
        endDate = now.toISOString().split('T')[0];
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        break;
      case 'THIS_MONTH':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];
        break;
      case 'LAST_30_DAYS':
        endDate = now.toISOString().split('T')[0];
        startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        break;
      case 'ALL':
        // Don't apply date filters for ALL
        break;
      default:
        console.warn('Unknown time range:', block.filter.timeRange);
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
        endDate = now.toISOString().split('T')[0];
    }

    console.log('Date range:', { startDate, endDate });

    // Build query
    let query = supabase.from(table).select('*');
    
    // Only apply date filters if we're not fetching ALL
    if (block.filter.timeRange !== 'ALL') {
      // Debug the actual query being executed
      console.log(`Querying ${table} where created_at >= ${startDate} AND created_at <= ${endDate}`);
      
      query = query
        .gte('created_at', startDate)
        .lte('created_at', endDate);
    }
    
    query = query.order('created_at', { ascending: false });

    console.log('Executing query...');
    const { data, error } = await query;

    if (error) {
      console.error('Database error:', error);
      throw new Error(`Failed to fetch ${table}: ${error.message}`);
    }

    if (!data || data.length === 0) {
      console.warn('No data found for the specified criteria');
      
      // For debugging purposes, try to fetch all records to see if there's any data at all
      console.log('Attempting to fetch all records to check if table has data...');
      const { data: allData, error: allError } = await supabase.from(table).select('*').limit(5);
      
      if (allError) {
        console.error('Error fetching all records:', allError);
      } else {
        console.log(`Found ${allData?.length || 0} records in total (sample):`);
        if (allData && allData.length > 0) {
          console.log('Sample record:', allData[0]);
          console.log('Sample record created_at:', allData[0].created_at);
        }
      }
      
      return [];
    }

    console.log(`Found ${data.length} items`);
    return data;
  } catch (error: any) {
    console.error('Error in fetchDynamicContent:', error);
    throw new Error(`Failed to fetch dynamic content: ${error.message}`);
  }
}; 