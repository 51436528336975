import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { line_1 } from "../../../assets";
import supabaseConnect from "../../../utils/supabaseConnect";

interface Props {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setActiveAnnouncement: Dispatch<SetStateAction<any[]>>;
  activeAnnouncement: any[];
}

interface AnnouncementType {
  id: string;
  title: string;
  description: string;
  created_at: string;
  info: string;
  cover_image: string;
  expired: boolean;
}

const Announcements: React.FC<Props> = ({
  setIsModalOpen,
  setActiveAnnouncement,
  activeAnnouncement,
}) => {
  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([]);
  const [loading, setLoading] = useState(true);
  const supabase = supabaseConnect();

  useEffect(() => {
    const getAnnouncements = async () => {
      const { data: fetchedAnnouncements, error } = await supabase
        .from("announcement")
        .select("*")
        .eq('expired', false)
        .order('created_at', { ascending: false });

      if (error) return console.log(error);

      const processedAnnouncements = fetchedAnnouncements?.map((announcement: AnnouncementType) => ({
        ...announcement,
        description: announcement.description.length > 50 
          ? announcement.description.slice(0, 50) + "..."
          : announcement.description
      })).slice(0, 3);

      setAnnouncements(processedAnnouncements);
      setLoading(false);
    };
    getAnnouncements();
  }, []);

  const Skeleton = () => (
    <div className="flex flex-col h-[29rem] max-h-[29rem] min-h-80 border rounded-lg text-base bg-black p-4 animate-pulse">
      <h2
        style={{
          borderBottom: "2px solid #08C2F8",
        }}
        className="text-white font-thin max-lg:m-0 pb-[1rem] mb-2"
      >
        Announcements
      </h2>
      <div className="flex-grow overflow-y-auto">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            style={{
              borderBottom: "2px solid #08C2F8",
            }}
            className="cursor-pointer py-2"
          >
            <div className="text-white pl-[1.2rem] break-words">
              <div className="h-4 bg-gray-300 rounded mb-1"></div>
            </div>
            <div className="text-white pl-[1.2rem] text-gray-400 text-[.8rem] pt-[10px]">
              <div className="h-3 bg-gray-300 rounded mb-1"></div>
            </div>
            <div className="text-white px-[1.2rem] pb-[1.2rem] text-[.8rem] text-wrap overflow-x-hidden">
              <div className="h-3 bg-gray-300 rounded"></div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-white pl-[1.2rem] text-[.8rem] pt-2 mt-auto">
        <div className="h-3 bg-gray-300 rounded"></div>
      </div>
    </div>
  );

  const convertDate = (date: string) => {
    const [year, month, day] = date.split("T")[0].split("-");
    return `${month}/${day}/${year}`;
  };

  const sliceDescription = (description: string) => {
    if (description.length > 200) {
      return description.slice(0, 200) + "...";
    }
    return description;
  };

  const announcementOnClick = async (announcement: any) => {
    // Fetch the full announcement data
    const { data, error } = await supabase
      .from("announcement")
      .select("*")
      .eq("id", announcement.id)
      .single();

    if (error) {
      console.error("Error fetching full announcement:", error);
      return;
    }

    setActiveAnnouncement([data]);
    setIsModalOpen(true);
  };

  const handleLoadMore = async () => {
    window.location.href = "/announcements";
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="flex flex-col h-[29rem] max-h-[29rem] min-h-80 border rounded-lg text-base bg-black p-4">
          <h2
            style={{
              borderBottom: "2px solid #08C2F8",
            }}
            className="text-white font-thin max-lg:m-0 pb-[1rem] mb-2"
          >
            Announcements
          </h2>
          <div className="flex-grow overflow-y-auto">
            {announcements.map((announcement: any) => (
              <div
                key={announcement.id}
                style={{
                  borderBottom: "2px solid #08C2F8",
                }}
                className="cursor-pointer py-2"
                onClick={() => announcementOnClick(announcement)}
              >
                <div className="text-white pl-[1.2rem] break-words">
                  <span className="text-sm md:text-base lg:text-lg">{announcement.title}</span>
                </div>
                <div className="text-white pl-[1.2rem] text-gray-400 text-[.8rem] pt-[10px]">
                  {convertDate(announcement.created_at)}
                </div>
                <div className="text-white px-[1.2rem] pb-[1.2rem] text-[.8rem] text-wrap overflow-x-hidden">
                  {sliceDescription(announcement.description)}
                </div>
              </div>
            ))}
          </div>
          <div
            onClick={handleLoadMore}
            className="text-white pl-[1.2rem] text-[.8rem] pt-2 cursor-pointer mt-auto"
          >
            Load More
          </div>
        </div>
      )}
    </>
  );
};

export default Announcements;
