import React, { useEffect, useState, useCallback } from 'react';
import { FaSearch, FaFilter, FaTimes, FaPlus } from 'react-icons/fa';
import debounce from 'lodash/debounce';

interface Category {
  id: number;
  name: string;
}

interface PartnerAndVendorSearchAndFilterProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  selectedCategories: Category[];
  handleCategoryRemove: (categoryId: number) => void;
  setIsFilterMenuOpen: (isOpen: boolean) => void;
  isFilterMenuOpen: boolean;
  categorySearchTerm: string;
  setCategorySearchTerm: (term: string) => void;
  filteredCategories: Category[];
  handleCategorySelect: (category: Category) => void;
  allCategories: Category[];
  isAdmin: boolean;
  handleCreateItem: () => void;
  showMyItemsButton?: boolean;
  isShowingMyItems?: boolean;
  onMyItemsToggle?: () => void;
  currentUserId?: string | null;
  showCreateButton?: boolean;
}

const PartnerAndVendorSearchAndFilter: React.FC<PartnerAndVendorSearchAndFilterProps> = ({
  searchTerm,
  setSearchTerm,
  selectedCategories,
  handleCategoryRemove,
  setIsFilterMenuOpen,
  isFilterMenuOpen,
  categorySearchTerm,
  setCategorySearchTerm,
  filteredCategories,
  handleCategorySelect,
  allCategories,
  isAdmin,
  handleCreateItem,
  showMyItemsButton = false,
  isShowingMyItems = false,
  onMyItemsToggle,
  currentUserId,
  showCreateButton = false,
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  const debouncedSetSearchTerm = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 300),
    [setSearchTerm]
  );

  useEffect(() => {
    debouncedSetSearchTerm(localSearchTerm);
  }, [localSearchTerm, debouncedSetSearchTerm]);

  useEffect(() => {
    const searchTermLower = localSearchTerm.toLowerCase();

    allCategories.forEach(category => {
      const categoryNameLower = category.name.toLowerCase();
      if (searchTermLower.includes(categoryNameLower)) {
        console.log('Matched category:', category);
        if (!selectedCategories.some(cat => cat.id === category.id)) {
          console.log('Adding category:', category);
          handleCategorySelect(category);
          // Remove the matched category name from the search term
          setLocalSearchTerm(prev => 
            prev.replace(new RegExp(category.name, 'i'), '').trim()
          );
        } else {
          console.log('Category already selected:', category);
        }
      }
    });
  }, [localSearchTerm, allCategories, selectedCategories, handleCategorySelect]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchTerm(e.target.value);
  };

  return (
    <>
      <div className="mt-5 flex items-center w-[48rem]">
        <div className="relative flex-grow flex">
          <input
            type="text"
            placeholder="Search..."
            value={localSearchTerm}
            onChange={handleInputChange}
            className="flex-grow p-2 pl-10 pr-4 rounded-l-lg bg-[#2d3035] text-white"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <div className="flex items-center ml-2">
            <button
              onClick={() => setSearchTerm(localSearchTerm)}
              className="bg-[#2d3035] text-white px-5 py-2 rounded-lg hover:bg-communityBlue transition-colors cursor-pointer"
            >
              Search
            </button>
            <button
              onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
              className="ml-2 p-2 bg-[#2d3035] rounded-lg cursor-pointer hover:bg-communityBlue transition-colors"
            >
              <FaFilter className="text-white" />
            </button>
           
            {(isAdmin || showCreateButton) && (
              <button
                onClick={handleCreateItem}
                className="ml-2 p-2 bg-[#2d3035] rounded-lg cursor-pointer hover:bg-communityBlue transition-colors"
              >
                <FaPlus className="text-white" />
              </button>
            )}
             {showMyItemsButton && currentUserId && (
              <button
                onClick={onMyItemsToggle}
                className={`ml-2 p-2 bg-[#2d3035] rounded-lg cursor-pointer transition-colors ${
                  isShowingMyItems 
                    ? 'bg-[#2d3035] hover:bg-communityBlue text-white' 
                    : 'hover:bg-communityBlue text-white'
                }`}
              >
                {isShowingMyItems ? 'All Vendors' : 'My Vendors'}
              </button>
            )}
          </div>
        </div>
      </div>
      <div 
        className={`fixed right-0 top-0 h-full bg-[#2d3035] overflow-hidden shadow-lg transition-all duration-300 ease-in-out transform border-l border-white ${
          isFilterMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } z-50 w-full max-w-[100vw] sm:w-80 md:w-96 p-4 flex flex-col`}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Filter Categories</h3>
          <button
            onClick={() => setIsFilterMenuOpen(false)}
            className="text-white hover:text-communityBlue cursor-pointer bg-black"
          >
            <FaTimes />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search categories..."
          value={categorySearchTerm}
          onChange={(e) => setCategorySearchTerm(e.target.value)}
          className="w-full p-2 mb-4 rounded-lg bg-[#1d2025] text-white box-border"
        />
        <div className="flex-grow border border-gray-600 rounded-lg p-2 border-solid border-white border-opacity-20 border-1" style={{ maxHeight: '50%', overflowY: 'auto', scrollbarWidth: 'thin', msOverflowStyle: 'auto' }}>
          <div className="h-full overflow-y-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}>
            {filteredCategories.map(category => (
              <div
                key={category.id}
                onClick={() => handleCategorySelect(category)}
                className="cursor-pointer p-2 pb-3 hover:bg-communityBlue rounded"
              >
                {category.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isFilterMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsFilterMenuOpen(false)}
        ></div>
      )}
    </>
  );
};

export default PartnerAndVendorSearchAndFilter;