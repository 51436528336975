import React from 'react';
import { IconType } from 'react-icons';

/**
 * SidebarLink component for rendering individual navigation links in the sidebar.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string | IconType} props.icon - URL of the icon to display or React icon
 * @param {string} props.text - Text to display for the link
 * @param {boolean} props.isActive - Whether the link is currently active
 * @param {() => void} props.onClick - Function to handle click event
 * 
 * @example
 * <SidebarLink icon={dashboardIcon} text="Dashboard" isActive={true} onClick={handleDashboardClick} />
 * 
 * @remarks
 * If this component is missing, the sidebar would not have any clickable navigation links,
 * preventing users from navigating through different sections of the application.
 */
interface SidebarLinkProps {
  icon: string | IconType;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ icon, text, isActive, onClick }) => {
  return (
    <div
      className={`${
        isActive ? "text-white" : "text-gray-400"
      } self-stretch flex flex-row items-center justify-between cursor-pointer z-10 transition-colors duration-200`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center justify-start gap-[8px]">
        {typeof icon === 'string' ? (
          <img
            className={`relative w-[18px] h-[18px] overflow-hidden shrink-0 ${
              isActive ? "" : "opacity-50"
            }`}
            alt=""
            src={icon}
          />
        ) : (
          React.createElement(icon, { className: "w-[18px] h-[18px]"})
        )}
        <div className="relative font-medium inline-block shrink-0 hover:text-communityBlue">{text}</div>
      </div>
    </div>
  );
};

export default SidebarLink;
