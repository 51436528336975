// @ts-nocheck
import { SlowBuffer } from "buffer";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import supabaseConnect from "../../utils/supabaseConnect";
import convertToTimestamptz from "../../utils/timeStampConvert";
import getEvents from "../../utils/getEvents";
import { start } from "repl";
import { ZonedDateTime, ZoneId, DateTimeFormatter, LocalDate, LocalTime, Instant, LocalDateTime } from '@js-joda/core'
import '@js-joda/timezone'

// todo: need to finished error handling and validation Solution is to map throught the errors array looking for the field name and then displaying the message

const supabase: any = supabaseConnect();

const NewEventModal = ({
  showModal,
  setShowModal,
  setList,
  setEventData,
  activeEvent,
}: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setList: (list: any) => void;
  setEventData: (eventData: any) => void;
  activeEvent: any;
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [errors, setErrors] = useState([]) as any[];
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const deleteButtonRef = useRef(null);

  const [event, setEvent] = useState({
    title,
    description,
    eventDetails,
    location,
    startDate,
    endDate,
    startTime,
    endTime,
  });

  const clearInput = () => {
    setTitle("");
    setDescription("");
    setEventDetails("");
    setLocation("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
  };

  interface Error {
    field: string;
    message: string;
  }

  const convertToMST = (date: string, time: string) => {
    const localDateTime = LocalDate.parse(date).atTime(LocalTime.parse(time));
    const mstZone = ZoneId.of('America/Denver');
    const zonedDateTime = ZonedDateTime.of(localDateTime, mstZone);
    return zonedDateTime.toInstant().toString();
  };

  const submit = async () => {
    // Validation rules
    const validationRules = [
      { field: "title", message: "Title cannot be empty" },
      { field: "description", message: "Description cannot be empty" },
      { field: "eventDetails", message: "Event Details cannot be empty" },
      { field: "location", message: "Location cannot be empty" },
      { field: "startDate", message: "Start Date cannot be empty" },
      { field: "endDate", message: "End Date cannot be empty" },
      { field: "startTime", message: "Start Time cannot be empty" },
      { field: "endTime", message: "End Time cannot be empty" },
    ];
    let isErrors = false;

    // Initial object containing input values
    const inputValues = {
      title,
      description,
      eventDetails,
      location,
      startDate,
      startTime,
    };


    const { startTimestamptz, endTimestamptz } = convertToTimestamptz(
      startDate,
      startTime
    );

    const getEventsData = async () => {
      const { data, error } = await supabase.from("event").select("*").limit(3);
      if (error) {
        console.log(error);
      } else {
        const sortedData = data?.sort((a: any, b: any) => {
          return (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          );
        });
        // filter out events that have already passed
        const currentDate = new Date();
        const filteredData = sortedData?.filter((event: any) => {
          return new Date(event.start_date) >= currentDate;
        });
        setEventData(filteredData);
      }
    };

    // Perform validation based on input values
    validationRules.forEach((rule) => {
      if (inputValues[rule.field as keyof typeof inputValues] === "") {
        setErrors((errors: Error[]) => [...errors, rule]);
        isErrors = true;
      }
    });

    // // Additional validation for time and dates
    // if (startTime !== "" && endTime !== "" && startTime > endTime) {
    //   setErrors((errors: Error[]) => [
    //     ...errors,
    //     { field: "endTime", message: "End Time cannot be before Start Time" },
    //   ]);
    //   isErrors = true;
    // }

    // if (startDate !== "" && endDate !== "" && startDate > endDate) {
    //   setErrors((errors: Error[]) => [
    //     ...errors,
    //     { field: "endDate", message: "End Date cannot be before Start Date" },
    //   ]);
    //   isErrors = true;
    // }

    if (isErrors === false) {
      const mstStartDate = convertToMST(startDate, startTime);

      if (activeEvent?.length > 0) {
        const { data, error } = await supabase
          .from("event")
          .update({
            title,
            description,
            info: eventDetails,
            location,
            start_date: mstStartDate,
          })
          .eq("id", activeEvent[0].id);
        if (error) {
          console.log(error);
        } else {
          console.log(data);
          clearInput();
          setErrors([]);
          getEventsData();
          window.location.reload();
        }
      } else {
        console.log("startTimestamptz", startTimestamptz);
        const { data, error } = await supabase.from("event").insert({
          title,
          description,
          info: eventDetails,
          location,
          start_date: mstStartDate,
        });
        if (error) {
          console.log(error);
        } else {
          console.log(data);
          clearInput();
          setErrors([]);
          getEventsData();
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    console.log("activeEvent", activeEvent);

    const convertToISO8601 = (dateString: string) => {
      // Check if the string is already in ISO 8601 format
      if (dateString.includes('T')) {
        // Remove seconds and milliseconds if present
        return dateString.substring(0, 16);
      }
      // If it contains '+', replace it with ':' and adjust the format
      if (dateString.includes('+')) {
        const [datePart, timePart] = dateString.split(' ');
        const [time, offset] = timePart.split('+');
        // Remove seconds if present
        const trimmedTime = time.substring(0, 5);
        return `${datePart}T${trimmedTime}`;
      }
      // If not, assume it's in "YYYY-MM-DD HH:mm:ss" format and convert
      const [datePart, timePart] = dateString.split(' ');
      // Remove seconds if present
      const trimmedTime = timePart.substring(0, 5);
      return `${datePart}T${trimmedTime}`;
    };

    const convertDate = (date: string) => {
      if (!date) return "";
      const isoDate = convertToISO8601(date);
      const mstZone = ZoneId.of('America/Denver');
      // Parse as LocalDateTime first, then convert to ZonedDateTime
      const localDateTime = LocalDateTime.parse(isoDate);
      const zonedDateTime = localDateTime.atZone(mstZone);
      return zonedDateTime.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'));
    };

    const convertTime = (dateString: string) => {
      if (!dateString) return "";
      const isoDate = convertToISO8601(dateString);
      const mstZone = ZoneId.of('America/Denver');
      // Parse as LocalDateTime first, then convert to ZonedDateTime
      const localDateTime = LocalDateTime.parse(isoDate);
      const zonedDateTime = localDateTime.atZone(mstZone);
      return zonedDateTime.format(DateTimeFormatter.ofPattern('HH:mm'));
    };

    if (activeEvent?.length > 0) {
      setTitle(activeEvent[0]?.title);
      setDescription(activeEvent[0]?.description);
      setEventDetails(activeEvent[0]?.info);
      setLocation(activeEvent[0]?.location);
      setStartDate(convertDate(activeEvent[0]?.start_date));
      setStartTime(convertTime(activeEvent[0]?.start_date));
    }
  }, [activeEvent]);

  const deleteEvent = async () => {
    const { data, error } = await supabase
      .from("event")
      .delete()
      .eq("id", activeEvent[0].id);
    if (error) {
      console.log(error);
    } else {
      console.log(data);
      clearInput();
      setErrors([]);
      window.location.reload();
    }
  };

  const handleDeleteClick = () => {
    console.log("showConfirmDelete", showConfirmDelete);
    if (showConfirmDelete) {
      // If already in confirmation mode, run the delete function
      deleteEvent();
    } else {
      // Show confirm delete message
      console.log("showConfirmDelete", showConfirmDelete);
      setShowConfirmDelete(true);
    }
  };

  // Reset the confirmation mode if needed elsewhere in your component
  const resetConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const handleClickOutside = (event) => {
    if (
      showConfirmDelete &&
      deleteButtonRef.current &&
      !deleteButtonRef.current.contains(event.target)
    ) {
      resetConfirmation();
    }
  };

  // Add event listeners when component mounts and handles the conditional logic
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    // Clean up event listener to prevent memory leaks
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showConfirmDelete]); // Only re-run if showConfirmDelete changes

  const editEvent = async () => {
    console.log("editEvent", 'testing');
    // check validation
    const validationRules = [
      { field: "title", message: "Title cannot be empty" },
      { field: "description", message: "Description cannot be empty" },
      { field: "eventDetails", message: "Event Details cannot be empty" },
      { field: "location", message: "Location cannot be empty" },
      { field: "startDate", message: "Start Date cannot be empty" },
      { field: "startTime", message: "Start Time cannot be empty" },
    ];
    let isErrors = false;
    if (isErrors === false) {
      const mstStartDate = convertToMST(startDate, startTime);

      const { data, error } = await supabase
        .from("event")
        .update({
          title,
          description,
          info: eventDetails,
          location,
          start_date: mstStartDate,
        })
        .eq("id", activeEvent[0].id);
      if (error) {
        console.log(error);
      } else {
        // console.log(data);
        clearInput();
        setErrors([]);
        window.location.reload();
      }
    } else {
      validationRules.forEach((rule) => {
        if (inputValues[rule.field as keyof typeof inputValues] === "") {
          setErrors((errors: Error[]) => [...errors, rule]);
          isErrors = true;
        }
      });
    }
  }

  const convertTime = (date: string) => {
    // create a date obj for colorado time zone
    const dateObj = new Date(date);
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();

    return `${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    }`;
  };


  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <div className="fixed z-40 inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            {/* Background overlay */}
            <Transition.Child
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                aria-hidden="true"
                onClick={() => {
                  setShowModal(false);
                  setErrors([]);
                }}
              ></div>
            </Transition.Child>

            {/* Right sliding panel */}
            <Transition.Child
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <section
                className="modal-section absolute h-screen right-0 pl-10 max-w-full flex sm:pl-16 transition-all duration-500 z-50 text-[#f1f2f6]"
              >
                <div className="w-screen sm:max-w-md">
                  <div className="h-full flex flex-col bg-[#100f0f] shadow-xl overflow-y-hidden overflow-x-hidden">
                    {/* Panel header */}
                    <div className="px-4 py-6 bg-[#100f0f] sm:px-6">
                      <div className="flex items-start justify-between">
                        <h3
                          className="text-lg font-medium text-[#f1f2f6]"
                          id="modal-headline"
                        >
                          {activeEvent?.length > 0 ? "Edit Event" : "Add Event"}
                        </h3>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-[#100f0f] rounded-md text-[#f1f2f6] hover:text-[#099aad] focus:outline-none border-none cursor-pointer"
                            onClick={() => {
                              setShowModal(false);
                              setErrors([]);
                              clearInput();
                            }}
                          >
                            <AiOutlineClose className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Panel body */}
                    <div className="relative flex-1 py-6 px-4 sm:px-6">
                      <div className="mt-2">
                        <div className="text-sm text-[#f1f2f6]">
                          
                          <div className="mb-4">
                            <label
                              htmlFor="title"
                              className="block text-[#f1f2f6] text-sm font-bold mb-2"
                            >
                              Title:
                            </label>
                            <input
                              type="text"
                              id="title"
                              placeholder="Title"
                              className="shadow appearance-none border border-[#2a2828] rounded w-full py-2 px-3 text-[#f1f2f6] bg-[#1e1e1e] leading-tight focus:outline-none focus:shadow-outline focus:border-[#099aad]"
                              onChange={(e) => setTitle(e.target.value)}
                              value={title}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "description"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "description"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="description"
                              className="block text-[#f1f2f6] text-sm font-bold mb-2"
                            >
                              Description:
                            </label>
                            <input
                              type="text"
                              id="description"
                              placeholder="Description"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-[#f1f2f6] bg-[#1e1e1e] leading-tight focus:outline-none focus:shadow-outline focus:border-[#099aad]"
                              onChange={(e) => setDescription(e.target.value)}
                              value={description}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "eventDetails"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "eventDetails"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="eventDetails"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Event Details:
                            </label>
                            <textarea
                              id="eventDetails"
                              placeholder="Event Details"
                              className="shadow appearance-none border rounded w-full h-[5rem] text-[#f1f2f6] bg-[#1e1e1e] leading-tight focus:outline-none focus:shadow-outline focus:border-[#099aad] px-2 py-2 resize-none overflow-y-auto"
                              onChange={(e) => setEventDetails(e.target.value)}
                              value={eventDetails}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "location"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "location"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="location"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Location:
                            </label>
                            <input
                              type="text"
                              id="location"
                              placeholder="Location"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-[#f1f2f6] bg-[#1e1e1e] leading-tight focus:outline-none focus:shadow-outline focus:border-[#099aad]"
                              onChange={(e) => setLocation(e.target.value)}
                              value={location}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "startDate"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "startDate"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="date"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Start Date:
                            </label>
                            <input
                              type="date"
                              id="date"
                              placeholder="Date"
                              className="shadow appearance-none border rounded w-[7rem] py-2 px-3 text-[#f1f2f6] bg-[#1e1e1e] leading-tight focus:outline-none focus:shadow-outline focus:border-[#099aad]"
                              onChange={(e) => setStartDate(e.target.value)}
                              value={startDate}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "startTime"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "startTime"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="time"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Start Time:
                            </label>
                            <input
                              type="time"
                              id="time"
                              placeholder="Time"
                              className="shadow appearance-none border rounded w-[6rem] py-2 px-3 text-[#f1f2f6] bg-[#1e1e1e] leading-tight focus:outline-none focus:shadow-outline focus:border-[#099aad]"
                              onChange={(e) => setStartTime(e.target.value)}
                              value={startTime}
                            />
                          </div>
                         
                        </div>
                      </div>
                    </div>

                    {/* Panel footer */}
                    <div className="flex-shrink-0 px-4 py-4 flex sm:flex-row flex-col sm:gap-2 gap-2 justify-start bg-[#100f0f]">
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                          setErrors([]);
                          clearInput();
                        }}
                        className="inline-flex justify-center rounded-md border border-[#2a2828] shadow-sm px-4 py-2 bg-[#1e1e1e] text-base font-medium text-[#f1f2f6] hover:bg-[#2a2828] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#099aad] sm:mt-0 sm:w-auto sm:text-sm cursor-pointer"
                      >
                        Cancel
                      </button>
                      {activeEvent?.length > 0 ? (
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#099aad] text-base font-medium text-[#f1f2f6] hover:bg-[#077d8d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#099aad] sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                          onClick={() => {
                            editEvent();
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#099aad] text-base font-medium text-[#f1f2f6] hover:bg-[#077d8d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#099aad] sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                          onClick={() => {
                            submit();
                          }}
                        >
                          Save
                        </button>
                      )}
                      {activeEvent?.length > 0 && (
                        <button
                          type="button"
                          ref={deleteButtonRef}
                          className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-[#f1f2f6] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                          onClick={() => {
                            handleDeleteClick();
                          }}
                        >
                          {showConfirmDelete ? "Confirm Delete?" : "Delete"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default NewEventModal;
