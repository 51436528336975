import React from 'react';
import { WorkflowDesigner } from './components/WorkflowDesigner';

const WorkflowAutomation: React.FC = () => {
  return (
    <div className="flex flex-col h-[calc(100vh-4rem)] overflow-hidden -mt-10">
      <div className="flex items-center justify-between p-4 border-b border-gray-700">
        <h1 className="text-2xl font-bold text-white">Workflow Creator</h1>
      </div>
      <div className="flex-1 overflow-hidden px-4">
        <WorkflowDesigner />
      </div>
    </div>
  );
};

export default WorkflowAutomation; 