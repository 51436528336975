import React, { useState } from 'react';
import { FaSearch, FaClock, FaDatabase, FaEnvelope } from 'react-icons/fa';
import { type NodeType } from './index';

interface NodeSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectNode: (nodeType: NodeType) => void;
  filterCategory?: 'triggers' | 'actions';
  title?: string;
}

const nodeCategories = [
  {
    title: 'Triggers',
    nodes: [
      { id: 'schedule', label: 'Schedule', icon: <FaClock />, description: 'Trigger based on time' },
      { id: 'database', label: 'Database', icon: <FaDatabase />, description: 'Trigger on database events' },
    ],
  },
  {
    title: 'Actions',
    nodes: [
      { id: 'email', label: 'Send Email', icon: <FaEnvelope />, description: 'Send an email' },
    ],
  },
];

export const NodeSelector: React.FC<NodeSelectorProps> = ({
  isOpen,
  onClose,
  onSelectNode,
  filterCategory,
  title = 'Add Node',
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const filteredCategories = nodeCategories
    .filter(category => {
      if (!filterCategory) return true;
      return filterCategory === 'triggers' ? category.title === 'Triggers' : category.title === 'Actions';
    })
    .map(category => ({
      ...category,
      nodes: category.nodes.filter(node =>
        node.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.description.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter(category => category.nodes.length > 0);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1a1a1a] rounded-lg w-full max-w-2xl max-h-[80vh] overflow-hidden">
        <div className="p-4 border-b border-gray-700">
          <h2 className="text-lg font-semibold text-white mb-4">{title}</h2>
          <div className="relative max-w-xs">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search nodes..."
              className="w-full pl-10 pr-4 py-2 bg-[#2a2828] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="p-4 overflow-y-auto max-h-[calc(80vh-80px)]">
          {filteredCategories.map((category) => (
            <div key={category.title} className="mb-6 last:mb-0">
              <h3 className="text-lg font-semibold text-gray-400 mb-3">
                {category.title}
              </h3>
              <div className="grid grid-cols-1 gap-2">
                {category.nodes.map((node) => (
                  <button
                    key={node.id}
                    className="flex items-center gap-3 p-3 rounded-md hover:bg-gray-700 transition-colors text-left cursor-pointer group"
                    onClick={() => onSelectNode(node.id as NodeType)}
                  >
                    <div className="text-blue-500">{node.icon}</div>
                    <div>
                      <div className="text-gray-800 font-medium group-hover:text-white transition-colors">{node.label}</div>
                      <div className="text-sm text-gray-400">{node.description}</div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="p-4 border-t border-gray-700 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-600 transition-colors cursor-pointer"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}; 