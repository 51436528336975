import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import MainLayoutWrapper from '../../components/Layout/MainLayoutWrapper';
import supabaseConnect from '../../utils/supabaseConnect';
import { sanitizeHtmlContent } from '../../utils/sanitizeHtml';
import StarRating from '../../components/Rating/StarRating';
import RatingModal from '../../components/Rating/RatingModal';
import RatingsOverview from '../../components/Rating/RatingsOverview';

const PartnerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [partner, setPartner] = useState<any>(null);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [ratingCount, setRatingCount] = useState<number>(0);
  const [userRating, setUserRating] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showRatingsOverview, setShowRatingsOverview] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [ratingsData, setRatingsData] = useState<{ average: number, count: number } | null>(null);
  const [ratingsLoading, setRatingsLoading] = useState<boolean>(true);
  const [ratingsError, setRatingsError] = useState<string | null>(null);

  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  useEffect(() => {
    fetchPartnerDetails();
    fetchRatings();
  }, [id]);

  useEffect(() => {
    if (ratingsData) {
      setAverageRating(ratingsData.average);
      setRatingCount(ratingsData.count);
    }
  }, [ratingsData]);

  useEffect(() => {
    const checkUserRating = async () => {
      const supabase = supabaseConnect();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('partner_ratings')
          .select('rating')
          .eq('partner_id', id)
          .eq('profile_id', user.id)
          .single();

        if (error) {
          console.error('Error checking user rating:', error);
        } else if (data) {
          setUserRating(data.rating);
        }
      }
    };

    checkUserRating();
  }, [id]);

  useEffect(() => {
    const supabase = supabaseConnect();
    const subscription = supabase
      .channel('partner_ratings')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'partner_ratings' }, (payload) => {
        if (payload.new && typeof payload.new === 'object' && 'partner_id' in payload.new) {
          if (payload.new.partner_id === id) {
            fetchRatings();
          }
        }
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [id]);

  /**
   * Fetches partner details from the database.
   * @remarks
   * If this function fails, the partner details will not be displayed.
   */
  const fetchPartnerDetails = async () => {
    const supabase = supabaseConnect();
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('partners')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      setPartner(data);
    } catch (error) {
      setError('Failed to fetch partner details');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetches ratings for the partner from the database.
   * @remarks
   * If this function fails, the ratings will not be displayed.
   */
  const fetchRatings = async () => {
    const supabase = supabaseConnect();
    try {
      setRatingsLoading(true);
      const { data, error } = await supabase
        .from('partner_ratings')
        .select('*')
        .eq('partner_id', id);

      if (error) throw error;
      const ratings = data.map(r => r.rating);
      const avg = ratings.length > 0 ? ratings.reduce((a, b) => a + b, 0) / ratings.length : 0;
      setRatingsData({ average: avg, count: ratings.length });
    } catch (error) {
      console.error('Error fetching ratings:', error);
      setRatingsError('Failed to fetch ratings');
    } finally {
      setRatingsLoading(false);
    }
  };

  /**
   * Handles the click event for rating a partner.
   * @param rating - The rating value.
   * @remarks
   * If the user has already rated, they cannot rate again.
   */
  const handleRatingClick = (rating: number) => {
    if (userRating === null) {
      setSelectedRating(rating);
      setIsModalOpen(true);
    }
  };

  /**
   * Submits the rating for the partner.
   * @param rating - The rating value.
   * @param comment - The comment for the rating.
   * @remarks
   * If this function fails, the rating will not be submitted.
   */
  const handleRatingSubmit = async (rating: number, comment: string) => {
    const supabase = supabaseConnect();
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      try {
        const { error } = await supabase
          .from('partner_ratings')
          .upsert({
            partner_id: id,
            profile_id: user.id,
            rating,
            comment
          });

        if (error) throw error;
        fetchRatings();
        setUserRating(rating);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error submitting rating:', error);
        alert('Failed to submit rating. Please try again.');
      }
    }
  };

  /**
   * Handles the click event for opening the email client.
   * @param email - The email address.
   * @remarks
   * If this function fails, the email client will not open.
   */
  const handleEmailClick = (email: string) => {
    if (email && email !== 'N/A') {
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`, '_blank');
    }
  };

  if (loading || ratingsLoading) return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </MainLayoutWrapper>
    </div>
  );

  if (error || ratingsError) return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="flex items-center justify-center h-full">
          <div className="text-red-500 text-xl">{error || ratingsError}</div>
        </div>
      </MainLayoutWrapper>
    </div>
  );

  if (!partner) return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="flex items-center justify-center h-full">
          <div className="text-xl">Partner not found</div>
        </div>
      </MainLayoutWrapper>
    </div>
  );

  const sanitizedInfo = sanitizeHtmlContent(partner.info);

  return (
    <div className="flex w-full h-screen overflow-hidden text-white bg-[#1d2025]">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5 flex flex-col h-full">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-6 text-center text-white"
            style={{
              WebkitTextStroke: '.2px white',
              textShadow: '0 0 3px rgba(255,255,255,0.5)',
              ...({ "textStroke": '1px white' } as React.CSSProperties)
            }}>
            Community Partner
          </h1>
          <div className="relative flex-grow overflow-hidden">
            <div className="absolute inset-0 bg-black rounded-lg shadow-lg border border-gray-800 overflow-y-auto partner-details-content">
              <div className="p-8 lg:pr-[28rem]">
                <div
                  className="text-lg partner-description prose prose-invert text-white"
                  dangerouslySetInnerHTML={{ __html: sanitizedInfo }}
                  role="region"
                  aria-label="Partner Description"
                />
              </div>
            </div>
            <div className="lg:absolute lg:right-8 lg:top-[10%] w-full lg:w-80 mt-8 lg:mt-0 partner-details-partner-card">
              <div className="bg-gray-900 rounded-lg shadow-xl overflow-hidden hover:shadow-2xl transition-shadow duration-300" style={{ border: '1px solid #D1D5DB' }}>
                {partner.logo_url && (
                  <div className="w-full h-full bg-white flex items-center justify-center" style={{ border: '1px solid #D1D5DB' }}>
                    <img
                      src={partner.logo_url}
                      alt={`${partner.name} logo`}
                      className="max-w-full max-h-full object-contain"
                      loading="lazy"
                    />
                  </div>
                )}
                <div className="p-6 border border-gray-700 border-t-0 flex flex-col h-[calc(100%-10rem)]">
                  <h2 className="text-2xl font-semibold text-center mb-6">{partner.name}</h2>
                  <div className="mb-6 flex-grow">
                    <h3 className="text-lg font-semibold mb-3">Contact Information</h3>
                    {partner.website_url && (
                      <a
                        href={partner.website_url.startsWith('http') ? partner.website_url : `https://${partner.website_url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:underline block mb-3 text-base"
                        aria-label={`Visit ${partner.name}'s website`}
                      >
                        {partner.name}'s Website
                      </a>
                    )}
                    {!partner.website_url && partner.email && (
                      <p className="text-gray-400 mb-3 text-base">No website available</p>
                    )}
                  </div>
                  <div className="mt-auto mb-4 text-center">
                    <div className="flex items-center">
                      <div
                        className={userRating === null ? "cursor-pointer" : "cursor-default"}
                        aria-label={userRating === null ? "Rate this partner" : "Your rating"}
                      >
                        <StarRating
                          rating={userRating || averageRating}
                          readonly={userRating !== null}
                          onRatingChange={handleRatingClick}
                        />
                      </div>
                      <span
                        className="ml-2 cursor-pointer hover:underline text-sm"
                        onClick={() => setShowRatingsOverview(true)}
                      >
                        ({ratingCount} ratings)
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={() => handleEmailClick(partner.email)}
                      className={`font-bold py-3 px-6 rounded text-base transition-colors duration-300 w-full ${
                        partner.email && partner.email !== 'N/A'
                          ? 'bg-white hover:bg-gray-100 text-black cursor-pointer'
                          : 'bg-gray-500 text-gray-300 cursor-not-allowed'
                      }`}
                      disabled={!partner.email || partner.email === 'N/A'}
                      aria-label={partner.email && partner.email !== 'N/A' ? `Email ${partner.name}` : 'Email not available'}
                    >
                      {partner.email && partner.email !== 'N/A' ? 'Email Partner' : 'Email Not Available'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RatingModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedRating(null);
          }}
          onSubmit={handleRatingSubmit}
          initialRating={selectedRating || userRating}
        />
        {showRatingsOverview && (
          <RatingsOverview
            partnerId={id!}
            onClose={() => setShowRatingsOverview(false)}
          />
        )}
      </MainLayoutWrapper>
    </div>
  );
};

export default PartnerDetails;