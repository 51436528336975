import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaChartBar, FaCog, FaTachometerAlt, FaBars, FaTools, FaUserShield } from 'react-icons/fa';
import { SidebarLink } from './SidebarLink';
import { ProfileSection } from './ProfileSection';
import supabaseConnect from "../../../../utils/supabaseConnect";
import * as images from "../../../../assets";
import { useAdmin } from '../../../../components/Sidebar/hooks/useAdmin';
import { useStaff } from '../../../../components/Sidebar/hooks/useStaff';
import Tooltip from '@mui/material/Tooltip';

const StaffAndAdminSidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [link, setLink] = useState(location.pathname);
  const [hamburgerState, setHamburgerState] = useState(true);
  const [sideNavState, setSideNavState] = useState<string | null>(() => {
    const savedState = localStorage.getItem("realCommunitySideNav");
    return savedState || "full"; 
  });
  const [userProfile, setUserProfile] = useState<any>(null);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const [isAdmin, setIsAdmin] = useState<boolean>(() => {
    const cachedIsAdmin = localStorage.getItem('isAdmin');
    return cachedIsAdmin ? JSON.parse(cachedIsAdmin) : false;
  });
  const [isStaff, setIsStaff] = useState<boolean>(() => {
    const cachedIsStaff = localStorage.getItem('isStaff');
    return cachedIsStaff ? JSON.parse(cachedIsStaff) : false;
  });

  const [isLoading, setIsLoading] = useState(true);
  const { isAdmin: fetchedIsAdmin } = useAdmin(userId);
  const { isStaff: fetchedIsStaff } = useStaff(userId);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const { data: { user } } = await supabaseConnect().auth.getUser();
      if (user) {
        setUserId(user.id);
        const { data, error } = await supabaseConnect()
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        if (data) {
          setUserProfile(data);
        }
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIsAdmin(fetchedIsAdmin);
      setIsStaff(fetchedIsStaff);
      localStorage.setItem('isAdmin', JSON.stringify(fetchedIsAdmin));
      localStorage.setItem('isStaff', JSON.stringify(fetchedIsStaff));
    }
  }, [fetchedIsAdmin, fetchedIsStaff, isLoading]);

  const handleLinkClick = useCallback((path: string) => {
    setLink(path);
    navigate(path);
  }, [navigate]);

  const toggleSideNav = useCallback(() => {
    const newState = sideNavState === "full" ? "collapsed" : "full";
    localStorage.setItem("realCommunitySideNav", newState);
    setSideNavState(newState);
    setHamburgerState(true);
  }, [sideNavState]);

  const handleSignOut = async () => {
    const { error } = await supabaseConnect().auth.signOut();
    if (error) return console.log(error);
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isStaff');
    navigate("/login");
  };

  const useOutsideClicker = (ref: React.RefObject<HTMLElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setHamburgerState(true);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClicker(wrapperRef);

  const handleChevron = () => {
    setHamburgerState(!hamburgerState);
    setSideNavState("full");
  };

  const renderSidebarContent = useMemo(() => {
    const isCollapsed = sideNavState === "collapsed";
    return (
      <div id={isCollapsed ? "mini-inner-sidenav" : "full-inner-sidenav"} 
           className={`${isCollapsed ? "w-16" : "w-64"} flex flex-col flex-1 transition-all duration-300 ease-in-out`}>
        <div className="w-full flex flex-col flex-1 items-start justify-start gap-4 text-left text-base">
          <div className="bg-gray-800 w-full flex flex-col items-center justify-center box-border py-3">
            <img
              src={isCollapsed ? images.mini_logo : images.communityLogo}
              alt={isCollapsed ? "mini logo" : "community logo"}
              className={isCollapsed ? "w-[15px]" : "w-[180px] h-[4rem] cursor-pointer"}
              onClick={() => !isCollapsed && navigate("/")}
            />
          </div>
          <div className="w-full flex flex-col items-start justify-start">
            <div className="flex-1 flex flex-col items-start justify-start w-full">
              <Tooltip title="Coming soon" placement="right">
                <div className={`w-full ${isCollapsed ? 'flex justify-center' : ''}`}>
                  <SidebarLink
                    icon={<FaTachometerAlt />}
                    text="Dashboard"
                    isActive={link === "/staff-admin/dashboard"}
                    onClick={() => {handleLinkClick("/staff-admin/dashboard")}}
                    collapsed={isCollapsed}
                    disabled={false}
                  />
                </div>
              </Tooltip>
              <div className={`w-full ${isCollapsed ? 'flex justify-center' : ''}`}>
                <SidebarLink
                  icon={<FaTools />}
                  text="Tools"
                  isActive={link === "/staff-admin/tools"}
                  onClick={() => handleLinkClick("/staff-admin/tools")}
                  collapsed={isCollapsed}
                  disabled={false}
                />
              </div>
              {(isAdmin) && (
                <div className={`w-full ${isCollapsed ? 'flex justify-center' : ''}`}>
                  <SidebarLink
                    icon={<FaUserShield />}
                    text="Admin Panel"
                    isActive={link === "/staff-admin/admin-panel"}
                    onClick={() => handleLinkClick("/staff-admin/admin-panel")}
                    collapsed={isCollapsed}
                    disabled={false}
                  />
                </div>
              )}
              {isAdmin && (
                <>
                  <Tooltip title="Coming soon" placement="right">
                    <div className={`w-full ${isCollapsed ? 'flex justify-center' : ''}`}>
                      <SidebarLink
                        icon={<FaChartBar />}
                        text="Analytics"
                        isActive={false}
                        onClick={() => {}}
                        collapsed={isCollapsed}
                        disabled={true}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title="Coming soon" placement="right">
                    <div className={`w-full ${isCollapsed ? 'flex justify-center' : ''}`}>
                      <SidebarLink
                        icon={<FaCog />}
                        text="Settings"
                        isActive={false}
                        onClick={() => {}}
                        collapsed={isCollapsed}
                        disabled={true}
                      />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
        <ProfileSection 
          onSignOut={handleSignOut} 
          collapsed={isCollapsed} 
          isAdmin={isAdmin} 
          profile={userProfile}
        />
      </div>
    );
  }, [sideNavState, link, isAdmin, isStaff, userProfile, handleLinkClick, handleSignOut, navigate]);

  const updateRoles = useCallback(() => {
    setIsAdmin(fetchedIsAdmin);
    setIsStaff(fetchedIsStaff);
    localStorage.setItem('isAdmin', JSON.stringify(fetchedIsAdmin));
    localStorage.setItem('isStaff', JSON.stringify(fetchedIsStaff));
    setIsLoading(false);
  }, [fetchedIsAdmin, fetchedIsStaff]);

  useEffect(() => {
    if (fetchedIsAdmin !== undefined && fetchedIsStaff !== undefined) {
      updateRoles();
    }
  }, [fetchedIsAdmin, fetchedIsStaff, updateRoles]);

  return (
    <div className={`${hamburgerState ? "relative" : "absolute z-20 max-sm:w-full"}`}>
      <div
        id="mobile-header"
        className="absolute top-0 flex bg-white font-black text-black h-11 w-screen justify-center items-center font-black text-lg lg:hidden z-20 border-4 border-blue-500"
      >
        <div
          className="HAMBURGER-ICON space-y-2 absolute top-3 left-3 lg:hidden cursor-pointer z-20"
          onClick={() => handleChevron()}
        >
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
        </div>
        <img
          src={images.CommunityLogoNoBackground}
          alt="community logo"
          className="w-[11rem] h-[2rem]"
        />
      </div>

      <aside
        className={`relative ${
          hamburgerState ? "lg:flex hidden" : "flex fixed top-11 left-0 z-30"
        } flex-row justify-between text-center text-xs h-screen bg-black text-white transition-all duration-300 ease-in-out`}
        ref={wrapperRef}
      >
        <div
          onClick={toggleSideNav}
          role="button"
          className={`absolute top-[50px] right-0 sm:right-[-17px] bg-communityBlue sm:rounded-tr-[4px] sm:rounded-br-[4px] max-sm:rounded-tl-[4px] max-sm:rounded-bl-[4px] flex px-[1px] py-1 cursor-pointer transition-transform duration-300 ${
            sideNavState === "full" ? "transform translate-x-0" : "transform -translate-x-[17px]"
          }`}
        >
          <img
            id="chevron"
            src={images.chevron_right}
            width="15"
            height="15"
            alt=""
            className={`transition-transform duration-300 ${
              sideNavState === "full" ? "transform rotate-0" : "transform rotate-180"
            }`}
          />
        </div>

        {renderSidebarContent}
      </aside>

      <div
        id={`${hamburgerState === false ? "show-hidden-bg" : "hidden-bg"}`}
      ></div>
    </div>
  );
};

export default StaffAndAdminSidebar;