import React, { useState, useEffect } from 'react';
import supabaseConnect from '../../utils/supabaseConnect';
import StarRating from './StarRating';

interface RatingsOverviewProps {
  partnerId?: string;
  vendorId?: string;
  onClose: () => void;
}

const RatingsOverview: React.FC<RatingsOverviewProps> = ({ partnerId, vendorId, onClose }) => {
  const [ratings, setRatings] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState<string>('newest');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const ratingsPerPage = 10;

  useEffect(() => {
    fetchRatings();
  }, [partnerId, vendorId, sortBy, currentPage]);

  /**
   * Fetches ratings data for the partner or vendor
   * 
   * @remarks
   * If missing: Ratings data would not be loaded, resulting in an empty ratings display
   */
  const fetchRatings = async () => {
    const supabase = supabaseConnect();
    let query = supabase
      .from(partnerId ? 'partner_ratings' : 'vendor_ratings')
      .select(`
        *,
        profiles:profile_id (first_name, last_name)
      `, { count: 'exact' })
      .eq(partnerId ? 'partner_id' : 'vendor_id', partnerId || vendorId)
      .range((currentPage - 1) * ratingsPerPage, currentPage * ratingsPerPage - 1);

    if (sortBy === 'newest') {
      query = query.order('created_at', { ascending: false });
    } else if (sortBy === 'highest') {
      query = query.order('rating', { ascending: false });
    } else if (sortBy === 'lowest') {
      query = query.order('rating', { ascending: true });
    }

    const { data, error, count } = await query;

    if (error) {
      console.error('Error fetching ratings:', error);
    } else {
      setRatings(data || []);
      setTotalPages(Math.ceil((count || 0) / ratingsPerPage));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
      <div className="bg-[#1d2025] rounded-lg p-8 max-w-2xl w-full m-4 text-white">
        <h2 className="text-2xl font-bold mb-4">Ratings & Comments</h2>
        <select 
          value={sortBy} 
          onChange={(e) => setSortBy(e.target.value)}
          className="mb-4 p-2 border border-gray-600 rounded bg-[#2d3035] text-white"
        >
          <option value="newest">Newest</option>
          <option value="highest">Highest Rated</option>
          <option value="lowest">Lowest Rated</option>
        </select>
        {ratings.map((rating) => (
          <div key={rating.id} className="mb-4 p-4 border border-gray-600 rounded">
            <StarRating rating={rating.rating} readonly={true} />
            <p className="mt-2">{rating.comment}</p>
            <p className="mt-1 text-sm text-gray-400">
              By: {rating.profiles.first_name} {rating.profiles.last_name}
            </p>
          </div>
        ))}
        <div className="flex justify-between items-center mt-4">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
        <button 
          onClick={onClose}
          className="mt-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default RatingsOverview;