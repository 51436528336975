import React from 'react';
import { ScheduleTriggerConfig } from './ScheduleTriggerConfig';
import { DatabaseTriggerConfig } from './DatabaseTriggerConfig';
import { EmailActionConfig } from './EmailActionConfig';
import { NodeConfig } from './types';

interface NodeConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeType: string;
  config: NodeConfig;
  onSave: (config: NodeConfig) => void;
}

export const NodeConfigModal: React.FC<NodeConfigModalProps> = ({
  isOpen,
  onClose,
  nodeType,
  config,
  onSave,
}) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div 
        className="bg-[#1a1a1a] rounded-lg w-full max-w-2xl mx-auto my-4 overflow-hidden flex flex-col max-h-[90vh]"
        onClick={(e) => e.stopPropagation()}
      >
        {nodeType === 'schedule' && (
          <ScheduleTriggerConfig
            config={config as any}
            onSave={onSave}
            onClose={onClose}
          />
        )}
        
        {nodeType === 'database' && (
          <DatabaseTriggerConfig
            config={config as any}
            onSave={onSave}
            onClose={onClose}
          />
        )}
        
        {nodeType === 'email' && (
          <EmailActionConfig
            config={config as any}
            onSave={onSave}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
}; 