import React, { useRef, useCallback, useState, useEffect } from 'react';
import EmailEditor from 'react-email-editor';
import Button from '../../../../components/Button/Button';
import supabaseConnect from '../../../../utils/supabaseConnect';
import { Menu } from '@headlessui/react';
import TemplateSelectionModal from './TemplateSelectionModal';
import { EmailTemplate, TEMPLATE_CATEGORIES, TemplateCategory } from './types';
import useUser from '../../../../utils/supabase/useUser';

const DropdownMenu: React.FC<{
  label: string;
  items: { label: string; onClick: () => void; disabled?: boolean; preventClose?: boolean }[];
}> = ({ label, items }) => (
  <Menu as="div" className="relative">
    <Menu.Button className="px-3 py-1 text-white hover:bg-communityBlue rounded cursor-pointer bg-gray-700 bg-opacity-50">
      {label}
    </Menu.Button>
    <Menu.Items className="absolute left-0 mt-1 w-56 bg-gray-700 border border-[#2a2828] rounded-md shadow-lg z-50">
      {items.map((item, index) => (
        <Menu.Item key={index}>
          {({ active, close }) => (
            <button
              onClick={(e) => {
                if (item.preventClose) {
                  e.preventDefault();
                }
                item.onClick();
                if (!item.preventClose) {
                  close();
                }
              }}
              disabled={item.disabled}
              className={`${
                active ? 'bg-communityBlue text-white cursor-pointer' : 'text-white bg-gray-700'
              } ${
                item.disabled ? 'opacity-50 cursor-not-allowed' : ''
              } group flex w-full items-center px-4 py-2 text-sm`}
            >
              {item.label}
            </button>
          )}
        </Menu.Item>
      ))}
    </Menu.Items>
  </Menu>
);

/**
 * EmailTemplateBuilder component that provides a drag-and-drop interface for creating email templates
 * Uses Unlayer's EmailEditor component for the main functionality
 * Customized with site colors and theme preferences
 */
const EmailTemplateBuilder: React.FC = () => {
  const emailEditorRef = useRef<any>(null);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editingTitle, setEditingTitle] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const user = useUser();

  // Filter templates based on selected category
  const filteredTemplates = selectedCategory === 'All' 
    ? templates 
    : templates.filter(template => template.category === selectedCategory);

  /**
   * Loads all templates from the database
   */
  const loadTemplates = useCallback(async () => {
    try {
      setIsLoading(true);
      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('email_templates')
        .select('*')
        .order('updated_at', { ascending: false });

      if (error) throw error;
      setTemplates(data || []);
    } catch (error) {
      console.error('Error loading templates:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  /**
   * Loads templates when component mounts
   */
  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  /**
   * Saves the current design as a new template
   */
  const handleSaveDesign = useCallback(async (providedName?: string) => {
    if (!emailEditorRef.current || !user) return;

    try {
      setIsLoading(true);
      const templateName = providedName || window.prompt('Enter template name:');
      if (!templateName) return;

      const design = await new Promise((resolve) => {
        emailEditorRef.current.editor.saveDesign((design: any) => resolve(design));
      });

      const html = await new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml((data: any) => resolve(data.html));
      });

      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('email_templates')
        .insert([
          {
            name: templateName,
            category: 'General',
            design,
            html,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            profile_id: user.profile?.id || '',
          },
        ])
        .select()
        .single();

      if (error) throw error;
      
      if (data) {
        setSelectedTemplate(data);
        setEditingTitle(templateName);
      }
      
      await loadTemplates();
    } catch (error) {
      console.error('Error saving template:', error);
    } finally {
      setIsLoading(false);
    }
  }, [loadTemplates, user]);

  /**
   * Loads a selected template into the editor
   */
  const handleLoadTemplate = useCallback((template: EmailTemplate) => {
    if (!emailEditorRef.current || !template.design) return;
    
    emailEditorRef.current.editor.loadDesign(template.design);
    setSelectedTemplate(template);
  }, []);

  /**
   * Updates an existing template
   */
  const handleUpdateTemplate = useCallback(async () => {
    if (!emailEditorRef.current || !selectedTemplate) return;

    try {
      setIsLoading(true);
      const design = await new Promise((resolve) => {
        emailEditorRef.current.editor.saveDesign((design: any) => resolve(design));
      });

      const html = await new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml((data: any) => resolve(data.html));
      });

      const supabase = supabaseConnect();
      const { error } = await supabase
        .from('email_templates')
        .update({
          design,
          html,
          updated_at: new Date().toISOString(),
        })
        .eq('id', selectedTemplate.id);

      if (error) throw error;
      await loadTemplates();
    } catch (error) {
      console.error('Error updating template:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedTemplate, loadTemplates]);

  const handleUpdateTemplateName = async (templateId: number, newName: string) => {
    try {
      setIsLoading(true);
      const supabase = supabaseConnect();
      const { error } = await supabase
        .from('email_templates')
        .update({ name: newName })
        .eq('id', templateId);

      if (error) throw error;
      await loadTemplates();
      
      // Update selected template name if it's the current template
      if (selectedTemplate?.id === templateId) {
        setSelectedTemplate({ ...selectedTemplate, name: newName });
      }
    } catch (error) {
      console.error('Error updating template name:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartTitleEdit = () => {
    setIsEditingTitle(true);
    setEditingTitle(selectedTemplate?.name || 'Untitled template');
  };

  const handleSaveTitle = async () => {
    const newTitle = editingTitle.trim() || 'Untitled template';
    
    if (selectedTemplate?.id) {
      await handleUpdateTemplateName(selectedTemplate.id, newTitle);
    } else {
      // If no template is selected, create a new one with this name
      await handleSaveDesign(newTitle);
    }
    
    // Update selected template name if it's the current template
    if (selectedTemplate) {
      setSelectedTemplate({
        ...selectedTemplate,
        name: newTitle
      });
    }
    
    setIsEditingTitle(false);
    setEditingTitle(newTitle);
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveTitle();
    } else if (e.key === 'Escape') {
      setIsEditingTitle(false);
      setEditingTitle(selectedTemplate?.name || 'Untitled template');
    }
  };

  const fileMenuItems = [
    {
      label: 'New Template',
      onClick: async () => {
        if (emailEditorRef.current?.editor) {
          await emailEditorRef.current.editor.loadDesign({
            body: {
              rows: []
            }
          });
          setSelectedTemplate(null);
          setEditingTitle('Untitled template');
        }
      }
    },
    {
      label: 'Save',
      onClick: handleUpdateTemplate,
      disabled: !selectedTemplate
    },
    {
      label: 'Save As...',
      onClick: handleSaveDesign
    },
    {
      label: 'Open Template',
      onClick: () => setIsTemplateModalOpen(true)
    },
    {
      label: isConfirmingDelete ? 'Confirm Delete?' : 'Delete',
      onClick: () => {
        if (isConfirmingDelete) {
          if (selectedTemplate?.id) {
            handleDeleteTemplate(selectedTemplate.id);
          }
          setIsConfirmingDelete(false);
        } else {
          setIsConfirmingDelete(true);
          setTimeout(() => setIsConfirmingDelete(false), 3000);
        }
      },
      disabled: !selectedTemplate,
      preventClose: !isConfirmingDelete
    }
  ];

  const helpMenuItems = [
    {
      label: 'Keyboard Shortcuts',
      onClick: () => {
        alert('Keyboard shortcuts coming soon!');
      }
    },
    {
      label: 'Documentation',
      onClick: () => {
        window.open('https://docs.unlayer.com/', '_blank');
      }
    }
  ];

  const handleDeleteTemplate = async (templateId: number) => {
    
    try {
      setIsLoading(true);
      const supabase = supabaseConnect();
      const { error } = await supabase
        .from('email_templates')
        .delete()
        .eq('id', templateId);

      if (error) throw error;
      
      // If the deleted template was selected, clear it
      if (selectedTemplate?.id === templateId) {
        setSelectedTemplate(null);
        setEditingTitle('Untitled template');
        emailEditorRef.current?.editor.loadDesign({
          body: {
            rows: []
          }
        });
      }
      
      await loadTemplates();
    } catch (error) {
      console.error('Error deleting template:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Google Docs-like Header */}
      <div className="flex flex-col bg-[#100f0f] border-b border-[#2a2828]">
        {/* Top Bar */}
        <div className="flex items-center px-4 py-2.5 border-b border-[#2a2828]">
          <div className="flex items-center gap-4">
            {/* Logo/Icon Placeholder */}
            <div className="w-6 h-6 bg-[#2a2828] rounded flex items-center justify-center">
              <svg 
                className="w-4 h-4 text-[#f1f2f6]" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" />
              </svg>
            </div>
            <div className="flex flex-col w-full">
              {isEditingTitle ? (
                <input
                  type="text"
                  value={editingTitle}
                  onChange={(e) => setEditingTitle(e.target.value)}
                  onBlur={handleSaveTitle}
                  onKeyDown={handleTitleKeyDown}
                  className="bg-[#2a2828] text-[#f1f2f6] text-lg font-medium leading-none px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-communityBlue"
                  autoFocus
                />
              ) : (
                <span 
                  className="text-[#f1f2f6] text-lg font-medium leading-none cursor-pointer hover:text-communityBlue"
                  onClick={handleStartTitleEdit}
                >
                  {selectedTemplate ? selectedTemplate.name : 'Untitled template'}
                </span>
              )}
              <span className="text-[#f1f2f6]/50 text-xs mt-0.5">
                Email Template
              </span>
            </div>
          </div>
        </div>
        
        <div className="flex items-center border-b border-[#2a2828]/50 mb-2">
          <div className="flex items-center gap-1 ml-14 my-1"> 
            <DropdownMenu label="File" items={fileMenuItems} />
            {/* <DropdownMenu label="Help" items={helpMenuItems} /> */}
          </div>
        </div>
      </div>

      {/* Editor */}
      <div className="flex-1 min-h-[700px]">
        <EmailEditor
          ref={emailEditorRef}
          onLoad={() => console.log('Editor loaded')}
          minHeight="700px"
          options={{
            appearance: {
              theme: 'dark',
              panels: {
                tools: {
                  dock: 'right'
                }
              }
            },
            customCSS: `
              .blockbuilder-branding {
                display: none !important;
              }
              .tool-panel {
                background-color: #100f0f !important;
                color: #f1f2f6 !important;
              }
              .tool-panel-header {
                background-color: #2a2828 !important;
              }
              .tool-panel-body {
                background-color: #100f0f !important;
              }
              .button-primary {
                background-color: #3b82f6 !important;
              }
            `,
            displayMode: 'email',
            projectId: 1,
            tools: {
              button: {
                enabled: true,
                properties: {
                  colors: {
                    color: '#f1f2f6',
                    backgroundColor: '#3b82f6'
                  }
                }
              }
            }
          }}
        />
      </div>

      {/* Template Selection Modal */}
      <TemplateSelectionModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        templates={templates}
        onSelectTemplate={handleLoadTemplate}
        currentUserId={user?.profile?.id || ''}
      />
    </div>
  );
};

export default EmailTemplateBuilder;
