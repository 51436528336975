import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatbotBubble from './components/Chatbot/ChatbotBubble';
import supabaseConnect from './utils/supabaseConnect';

// Import all components
import Dashboard from './pages/dashboard';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import SearchResults from './pages/search/SearchResults';
import ResourcesPageHome from './pages/resourcesPage/ResourcesPageHome';
import ResourcesPage from './pages/resourcesPage/ResourcesPage';
import TrainingHomePage from './pages/training';
import Partners from './pages/partners/Partners';
import PartnerDetails from './pages/partners/PartnerDetails';
import Announcement from './pages/announcement';
import Profile from './pages/profile/';
import EditProfile from './pages/profile';
import Inactive from './pages/inactive';
import ChangePassword from './pages/auth/ChangePassword';
import RequestPasswordChange from './pages/auth/RequestPasswordChange';
import ConfirmEmailForPasswordChange from './pages/auth/EmailSentConfirmation';
import Vendors from './pages/Recommended_Vendors/Vendors';
import VendorDetails from './pages/Recommended_Vendors/VendorDetails';
import EventsPage from './pages/eventsPage/EventsPage';
import StaffAndAdminPage from './pages/staff_and_admin';
import EditAnnouncement from './pages/admin/announcements/editAnnouncement';
import EditPartner from './pages/admin/partners/EditPartner';
import EditVendor from './pages/admin/vendors/EditVendor';
import CreateVendor from './pages/admin/vendors/Create_Vendor';
import CreatePartner from './pages/admin/partners/Create_Partner';
import EditUser from './pages/admin/users/editUsers';


// check with supabase if the user is authenticated
const supabase = supabaseConnect();

// check if the user is authenticate

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    // Check initial auth state
    checkAuthStatus();

    // Set up listener for auth state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsLoggedIn(!!session);
    });

    // Cleanup listener on component unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  async function checkAuthStatus() {
    const { data: { session } } = await supabase.auth.getSession();
    setIsLoggedIn(!!session);
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/search/:query" element={<SearchResults />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/page" element={<ResourcesPageHome />} />
        <Route path="/page/:id" element={<ResourcesPage />} />
        <Route path="/announcements" element={<Announcement />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route path="/training" element={<TrainingHomePage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/inactive" element={<Inactive />} />
        <Route path="/update-password" element={<ChangePassword />} />
        <Route path="/password-change-request" element={<RequestPasswordChange />} />
        <Route path="/email-sent-confirmation" element={<ConfirmEmailForPasswordChange />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/partners/:id" element={<PartnerDetails />} />
        <Route path="/recommended-vendors" element={<Vendors />} />
        <Route path="/recommended-vendors/:id" element={<VendorDetails />} />
        <Route path="/admin/announcements/edit/:id" element={<EditAnnouncement />} />
        <Route path="/staff-admin/*" element={<StaffAndAdminPage />} />
        <Route path="/admin/edit-partner/:id" element={<EditPartner />} />
        <Route path="/admin/edit-vendor/:id" element={<EditVendor />} />
        <Route path="/admin/create-vendor/create" element={<CreateVendor />} />
        <Route path="/admin/create-partner/create" element={<CreatePartner />} />
        <Route path="/users/edit/:id" element={<EditUser />} />
      </Routes>
      {isLoggedIn && (
        <ChatbotBubble
          isOpen={isChatOpen}
          setIsOpen={(isOpen) => setIsChatOpen(isOpen)}
        />
      )}
    </div>
  );
}

export default App;
