import React, { useEffect, useState } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import { communityLogo1 } from "../../assets/index";
import UpdateModal from "./UpdateModal";
import { SurveyQuestion } from "./MonthlySurveyModal";
import SurveyContent from './SurveyContent';
import { IoClose, IoArrowUp, IoArrowDown } from 'react-icons/io5';

export type Announcement = {
  id: string;
  title: string;
  description: string;
  created_at: string;
  info: string;
  cover_image: string;
  expired: boolean;
}

interface WeeklyAnnouncementModalProps {
  isOpen: boolean;
  onClose: () => void;
  announcements?: Announcement[];
  showSurvey?: boolean;
  surveyQuestions?: SurveyQuestion[];
  onSurveySubmit?: (responses: Record<string, string>) => void;
  surveyError?: string | null;
}

/**
 * WeeklyAnnouncementModal component
 * 
 * This component displays a modal with a list of announcements and an optional survey.
 * It handles navigation between announcements and manages survey visibility.
 * 
 * @param {boolean} isOpen - Determines if the modal is visible.
 * @param {() => void} onClose - Callback function to close the modal.
 * @param {Announcement[]} [announcements=[]] - List of announcements to display.
 * @param {boolean} [showSurvey=false] - Flag to show the survey section.
 * @param {SurveyQuestion[]} [surveyQuestions=[]] - List of survey questions.
 * @param {(responses: Record<string, string>) => void} [onSurveySubmit] - Callback for survey submission.
 * @param {string | null} [surveyError=null] - Error message to display if survey fails.
 * 
 * @returns {JSX.Element | null} The rendered modal component or null if not open.
 */
const WeeklyAnnouncementModal: React.FC<WeeklyAnnouncementModalProps> = ({
  isOpen,
  onClose,
  announcements = [],
  showSurvey = false,
  surveyQuestions = [],
  onSurveySubmit,
  surveyError
}) => {
  const [error, setError] = useState<Error | null>(null);
  const [showSurveySection, setShowSurveySection] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle error state changes
  useEffect(() => {
    if (error) {
      console.error('Error fetching announcements:', error);
    }
  }, [error]);

  // Handle modal open/close and announcements
  useEffect(() => {
    if (isOpen) {
      if (announcements.length === 0) {
        setError(new Error('No announcements available'));
        onClose();
      }
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
      setError(null);
    };
  }, [isOpen, onClose, announcements.length]);

  // Check if it's the last week of the month and manage survey visibility
  useEffect(() => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const daysUntilEndOfMonth = lastDayOfMonth.getDate() - today.getDate();
    const isLastWeek = daysUntilEndOfMonth <= 7;
    
    // Get the current month and year for storage key
    const currentMonthYear = `${today.getFullYear()}-${today.getMonth() + 1}`;
    const lastCompletedSurvey = localStorage.getItem('lastWeeklySurveyCompleted');
    const lastSurveyMonth = localStorage.getItem('lastWeeklySurveyMonth');
    const lastSurveySeen = localStorage.getItem('lastSurveySeen');
    const todayString = today.toISOString().split('T')[0];

    // Function to check if we should show the survey
    const shouldShowSurvey = () => {
      // If survey was completed for current month, don't show
      if (lastCompletedSurvey && lastSurveyMonth === currentMonthYear) {
        return false;
      }

      // If we're in the last week, always show unless completed or seen
      if (isLastWeek) {
        return !(lastCompletedSurvey || lastSurveySeen);
      }

      // Get the previous month for comparison
      const previousMonth = new Date(today);
      previousMonth.setMonth(today.getMonth() - 1);
      const previousMonthKey = `${previousMonth.getFullYear()}-${previousMonth.getMonth() + 1}`;

      // If we have a lastSurveyMonth and it's from a previous month
      if (lastSurveyMonth && lastSurveyMonth !== currentMonthYear) {
        // Show if the survey wasn't completed or seen from the previous month
        return !(
          (lastCompletedSurvey && lastSurveyMonth === previousMonthKey) || 
          lastSurveySeen
        );
      }

      // If we don't have any survey history, show it
      if (!lastSurveyMonth) {
        return true;
      }

      return false;
    };

    // Set survey visibility
    setShowSurveySection(showSurvey && shouldShowSurvey());

    // Reset survey state if we're in a new month's last week
    if (lastSurveyMonth !== currentMonthYear && isLastWeek) {
      localStorage.setItem('lastWeeklySurveyMonth', currentMonthYear);
      localStorage.removeItem('lastWeeklySurveyCompleted');
      localStorage.removeItem('lastSurveySeen');
    }
  }, [showSurvey]);

  const handleSurveyClose = () => {
    // If survey was shown but not completed, mark it as seen
    const today = new Date();
    const currentMonthYear = `${today.getFullYear()}-${today.getMonth() + 1}`;
    localStorage.setItem('lastSurveySeen', today.toISOString().split('T')[0]);
    localStorage.setItem('lastWeeklySurveyMonth', currentMonthYear);
    setShowSurveySection(false);
  };

  const handleSurveySubmit = (responses: Record<string, string>) => {
    if (onSurveySubmit) {
      onSurveySubmit(responses);
      // Mark survey as completed for current month
      const today = new Date();
      const currentMonthYear = `${today.getFullYear()}-${today.getMonth() + 1}`;
      localStorage.setItem('lastWeeklySurveyCompleted', 'true');
      localStorage.setItem('lastWeeklySurveyMonth', currentMonthYear);
      localStorage.removeItem('lastSurveySeen');
      setShowSurveySection(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric"
    });
  };

  const handleNext = () => {
    if (currentIndex < announcements.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (!isOpen) return null;

  return (
    <UpdateModal isOpen={isOpen} onClose={onClose}>
      {/* {showSurveySection && ( // todo: activate is when we are ready for a survey
        <>
          {surveyError && (
            <div 
              role="alert"
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 mx-auto w-full md:w-3/4 xl:w-2/3"
            >
              <strong className="font-bold">Error: </strong>
              <span className="block sm:inline">{surveyError}</span>
            </div>
          )}
          <SurveyContent
            questions={surveyQuestions}
            onSubmit={handleSurveySubmit}
            onCancel={handleSurveyClose}
          />
        </>
      )} */}
      <div className="flex flex-col items-center gap-8 pointer-events-none py-8">
        {announcements.map((announcement, index) => (
          <div 
            key={announcement.id}
            data-testid="announcement-container"
            className={`
              bg-[#1b2838] w-full md:w-3/4 xl:w-2/3 mx-auto rounded-lg shadow-2xl 
              flex flex-col relative ring-1 ring-white/10 overflow-hidden pointer-events-auto
              ${index === 0 ? 'mt-8' : ''}
              ${index === announcements.length - 1 ? 'mb-8' : ''}
            `}
          >
            {/* Navigation Controls - Now stacked vertically */}
            <div className="absolute right-4 top-4 z-10 flex flex-col gap-2">
              <button
                onClick={onClose}
                className="bg-[#1b2838]/80 text-gray-400 hover:text-white p-2 rounded cursor-pointer transition-colors hover:bg-communityBlue"
                aria-label="Close modal"
              >
                <IoClose size={24} />
              </button>
              <button
                onClick={() => {
                  const prevElement = document.getElementById(`announcement-${index - 1}`);
                  prevElement?.scrollIntoView({ behavior: 'smooth' });
                }}
                className={`bg-[#1b2838]/80 text-gray-400 hover:text-white p-2 rounded transition-colors hover:bg-communityBlue ${
                  index === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
                disabled={index === 0}
                aria-label="Previous announcement"
              >
                <IoArrowUp size={24} />
              </button>
              <button
                onClick={() => {
                  const nextElement = document.getElementById(`announcement-${index + 1}`);
                  nextElement?.scrollIntoView({ behavior: 'smooth' });
                }}
                className={`bg-[#1b2838]/80 text-gray-400 hover:text-white p-2 rounded transition-colors hover:bg-communityBlue ${
                  index === announcements.length - 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
                disabled={index === announcements.length - 1}
                aria-label="Next announcement"
              >
                <IoArrowDown size={24} />
              </button>
            </div>

            <div id={`announcement-${index}`} className="w-full flex-shrink-0">
              <img
                src={announcement.cover_image === "{}" ? communityLogo1 : announcement.cover_image}
                alt={announcement.title}
                className="w-full h-[200px] object-cover rounded-t-lg"
              />
              <div className="bg-gradient-to-r from-[#2a475e] to-[#1b2838] p-4">
                <div className="text-gray-400 text-sm">
                  <span className="font-bold text-communityBlue text-[1rem]">Posted</span> on {formatDate(announcement.created_at)}
                </div>
                <h2 className="text-2xl font-bold text-white mb-2">{announcement.title}</h2>
              </div>
            </div>

            <div className="flex-1 p-6">
              <div
                className="text-gray-300 prose prose-invert max-w-none"
                dangerouslySetInnerHTML={{ __html: announcement.info }}
              />
            </div>
          </div>
        ))}
      </div>
    </UpdateModal>
  );
};

export default WeeklyAnnouncementModal; 