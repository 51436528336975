import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import supabaseConnect from '../../../../utils/supabaseConnect';
import { SurveyQuestion } from '../../../../components/UpdateModal/MonthlySurveyModal';
import { FaGripVertical, FaTrash, FaEdit, FaPlus, FaEye } from 'react-icons/fa';
import MainLayoutWrapper from '../../../../components/Layout/MainLayoutWrapper';

const ItemTypes = {
  QUESTION: 'question'
};

interface DraggableQuestionProps {
  question: SurveyQuestion;
  index: number;
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
  onEdit: (question: SurveyQuestion) => void;
  onDelete: (id: string) => void;
  onViewResponses: (question: SurveyQuestion) => void;
}

const DraggableQuestion: React.FC<DraggableQuestionProps> = ({
  question,
  index,
  moveQuestion,
  onEdit,
  onDelete,
  onViewResponses
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.QUESTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveQuestion(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.QUESTION,
    item: () => ({ id: question.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="bg-[#1b2838] text-white p-4 rounded border border-gray-700 flex items-center justify-between hover:border-communityBlue transition-all duration-200"
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div className="flex items-center gap-4">
        <div className="cursor-move">
          <FaGripVertical className="text-gray-400 hover:text-communityBlue" />
        </div>
        <div>
          <p className="font-medium">{question.question}</p>
          <p className="text-sm text-gray-400">
            Type: {question.type} | {question.required ? 'Required' : 'Optional'}
          </p>
        </div>
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => onViewResponses(question)}
          className="text-gray-400 hover:text-communityBlue transition-colors cursor-pointer"
          title="View Responses"
        >
          <FaEye />
        </button>
        <button
          onClick={() => onEdit(question)}
          className="text-gray-400 hover:text-communityBlue transition-colors cursor-pointer"
          title="Edit Question"
        >
          <FaEdit />
        </button>
        <button
          onClick={() => onDelete(question.id)}
          className="text-gray-400 hover:text-red-500 transition-colors cursor-pointer"
          title="Delete Question"
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

interface SurveyResponse {
  id: string;
  question_id: string;
  profile_id: string;
  response: string;
  survey_date: string;
  first_name: string;
  last_name: string;
  email: string;
}

interface ResponsesModalProps {
  question: SurveyQuestion;
  onClose: () => void;
}

const ResponsesModal: React.FC<ResponsesModalProps> = ({ question, onClose }) => {
  const [responses, setResponses] = useState<SurveyResponse[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResponses = async () => {
      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('survey_responses_with_profiles')
        .select('*')
        .eq('question_id', question.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching responses:', error);
      } else {
        setResponses(data || []);
      }
      setLoading(false);
    };

    fetchResponses();
  }, [question.id]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-[#1b2838] rounded-lg p-6 max-w-4xl w-full max-h-[80vh] overflow-y-auto border border-gray-700">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold text-white">Responses for: {question.question}</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-communityBlue text-2xl cursor-pointer"
          >
            ×
          </button>
        </div>

        {loading ? (
          <div className="text-center py-4 text-gray-400">Loading responses...</div>
        ) : responses.length === 0 ? (
          <div className="text-center py-4 text-gray-400">No responses yet</div>
        ) : (
          <div className="space-y-4">
            {responses.map((response) => (
              <div
                key={response.id}
                className="border border-gray-700 rounded p-4 bg-[#2a475e]"
              >
                <div className="flex justify-between mb-2">
                  <div className="font-medium text-white">
                    {response.first_name} {response.last_name}
                  </div>
                  <div className="text-sm text-gray-400">
                    {new Date(response.survey_date).toLocaleDateString()}
                  </div>
                </div>
                <div className="text-gray-300">{response.response}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SurveyManager: React.FC = () => {
  const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
  const [editingQuestion, setEditingQuestion] = useState<SurveyQuestion | null>(null);
  const [viewingResponses, setViewingResponses] = useState<SurveyQuestion | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('survey_questions')
      .select('*')
      .order('order');

    if (error) {
      console.error('Error fetching questions:', error);
    } else {
      setQuestions(data || []);
    }
  };

  const moveQuestion = useCallback(async (dragIndex: number, hoverIndex: number) => {
    const dragQuestion = questions[dragIndex];
    const updatedQuestions = [...questions];
    updatedQuestions.splice(dragIndex, 1);
    updatedQuestions.splice(hoverIndex, 0, dragQuestion);

    // Update order property for all items
    const reorderedQuestions = updatedQuestions.map((item, index) => ({
      ...item,
      order: index
    }));

    setQuestions(reorderedQuestions);

    // Update in database
    const supabase = supabaseConnect();
    const updates = reorderedQuestions.map((item) => ({
      id: item.id,
      order: item.order
    }));

    const { error } = await supabase
      .from('survey_questions')
      .upsert(updates);

    if (error) {
      console.error('Error updating question order:', error);
    }
  }, [questions]);

  const handleDelete = async (id: string) => {
    const supabase = supabaseConnect();
    const { error } = await supabase
      .from('survey_questions')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting question:', error);
    } else {
      setQuestions(questions.filter(q => q.id !== id));
    }
  };

  const handleSave = async (question: SurveyQuestion) => {
    const supabase = supabaseConnect();
    const { error } = await supabase
      .from('survey_questions')
      .upsert({
        ...question,
        order: question.id ? question.order : questions.length
      });

    if (error) {
      console.error('Error saving question:', error);
    } else {
      fetchQuestions();
      setIsModalOpen(false);
      setEditingQuestion(null);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="bg-[#1b2838] p-6 rounded-lg shadow-lg text-white">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Monthly Survey Questions</h2>
          <button
            onClick={() => {
              setEditingQuestion(null);
              setIsModalOpen(true);
            }}
            className="bg-communityBlue text-white px-4 py-2 rounded flex items-center gap-2 hover:bg-[#0b7c8a] transition-colors cursor-pointer"
          >
            <FaPlus /> Add Question
          </button>
        </div>

        <div className="space-y-4">
          {questions.map((question, index) => (
            <DraggableQuestion
              key={question.id}
              question={question}
              index={index}
              moveQuestion={moveQuestion}
              onEdit={(q) => {
                setEditingQuestion(q);
                setIsModalOpen(true);
              }}
              onDelete={handleDelete}
              onViewResponses={(q) => setViewingResponses(q)}
            />
          ))}
        </div>

        {isModalOpen && (
          <QuestionModal
            question={editingQuestion}
            onSave={handleSave}
            onClose={() => {
              setIsModalOpen(false);
              setEditingQuestion(null);
            }}
          />
        )}

        {viewingResponses && (
          <ResponsesModal
            question={viewingResponses}
            onClose={() => setViewingResponses(null)}
          />
        )}
      </div>
    </DndProvider>
  );
};

interface QuestionModalProps {
  question: SurveyQuestion | null;
  onSave: (question: SurveyQuestion) => void;
  onClose: () => void;
}

const QuestionModal: React.FC<QuestionModalProps> = ({
  question,
  onSave,
  onClose
}) => {
  const [formData, setFormData] = useState<SurveyQuestion>(
    question || {
      id: crypto.randomUUID(),
      question: '',
      type: 'text',
      required: false,
      order: 0,
      options: []
    }
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSelectType = (type: 'text' | 'radio' | 'checkbox' | 'rating') => {
    setFormData({ ...formData, type });
    setDropdownOpen(false);
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-[#1b2838] rounded-lg p-6 max-w-2xl w-full border border-gray-700">
        <h3 className="text-xl font-bold text-white mb-4">
          {question ? 'Edit Question' : 'Add Question'}
        </h3>
        
        <div className="space-y-4 pr-5">
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-300 mb-1">Question</label>
            <input
              type="text"
              value={formData.question}
              onChange={(e) => setFormData({ ...formData, question: e.target.value })}
              className="w-full p-2 bg-[#2a475e] text-white rounded border border-gray-600 focus:border-communityBlue focus:ring-1 focus:ring-communityBlue focus:ring-opacity-100 focus:outline-none"
            />
          </div>

          <div className="w-full relative">
            <label className="block text-sm font-medium text-gray-300 mb-1">Type</label>
            <div className="w-32 flex items-center justify-between p-2 bg-[#2a475e] text-white rounded border border-gray-600 cursor-pointer" onClick={() => setDropdownOpen(!dropdownOpen)}>
              <span>{formData.type}</span>
              <span className={`text-[12px] transform transition-transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}>
                ▼
              </span>
            </div>
            {dropdownOpen && (
              <div className="absolute mt-1 w-32 bg-[#2a475e] rounded border border-gray-600 z-10">
                {(['text', 'rating'] as const).map((type) => (
                  <div
                    key={type}
                    className="p-2 hover:bg-communityBlue cursor-pointer"
                    onClick={() => handleSelectType(type)}
                  >
                    {type}
                  </div>
                ))}
              </div>
            )}
          </div>

          {formData.type === 'radio' && (
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Options</label>
              <div className="space-y-2">
                {formData.options?.map((option, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={option}
                      onChange={(e) => {
                        const newOptions = [...(formData.options || [])];
                        newOptions[index] = e.target.value;
                        setFormData({ ...formData, options: newOptions });
                      }}
                      className="flex-1 p-2 bg-[#2a475e] text-white rounded border border-gray-600 focus:border-communityBlue focus:ring-2 focus:ring-communityBlue focus:ring-opacity-50 focus:outline-none"
                    />
                    <button
                      onClick={() => {
                        const newOptions = formData.options?.filter((_, i) => i !== index);
                        setFormData({ ...formData, options: newOptions });
                      }}
                      className="text-gray-400 hover:text-red-500 cursor-pointer"
                    >
                      <FaTrash />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => setFormData({
                    ...formData,
                    options: [...(formData.options || []), '']
                  })}
                  className="text-communityBlue hover:text-[#0b7c8a] text-sm cursor-pointer"
                >
                  + Add Option
                </button>
              </div>
            </div>
          )}

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.required}
              onChange={(e) => setFormData({ ...formData, required: e.target.checked })}
              id="required"
              className="text-communityBlue bg-[#2a475e] border-gray-600 rounded focus:ring-communityBlue focus:ring-2 focus:ring-communityBlue focus:ring-opacity-50 focus:outline-none"
            />
            <label htmlFor="required" className="text-sm text-gray-300">Required</label>
          </div>
        </div>

        <div className="mt-6 flex justify-end gap-4">
          <div
            onClick={onClose}
            className="px-4 py-2 text-white hover:bg-communityBlue hover:text-white cursor-pointer transition-all duration-200" 
          >
            Cancel
          </div>
          <div
            onClick={() => onSave(formData)}
            className="px-4 py-2 hover:bg-communityBlue text-white rounded transition-colors cursor-pointer transition-all duration-200"
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyManager; 