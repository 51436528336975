import { useState, useEffect } from 'react';
import supabaseConnect from "../../../utils/supabaseConnect";
import { User } from '@supabase/supabase-js';

/**
 * Custom hook to fetch and manage the current user's authentication state.
 * 
 * @returns {Object} An object containing the current user
 * @property {User | null} user - The current authenticated user or null if not authenticated
 * 
 * @example
 * const { user } = useUser();
 * 
 * @remarks
 * If this hook is missing, the application would not be able to determine the current user's
 * authentication state, breaking user-specific functionality and potentially exposing
 * protected routes to unauthenticated users.
 */
export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const supabase = supabaseConnect();

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.log(error);
        return;
      }
      if (!data.session?.user) {
        window.location.href = "/login";
        return;
      }
      setUser(data.session.user);
    };

    getUser();
  }, []);

  return { user };
};