import supabaseConnect from "./supabaseConnect";

export const trackEvent = async (category: string, action: string, label?: string, value?: number) => {
  const supabase = supabaseConnect();

  try {
    // Get the current user (if logged in)
    const { data: { user } } = await supabase.auth.getUser();

    // Prepare the event data
    const eventData = {
      category,
      action,
      label,
      value,
      user_id: user?.id 
    };

    // Insert the event into the analytics_events table
    const { data, error } = await supabase
      .from('analytics_events')
      .insert(eventData);

    if (error) {
      console.error('Error tracking event:', error);
    } 
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

// Todo: Add more analytics-related functions here as needed
