import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as images from "../../../../assets";

interface ProfileSectionProps {
  onSignOut: () => void;
  collapsed: boolean;
  isAdmin: boolean;
  profile: any;
}

export const ProfileSection: React.FC<ProfileSectionProps> = ({ onSignOut, collapsed, isAdmin, profile }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleProfileClick = () => {
    navigate("/profile");
  };

  if (collapsed) {
    return (
      <div className="p-4">
        <img
          src={profile?.profile_image_url || "https://via.placeholder.com/40"}
          alt="Profile"
          className="w-10 h-10 rounded-full mx-auto"
        />
      </div>
    );
  }

  return (
    <div className={`transition-all duration-300 ease-in-out ${isDropdownOpen ? 'mb-0' : ''}`}>
      <div 
        className="flex flex-row items-center justify-start gap-[.8rem] text-left text-base cursor-pointer p-4"
        onClick={toggleDropdown}
      >
        <img
          className="rounded-[50%] w-10 h-10 object-cover"
          alt=""
          src={profile?.profile_image_url || "https://via.placeholder.com/40"}
        />
        <div className="overflow-hidden flex-grow">
          <p className="m-0 font-sm word-clip">
            {profile?.first_name} {profile?.last_name}
          </p>
          <p className="m-0 text-[10px] leading-[16px] word-clip">
            {profile?.email}
          </p>
        </div>
        <img 
          className={`w-4 h-4 transition-transform duration-200 ${isDropdownOpen ? 'rotate-180' : ''}`}
          src={images.chevron_right} 
          alt="expand"
        />
      </div>
      
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isDropdownOpen ? 'max-h-24' : 'max-h-0'}`}>
        <button 
          onClick={handleProfileClick}
          className="w-full text-left px-4 py-2 hover:bg-black transition-colors duration-200 bg-[#2d3035] text-white cursor-pointer"
        >
          My Profile
        </button>
        <button 
          onClick={onSignOut}
          className="w-full text-left px-4 py-2 hover:bg-black transition-colors duration-200 bg-[#2d3035] text-white cursor-pointer"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};