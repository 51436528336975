import React, { useState } from "react";
import WeeklyAnnouncementModal from "../../components/UpdateModal/WeeklyAnnouncementModal";
import { BsNewspaper } from "react-icons/bs";

const ModalTest: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="min-h-screen bg-[#1b2838] p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8">Modal Test Page</h1>
        
        <div className="bg-[#2a475e] rounded-lg p-6 shadow-lg">
          <h2 className="text-xl text-white mb-4">Available Modals</h2>
          
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors"
          >
            <BsNewspaper />
            <span>Open Weekly Announcements</span>
          </button>
        </div>
      </div>

      <WeeklyAnnouncementModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default ModalTest; 