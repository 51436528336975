// LoadingAnimation.tsx
import React from "react";

const LoadingAnimation: React.FC = () => {
  return (
    <div className="flex items-center space-x-1">
      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce animation-delay-200"></div>
      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce animation-delay-400"></div>
    </div>
  );
};

export default LoadingAnimation;