import { BaseNodeConfig, ScheduleTriggerConfig, DatabaseTriggerConfig, EmailActionConfig } from '../NodeConfig/types';

export interface ValidationResult {
  isValid: boolean;
  errors: Record<string, string[]>;
}

export const validateNodeConfig = (config: BaseNodeConfig): ValidationResult => {
  const errors: Record<string, string[]> = {};

  switch (config.type) {
    case 'schedule':
      return validateScheduleTrigger(config as ScheduleTriggerConfig);
    case 'database':
      return validateDatabaseTrigger(config as DatabaseTriggerConfig);
    case 'email':
      return validateEmailAction(config as EmailActionConfig);
    default:
      errors.type = ['Invalid node type'];
      return { isValid: false, errors };
  }
};

export const validateScheduleTrigger = (config: ScheduleTriggerConfig): ValidationResult => {
  const errors: Record<string, string[]> = {};

  if (!config.schedule.frequency) {
    errors.frequency = ['Frequency is required'];
  }

  if (!config.schedule.time) {
    errors.time = ['Time is required'];
  }

  if (!config.schedule.startDate) {
    errors.startDate = ['Start date is required'];
  }

  if (config.schedule.frequency === 'weekly' && (!config.schedule.daysOfWeek || config.schedule.daysOfWeek.length === 0)) {
    errors.daysOfWeek = ['At least one day must be selected'];
  }

  if (config.schedule.frequency === 'monthly' && !config.schedule.dayOfMonth) {
    errors.dayOfMonth = ['Day of month is required'];
  }

  if (config.schedule.endDate && new Date(config.schedule.endDate) <= new Date(config.schedule.startDate)) {
    errors.endDate = ['End date must be after start date'];
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validateDatabaseTrigger = (config: DatabaseTriggerConfig): ValidationResult => {
  const errors: Record<string, string[]> = {};

  if (!config.table) {
    errors.table = ['Table name is required'];
  }

  if (!config.event) {
    errors.event = ['Event type is required'];
  }

  if (config.conditions.length > 0) {
    config.conditions.forEach((condition, index) => {
      if (!condition.field) {
        errors[`conditions.${index}.field`] = ['Field is required'];
      }
      if (!condition.operator) {
        errors[`conditions.${index}.operator`] = ['Operator is required'];
      }
      if (condition.value === undefined || condition.value === '') {
        errors[`conditions.${index}.value`] = ['Value is required'];
      }
    });
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validateEmailAction = (config: EmailActionConfig): ValidationResult => {
  const errors: Record<string, string[]> = {};

  if (!config.templateId) {
    errors.templateId = ['Email template is required'];
  }

  if (!config.subject) {
    errors.subject = ['Subject is required'];
  }

  if (config.recipients.type === 'static' && (!config.recipients.value || config.recipients.value.length === 0)) {
    errors.recipients = ['At least one recipient is required'];
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
}; 