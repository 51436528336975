import React, { useEffect, useState } from 'react';
import supabaseConnect from '../../../../utils/supabaseConnect';
import { ZonedDateTime, ZoneId, LocalDate, LocalTime } from '@js-joda/core';
import '@js-joda/timezone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { FaPhone, FaGlobe, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

interface DailyUpdate {
  id: number;
  title: string;
  content: string;
  created_at: string;
  sent_date: string | null;
}

interface UserProfile {
  email: string;
}

const DailyUpdatesApproval: React.FC = () => {
  const [dailyUpdates, setDailyUpdates] = useState<DailyUpdate[]>([]);
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [confirmingDeleteId, setConfirmingDeleteId] = useState<number | null>(null);

  useEffect(() => {
    fetchDailyUpdates();
    fetchUserEmails();
    const interval = setInterval(fetchDailyUpdates, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, []);

  const fetchDailyUpdates = async () => {
    const supabase = supabaseConnect();
    const mstZone = ZoneId.of('America/Denver');
    const now = ZonedDateTime.now(mstZone);
    const currentDate = now.toLocalDate();
    const cutoffTime = LocalTime.of(13, 45); // 1:45 PM
    const currentCutoff = ZonedDateTime.of(LocalDate.from(currentDate), cutoffTime, mstZone);

    const { data, error } = await supabase
      .from('daily_updates')
      .select('*')
      .is('sent_date', null) // Only get unsent updates
      .lt('created_at', currentCutoff.toInstant().toString()) // Only get updates created before cutoff
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching daily updates:', error);
    } else {
      setDailyUpdates(data || []);
    }
    setLoading(false);
  };

  const fetchUserEmails = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('profiles')
      .select('email');

    if (error) {
      console.error('Error fetching user emails:', error);
    } else {
      setUserEmails(data?.map((profile: UserProfile) => profile.email) || []);
    }
  };

  const handleEdit = (update: DailyUpdate) => {
    setEditingId(update.id);
    setEditedTitle(update.title);
    setEditedContent(update.content);
  };

  const handleSave = async (id: number) => {
    const supabase = supabaseConnect();
    const { error } = await supabase
      .from('daily_updates')
      .update({ title: editedTitle, content: editedContent })
      .eq('id', id);

    if (error) {
      console.error('Error updating daily update:', error);
    } else {
      setEditingId(null);
      fetchDailyUpdates();
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditedTitle('');
    setEditedContent('');
  };

  const handleApprove = async () => {
    if (emailSent) return;
    setIsSending(true);
    try {
      const emailHTML = generateEmailHTML();
      await axios.post('https://realcommunityportal-production-f1b9.up.railway.app/api/email/send-daily-update', {
        emailHTML,
        emails: userEmails
      });

      const supabase = supabaseConnect();
      const today = LocalDate.now(ZoneId.of('America/Denver')).toString(); // Format: YYYY-MM-DD
      const updatePromises = dailyUpdates.map(update => 
        supabase
          .from('daily_updates')
          .update({ sent_date: today })
          .eq('id', update.id)
      );
      await Promise.all(updatePromises);

      setShowSuccessMessage(true);
      setEmailSent(true);
      setTimeout(() => setShowSuccessMessage(false), 5000);
      
      fetchDailyUpdates();
    } catch (error) {
      console.error('Error:', error);
    }
    setIsSending(false);
  };

  const handleDelete = async (id: number) => {
    if (confirmingDeleteId === id) {
      const supabase = supabaseConnect();
      const { error } = await supabase
        .from('daily_updates')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting daily update:', error);
      } else {
        fetchDailyUpdates();
      }
      setConfirmingDeleteId(null);
    } else {
      setConfirmingDeleteId(id);
    }
  };

  const generateEmailHTML = () => {
    return `
      <html>
        <head>
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" rel="stylesheet">
        </head>
        <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
          <p>Hey Team, Below are the daily updates you will be receiving from me about happenings within Community that relate to you:</p>
          <br />
          <h2 style="font-size: 1.5em; margin-bottom: 10px;">DAILY UPDATES:</h2>
          <ul style="list-style-type: none; padding: 0; margin: 0;">
            ${dailyUpdates.map(update => `
              <li style="margin-bottom: -10px;">
                <div style="display: inline;">
                  <span style="font-weight: bold; text-decoration: underline;">${update.title}:</span>
                  <span style="display: inline-block; margin-left: 4px;">${update.content}</span>
                </div>
              </li>
            `).join('')}
          </ul>
          <p>
            As a reminder you can access Community information at the Community Portal at: 
            <a href="https://app.realcommunity.com" className="text-[#099aad] font-bold">app.realcommunity.com</a>
          </p>
          <p>
            Again I want to emphasize the importance of reading these emails as I send them out. I'm working hard with our team to improve communication and I want you to stay informed about activities and updates that directly relate to your work.
          </p>
          <p>
            As always, let me know if there is anything I can do for you.
          </p>
          
          <div style="display: flex; align-items: center; margin-top: 20px;">
            <img src="https://ci3.googleusercontent.com/meips/ADKq_NZXWS8BQZGk0wDnEG3-9hMG27RSKn-Ianb4teKjiuw5G_01rrZfLIsYiUFF0WWe-iQzcW2VPOKAEgShyxzOtd_HJhMLM6mjFZV65y8RVP1VxJTOIuKH1BaE5VEt0oVZ1gJXS8Jp37uVNYpM-uQhfxnplhlPx0roj0XeIz2pqCE6Bs9iUsIZjIZpLTVimAg19GtAkyQRmB2z3B8lwUYZn7tAoFrbzsOZI-IeCycqZszICjBhiDShsEGHw681j0gXGngPZR7kb4Ut-pBegpcU2I_mI4pmhkA2k0tmzHJ4A8InViIjlW9sDv5xpPoxnYC1=s0-d-e1-ft#https://cdn.gifo.wisestamp.com/im/sh/ODgwZmQ1ZWYtNWU3MS00NTA0LWI5MjItNDBjODIwYjk4NjdiLzNfSm9yZGFuVGVycmVsbF8ySjZBMzQzNzQuY3JvcF80NDgweDQ0NzZfMCw0NDMucG5nLnJlc2l6ZV8yMDB4LmpwZWcjbG9nbw==/circle.png" alt="Jordan Terrell" style="width: 64px; height: 64px; border-radius: 50%; margin-right: 16px;" />
            <div>
              <h3 style="margin: 0; font-size: 1.2em;">Jordan Terrell</h3>
              <p style="font-size: 12px; font-weight: bold;">CEO, Community, Brokered by Real</p>
              <p style="font-size: 10px;">
                <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_7.png" alt="Phone" style="width: 12px; height: 12px; margin-right: 5px; vertical-align: middle;" />
                720-690-5745 
                <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_8.png" alt="Globe" style="width: 12px; height: 12px; margin-right: 5px; margin-left: 10px; vertical-align: middle;" />
                realcommunity.com
              </p>
              <p style="font-size: 10px;">
                <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_9.png" alt="Email" style="width: 12px; height: 12px; margin-right: 5px; vertical-align: middle;" />
                jordan@realcommunity.com
              </p>
              <p style="font-size: 10px;">
                <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_10.png" alt="Location" style="width: 12px; height: 12px; margin-right: 5px; vertical-align: middle;" />
                12245 N Pecos St Unit 400 Westminster, CO 80234
              </p>
            </div>
          </div>
          <div className="mt-4">
          <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_4.png" alt="Community Logo" style="height: 3rem;" />
        </div>
        <div className="mt-4 flex space-x-2">
          <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_5.png" alt="Award 1" style="width: 25rem; height: 7rem;" />
        </div>
          <p style="font-size: 10px; margin-top: 20px; color: #666;">
            IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.
          </p>
        </body>
      </html>
    `;
  };

  if (loading) {
    return <div className="text-center text-lg text-white">Loading...</div>;
  }

  const mstZone = ZoneId.of('America/Denver');
  const now = ZonedDateTime.now(mstZone);
  const currentDate = now.toLocalDate();
  const midnight = ZonedDateTime.of(LocalDate.from(currentDate), LocalTime.of(23, 59, 59), mstZone);

  let periodDescription = now.isBefore(midnight) ? "Today's" : "Tomorrow's";

  return (
    <div className="max-w-4xl mx-auto bg-white text-black shadow-lg rounded-lg overflow-hidden">
      <div className="p-6 bg-gray-100 border-b border-gray-200">
        <h1 className="text-2xl font-bold">Daily Updates Email Approval</h1>
      </div>
      <div className="p-6">
        
        {dailyUpdates.length === 0 ? (
          <p className="text-lg">There are no updates to be approved for the email.</p>
        ) : (
          <>
            <h2 className="text-xl font-semibold mb-4">DAILY UPDATES:</h2>
            <ul className="space-y-4">
              {dailyUpdates.map((update) => (
                <li key={update.id} className="border-b border-gray-200 pb-4">
                  {editingId === update.id ? (
                    <div>
                      <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        className="w-full bg-gray-100 text-black p-2 mb-2 rounded border border-gray-300"
                      />
                      <ReactQuill 
                        theme="snow"
                        value={editedContent}
                        onChange={setEditedContent}
                      />
                      <div className="mt-2 flex space-x-2">
                        <button 
                          onClick={() => handleSave(update.id)}
                          className="bg-[#099aad] hover:bg-[#0b7c8a] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out cursor-pointer"
                        >
                          Save
                        </button>
                        <button 
                          onClick={handleCancel}
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-300 ease-in-out cursor-pointer"
                        >
                          Cancel
                        </button>
                        <button 
                          onClick={() => handleDelete(update.id)}
                          className={`${
                            confirmingDeleteId === update.id
                              ? 'bg-red-600 hover:bg-red-700 text-white'
                              : 'bg-gray-300 hover:bg-gray-400 text-gray-800'
                          } font-bold py-2 px-4 rounded transition duration-300 ease-in-out cursor-pointer`}
                        >
                          {confirmingDeleteId === update.id ? 'Confirm Delete' : 'Delete'}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex items-start">
                        <div className="flex items-center">
                          <span className="font-bold mr-2 underline">{update.title}: </span>
                          <div dangerouslySetInnerHTML={{ __html: update.content }} className="flex-grow" />
                        </div>
                      </div>
                      <button 
                        onClick={() => handleEdit(update)}
                        className="mt-2 bg-[#099aad] hover:bg-[#0b7c8a] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out cursor-pointer"
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="p-6 text-sm text-gray-600">
      <p className="mt-2">
          As a reminder you can access Community information at the Community Portal at: {' '}
          <a href="https://app.realcommunity.com" className="text-[#099aad] font-bold">app.realcommunity.com</a>
        </p>
        <p>
          Again I want to emphasize the importance of reading these emails as I send them out. I'm working hard with our team to improve communication and I want you to stay informed about activities and updates that directly relate to your work.
        </p>
        <p className="mt-2">
          As always, let me know if there is anything I can do for you.
        </p>
      </div>
      <div className="p-6 bg-gray-100 text-gray-600">
        <div className="flex items-center">
          <img src="https://ci3.googleusercontent.com/meips/ADKq_NZXWS8BQZGk0wDnEG3-9hMG27RSKn-Ianb4teKjiuw5G_01rrZfLIsYiUFF0WWe-iQzcW2VPOKAEgShyxzOtd_HJhMLM6mjFZV65y8RVP1VxJTOIuKH1BaE5VEt0oVZ1gJXS8Jp37uVNYpM-uQhfxnplhlPx0roj0XeIz2pqCE6Bs9iUsIZjIZpLTVimAg19GtAkyQRmB2z3B8lwUYZn7tAoFrbzsOZI-IeCycqZszICjBhiDShsEGHw681j0gXGngPZR7kb4Ut-pBegpcU2I_mI4pmhkA2k0tmzHJ4A8InViIjlW9sDv5xpPoxnYC1=s0-d-e1-ft#https://cdn.gifo.wisestamp.com/im/sh/ODgwZmQ1ZWYtNWU3MS00NTA0LWI5MjItNDBjODIwYjk4NjdiLzNfSm9yZGFuVGVycmVsbF8ySjZBMzQzNzQuY3JvcF80NDgweDQ0NzZfMCw0NDMucG5nLnJlc2l6ZV8yMDB4LmpwZWcjbG9nbw==/circle.png" alt="Jordan Terrell" className="w-16 h-16 rounded-full mr-4" />
          <div>
            <h3 className="font-bold">Jordan Terrell</h3>
            <p className="text-[12px] mt-[-.8rem] font-bold">CEO, Community, Brokered by Real</p>
            <p className="text-[10px] mt-[-.5rem]">
              <FaPhone className="inline mr-1" /> 720-690-5745 <FaGlobe className="inline mr-1 ml-2" /> realcommunity.com
            </p>
            <p className="text-[10px] mt-[-.5rem]">
              <FaEnvelope className="inline mr-1" /> jordan@realcommunity.com
            </p>
            <p className="text-[10px] mt-[-.5rem]">
              <FaMapMarkerAlt className="inline mr-1" /> 12245 N Pecos St Unit 400 Westminster, CO 80234
            </p>
          </div>
        </div>
        <div className="mt-4">
          <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_4.png" alt="Community Logo" className="h-[3rem]" />
        </div>
        <div className="mt-4 flex space-x-2">
          <img src="https://dcpnqtsmziafemwxavty.supabase.co/storage/v1/object/public/media/unnamed_5.png" alt="Award 1" className="h-12 w-[25rem] h-[7rem]" />
        </div>
      </div>
      <div className="p-4 -mt-[2rem] bg-gray-100 text-[10px] text-gray-600 pl-[2rem]">
        <p>IMPORTANT: The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof.</p>
      </div>
      <div className="p-6 bg-gray-100 border-t border-gray-200">
        {showSuccessMessage && (
          <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
            Email sent successfully!
          </div>
        )}
        <button 
          onClick={handleApprove}
          disabled={isSending || emailSent}
          className="bg-[#099aad] hover:bg-[#0b7c8a] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {emailSent ? 'Email Sent' : (isSending ? 'Sending...' : 'Approve and Send')}
        </button>
      </div>
    </div>
  );
};

export default DailyUpdatesApproval;
