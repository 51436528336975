import React, { useEffect } from 'react';
import MainLayoutWrapper from '../../../../components/Layout/MainLayoutWrapper';

const Dashboard: React.FC = () => {
  
  return (
    <MainLayoutWrapper>
      <h1 className="text-2xl font-semibold text-white">Dashboard (More Coming Soon!)</h1>
    </MainLayoutWrapper>
  );
};

export default Dashboard;