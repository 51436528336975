import React from 'react';
import { FaRobot, FaHeadset, FaBook } from 'react-icons/fa';
import { IoIosArrowBack } from "react-icons/io";
import Tooltip from '@mui/material/Tooltip';

interface ChatbotInterfaceProps {
  onSelectOption: (option: 'ai' | 'support' | 'docs') => void;
  showBackButton?: boolean;
  onBack?: () => void;
}

const ChatbotInterface: React.FC<ChatbotInterfaceProps> = ({ onSelectOption, showBackButton = false, onBack }) => {
  return (
    <div className="flex flex-col h-full bg-gray-900 p-6 md:p-6 sm:p-4">
      <div className="flex items-center mb-6">
        {showBackButton && (
          <div
            onClick={onBack}
            className="mr-3 text-white hover:text-blue-400 transition-colors duration-200 cursor-pointer"
          >
            <IoIosArrowBack className="text-2xl" />
          </div>
        )}
        <FaRobot className="text-gray-100 text-3xl mr-3" />
        <h2 className="text-2xl font-bold text-gray-100">How can we help you today? 👋</h2>
      </div>
      <div className="flex-grow flex flex-col space-y-4">
        <div className="bg-gray-800 rounded-lg p-4 shadow-md">
          <div className="flex items-center mb-2">
            <FaRobot className="text-blue-400 text-xl mr-2" />
            <p className="text-gray-300 font-medium">ChatBot</p>
          </div>
          <p className="text-gray-400 mb-4">Let me know if you have any questions!</p>
          <button
            onClick={() => onSelectOption('ai')}
            className="w-full bg-gray-700 hover:bg-communityBlue text-gray-100 font-bold py-3 px-4 rounded transition duration-300 cursor-pointer"
          >
            Chat with AI
          </button>
        </div>
        <button
          onClick={() => onSelectOption('support')}
          className="flex items-center justify-between w-full bg-gray-800 hover:bg-communityBlue text-gray-300 font-semibold py-3 px-4 rounded transition duration-300 cursor-pointer"
        >
          <div className="flex items-center">
            <FaHeadset className="mr-2" />
            <span>Message Support</span>
          </div>
          <span>&gt;</span>
        </button>
        <Tooltip title="Coming Soon" placement="top" arrow>
          <div className="w-full">
            <button
              className="flex items-center justify-between w-full bg-gray-800 text-gray-500 font-semibold py-3 px-4 rounded transition duration-300 cursor-not-allowed opacity-50"
              disabled
            >
              <div className="flex items-center">
                <FaBook className="mr-2" />
                <span>Visit Help Docs</span>
              </div>
              <span>&gt;</span>
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ChatbotInterface;