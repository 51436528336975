import React, { useState } from "react";
import ReactDOM from "react-dom";
import supabaseConnect from "../../utils/supabaseConnect";
import { calendar } from "../../assets";
import { pin } from "../../assets";
import { IoMdClose } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { communityLogo1 } from "../../assets";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  activeEvent: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info?: string;
    cover_image?: string;
    start_date?: string;
    location?: string;
    all_day?: boolean;
  }[];
  setBeingEdited: (value: boolean) => void;
  setShowEventModal: (value: boolean) => void;
  setActiveEvent: (value: any) => void;
}

/**
 * EventModal component
 * 
 * A modal component that displays event details with support for admin editing.
 * Renders event content including title, date, location, and formatted HTML content.
 * Uses a portal for rendering the modal outside the normal DOM hierarchy.
 * 
 * @param {boolean} isOpen - Controls the visibility of the modal
 * @param {() => void} onClose - Callback function to close the modal
 * @param {Event[]} activeEvent - Array containing the event data to display
 * @param {(value: boolean) => void} setBeingEdited - Function to set edit mode
 * @param {(value: boolean) => void} setShowEventModal - Function to control modal visibility
 * @param {(value: any) => void} setActiveEvent - Function to set the active event
 */
const EventModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  activeEvent,
  setBeingEdited,
  setShowEventModal,
  setActiveEvent,
}) => {
  const [userAdmin, setUserAdmin] = useState(false);
  if (!isOpen) return null;
  const date = new Date(activeEvent[0].created_at);
  const supabase = supabaseConnect();

  function convertDateFormat(dateString: string, isAllDay?: boolean) {
    // Return early if it's an all-day event
    if (isAllDay) {
      const date = new Date(dateString);
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
      const formattedDay = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${formattedMonth}/${formattedDay}/${year} | All Day`;
    }

    // Ensure the date string is in ISO 8601 format
    const isoDateString = new Date(dateString).toISOString();

    // Parse the ISO date string
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error('Invalid date string:', dateString);
      return 'Invalid date';
    }

    // Format the date
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDay = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Convert to 12-hour format
    let period = 'AM';
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }

    return `${formattedMonth}/${formattedDay}/${year} | ${hours}:${minutes} ${period}`;
  }


  const dateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // months are zero indexed
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) setUserAdmin(true);
  };

  getUserAdmin();

  // convert html string to jsx element
  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };

  const handleEditClick = () => {
    setBeingEdited(true);
    setShowEventModal(true);
    setActiveEvent(activeEvent);
    onClose();
  };

  console.log(activeEvent)

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
      style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}
      onClick={onClose}
    >
      <div 
        className="bg-[#1b2838] w-full md:w-3/4 xl:w-2/3 mx-auto rounded-lg shadow-2xl max-h-[90vh] flex flex-col relative ring-1 ring-white/10 overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Navigation buttons */}
        <div className="absolute top-4 right-4 z-10 flex flex-col gap-2">
          <button
            onClick={onClose}
            className="bg-[#1b2838] text-gray-400 hover:text-white p-2 rounded cursor-pointer transition-colors hover:bg-communityBlue"
            aria-label="Close event"
          >
            <IoMdClose size={24} />
          </button>
          {userAdmin && (
            <button
              onClick={handleEditClick}
              className="bg-[#1b2838] text-gray-400 hover:text-white p-2 rounded cursor-pointer transition-colors hover:bg-communityBlue"
              aria-label="Edit event"
            >
              <FiEdit size={24} />
            </button>
          )}
        </div>

        {activeEvent.map((event) => (
          <div key={event.id} className="flex flex-col h-full overflow-auto">
            <div className="w-full flex-shrink-0">
              <img
                src={event.cover_image || communityLogo1}
                alt={event.title}
                className="w-full h-[200px] object-cover rounded-t-lg"
              />
              <div className="bg-gradient-to-r from-[#2a475e] to-[#1b2838] p-4">
                <div className="text-gray-400 text-sm">
                  <span className="font-bold text-communityBlue text-[1rem]">Event Date:</span>{' '}
                  {event.start_date ? convertDateFormat(event.start_date, event.all_day) : 'Date not available'}
                </div>
                <h2 className="text-2xl font-bold text-white mb-2">
                  {event.title}
                </h2>
                <div className="text-gray-300 mb-2">
                  <span className="font-bold text-communityBlue">Location:</span>{' '}
                  {event.location || 'Location not specified'}
                </div>
              </div>
            </div>

            <div className="flex-1 p-6 overflow-y-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}>
              <div className="text-gray-300 mb-4">
                {event.description}
              </div>
              <div className="text-gray-300 prose prose-invert max-w-none [&_a]:text-communityBlue [&_a]:no-underline hover:[&_a]:underline">
                <h3 className="text-xl font-bold mb-2">About this event:</h3>
                <div
                  dangerouslySetInnerHTML={createMarkup(event.info ?? "")}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default EventModal;
