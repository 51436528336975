import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ConfigurationPanel } from './shared/ConfigurationPanel';
import { FormField } from './shared/FormField';
import { EmailActionConfig as ConfigType } from './types';
import { validateEmailAction } from '../utils/validation';
import supabaseConnect from '../../../../../../../utils/supabaseConnect';

interface EmailTemplate {
  id: string;
  name: string;
}

interface EmailActionConfigProps {
  config: ConfigType;
  onSave: (config: ConfigType) => void;
  onClose: () => void;
}

export const EmailActionConfig: React.FC<EmailActionConfigProps> = ({
  config: initialConfig,
  onSave,
  onClose,
}) => {
  const [config, setConfig] = useState<ConfigType>(initialConfig);
  const [validation, setValidation] = useState(validateEmailAction(initialConfig));
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [newRecipient, setNewRecipient] = useState('');

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  useEffect(() => {
    setValidation(validateEmailAction(config));
  }, [config]);

  const fetchEmailTemplates = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('email_templates')
      .select('id, name');
    
    if (!error && data) {
      setTemplates(data);
    }
  };

  const handleSave = () => {
    if (validation.isValid) {
      onSave(config);
    }
  };

  const addRecipient = () => {
    if (newRecipient && !config.recipients.value.includes(newRecipient)) {
      setConfig({
        ...config,
        recipients: {
          ...config.recipients,
          value: [...config.recipients.value, newRecipient],
        },
      });
      setNewRecipient('');
    }
  };

  const removeRecipient = (email: string) => {
    setConfig({
      ...config,
      recipients: {
        ...config.recipients,
        value: config.recipients.value.filter((r) => r !== email),
      },
    });
  };

  const baseInputStyles = "block w-full p-2 bg-[#2a2828] text-white rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500";
  const constrainedInputStyles = `${baseInputStyles} w-full sm:max-w-[300px]`;

  return (
    <ConfigurationPanel
      title="Email Action Configuration"
      onClose={onClose}
      onSave={handleSave}
      isValid={validation.isValid}
    >
      <div className="space-y-4 w-full max-w-full overflow-hidden">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <FormField
            label="Email Template"
            required
            error={validation.errors.templateId?.[0]}
          >
            <select
              value={config.templateId}
              onChange={(e) => setConfig({ ...config, templateId: e.target.value })}
              className={constrainedInputStyles}
            >
              <option value="">Select a template</option>
              {templates.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
          </FormField>

          <FormField
            label="Subject"
            required
            error={validation.errors.subject?.[0]}
          >
            <input
              type="text"
              value={config.subject}
              onChange={(e) => setConfig({ ...config, subject: e.target.value })}
              className={constrainedInputStyles}
              placeholder="Enter email subject"
            />
          </FormField>
        </div>

        <FormField
          label="Recipient Type"
          required
        >
          <select
            value={config.recipients.type}
            onChange={(e) =>
              setConfig({
                ...config,
                recipients: { ...config.recipients, type: e.target.value as 'static' | 'dynamic' },
              })
            }
            className={`${baseInputStyles} sm:max-w-[300px]`}
          >
            <option value="static">Static Recipients</option>
            <option value="dynamic">Dynamic Recipients</option>
          </select>
        </FormField>

        {config.recipients.type === 'static' && (
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-[1fr,auto] gap-2 sm:max-w-[500px]">
              <input
                type="email"
                value={newRecipient}
                onChange={(e) => setNewRecipient(e.target.value)}
                className={baseInputStyles}
                placeholder="Enter email address"
              />
              <button
                type="button"
                onClick={addRecipient}
                className="flex items-center justify-center px-4 py-2 bg-communityBlue text-white rounded-md hover:bg-blue-600 transition-colors w-full sm:w-auto"
              >
                <FaPlus className="w-4 h-4" />
              </button>
            </div>

            {validation.errors.recipients?.[0] && (
              <p className="text-sm text-red-400">{validation.errors.recipients[0]}</p>
            )}

            <div className="space-y-2 max-h-[200px] overflow-y-auto sm:max-w-[500px]">
              {config.recipients.value.map((email) => (
                <div
                  key={email}
                  className="flex justify-between items-center p-2 bg-[#2a2828] rounded-md gap-2"
                >
                  <span className="text-white truncate flex-1">{email}</span>
                  <button
                    type="button"
                    onClick={() => removeRecipient(email)}
                    className="text-red-400 hover:text-red-300 transition-colors flex-shrink-0 p-1"
                  >
                    <FaTrash className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {config.recipients.type === 'dynamic' && (
          <FormField
            label="Dynamic Field"
            required
            error={validation.errors.dynamicField?.[0]}
          >
            <input
              type="text"
              value={config.dynamicFields?.emailField || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  dynamicFields: { ...config.dynamicFields, emailField: e.target.value },
                })
              }
              className={constrainedInputStyles}
              placeholder="Enter field name for email addresses"
            />
          </FormField>
        )}
      </div>
    </ConfigurationPanel>
  );
}; 