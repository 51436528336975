import React, { useState } from 'react';
import { HiX, HiPlus } from 'react-icons/hi';
import axios from 'axios';

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Modal component for inviting multiple users by email
 * @param isOpen - Controls modal visibility
 * @param onClose - Function to handle modal closing
 * @returns Modal component for adding users
 * @throws Will throw an error if the email sending fails
 */
const AddUserModal: React.FC<AddUserModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleAddEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailList([...emailList, email]);
      setEmail('');
      setError('');
    } else {
      setError('Please enter a valid email address');
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setEmailList(emailList.filter(e => e !== emailToRemove));
  };

  const handleInviteUsers = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailList.length === 0) {
      setError('Please add at least one email address');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const invitePromises = emailList.map(emailAddress => 
        axios.post('/api/users/invite', {
          email: emailAddress,
          inviteLink: 'https://app.realcommunity.com/signup'
        })
      );

      await Promise.all(invitePromises);
      alert('Invitations sent successfully!');
      setEmailList([]);
      onClose();
    } catch (err) {
      setError('Failed to send invitations. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1b2838] w-full max-w-md rounded-lg shadow-2xl ring-1 ring-white/10">
        <div className="flex justify-between items-center p-6 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-white">Invite New Users</h2>
          <div
            onClick={onClose}
            className="text-gray-400 hover:text-white p-2 rounded-full cursor-pointer transition-colors"
            aria-label="Close modal"
          >
            <HiX className="w-5 h-5 hover:text-communityBlue" />
          </div>
        </div>

        {error && (
          <div className="bg-red-900/50 border border-red-500 text-red-200 px-4 py-3 mx-6 mt-4 rounded" role="alert">
            {error}
          </div>
        )}

        <form onSubmit={handleAddEmail} className="p-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                Email Address
              </label>
              <div className="flex gap-2">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="flex-1 bg-[#2a475e] text-white border border-gray-700 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-communityBlue focus:border-transparent"
                  placeholder="Enter email address"
                  aria-label="Email address"
                />
                <button
                  type="submit"
                  className="bg-gray-600 hover:bg-communityBlue text-white px-4 py-2 rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-communityBlue focus:ring-offset-2 focus:ring-offset-[#1b2838]"
                >
                  <HiPlus className="w-5 h-5" />
                </button>
              </div>
            </div>

            {emailList.length > 0 && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-300 mb-2">Email List</h3>
                <div className="space-y-2">
                  {emailList.map((email, index) => (
                    <div key={index} className="flex items-center justify-between bg-[#2a475e] px-3 py-2 rounded-md">
                      <span className="text-white text-sm">{email}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveEmail(email)}
                        className="text-gray-400 hover:text-red-500 cursor-pointer bg-gray-600 hover:bg-communityBlue p-1 rounded"
                        aria-label={`Remove ${email}`}
                      >
                        <HiX className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </form>

        <div className="px-6 py-4 bg-[#171d25] rounded-b-lg border-t border-gray-700">
          <button
            type="button"
            onClick={handleInviteUsers}
            disabled={isLoading || emailList.length === 0}
            className="w-full bg-gray-600 hover:bg-communityBlue text-white py-2 px-4 rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-communityBlue focus:ring-offset-2 focus:ring-offset-[#1b2838] disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label={isLoading ? 'Sending invitations...' : 'Send invitations'}
          >
            {isLoading ? 'Sending...' : `Send Invitation${emailList.length !== 1 ? 's' : ''}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal; 