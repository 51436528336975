import React, { useState, useEffect } from "react";
import { calendar } from "../../../assets";
import { pin } from "../../../assets";
import supabaseConnect from "../../../utils/supabaseConnect";

interface CalendarProps {
  eventData: {
    id: string;
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    location: string;
    info: string;
    cover_image: string;
  };
  activeEvent: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    cover_image: string;
  }[];
  setActiveEvent: (event: any) => void;
  setIsEventModalOpen: (event: boolean) => void;
}

const Calendar = ({ eventData, activeEvent, setActiveEvent, setIsEventModalOpen }: CalendarProps) => {
  const [loading, setLoading] = useState(false);

  const Skeleton = () => (
    <div className="w-full bg-gray-200 rounded-lg shadow-sm p-3 mb-2 animate-pulse">
      <div className="flex flex-col sm:flex-row items-start">
        <div className="flex mb-2 sm:mb-0 sm:mr-4">
          <div className="flex-shrink-0 mr-2 sm:mr-4 w-10 text-center">
            <div className="h-8 bg-gray-300 rounded"></div>
          </div>
          <div className="flex-shrink-0 w-20">
            <div className="h-4 bg-gray-300 rounded mb-1"></div>
            <div className="h-4 bg-gray-300 rounded mb-1"></div>
            <div className="h-4 bg-gray-300 rounded"></div>
          </div>
        </div>
        <div className="flex-grow w-full sm:w-auto">
          <div className="h-4 bg-gray-300 rounded mb-1"></div>
          <div className="h-3 bg-gray-300 rounded mb-1"></div>
          <div className="h-3 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );

  const parseDateString = (dateString: string | undefined) => {
    console.log("Received dateString:", dateString);
  
    if (!dateString) {
      console.log("dateString is falsy");
      return {
        day: 'N/A',
        month: 'N/A',
        year: 'N/A',
        dayOfWeek: 'N/A',
        time: 'N/A'
      };
    }
  
    try {
      // Parse the input date string
      const inputDate = new Date(dateString);
      
      // Convert to MST
      const mstDate = new Date(inputDate.toLocaleString("en-US", {timeZone: "America/Denver"}));
      
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      
      const daysOfWeek = [
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
      ];

      const day = mstDate.getDate();
      const month = months[mstDate.getMonth()];
      const year = mstDate.getFullYear();
      const dayOfWeek = daysOfWeek[mstDate.getDay()];

      let hour = mstDate.getHours();
      const minutes = mstDate.getMinutes().toString().padStart(2, '0');
      const ampm = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12;
      hour = hour ? hour : 12; 

      return {
        day,
        month,
        year,
        dayOfWeek,
        time: `${hour}:${minutes} ${ampm} MST`
      };
    } catch (error) {
      console.error("Error parsing date:", error);
      console.error("Problematic dateString:", dateString);
      return {
        day: 'Error',
        month: 'Error',
        year: 'Error',
        dayOfWeek: 'Error',
        time: 'Error'
      };
    }
  };

  const { day, month, year, dayOfWeek, time } = parseDateString(eventData.start_date);

  const handleClick = async (e: React.MouseEvent) => {
    console.log("clicked", eventData.id);
    const supabase = supabaseConnect(); 
    const { data, error } = await supabase.from("event").select("*").eq("id", eventData.id);
    console.log(data);
    setActiveEvent(data);
    setIsEventModalOpen(true);
    return data;
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="w-full bg-white rounded-lg shadow-sm cursor-pointer p-3 mb-2" onClick={handleClick}>
          <div className="flex flex-col sm:flex-row items-start">
            <div className="flex mb-2 sm:mb-0 sm:mr-4">
              <div className="flex-shrink-0 mr-2 sm:mr-4 w-10 text-center">
                <p className="text-3xl font-bold text-communityBlue m-0 leading-tight">{day}</p>
              </div>
              <div className="flex-shrink-0 w-20">
                <p className="text-sm font-medium text-gray-600 m-0">{dayOfWeek}</p>
                <p className="text-sm text-gray-500 m-0">{month}</p>
                <p className="text-sm text-gray-500 m-0">{year}</p>
              </div>
            </div>
            <div className="flex-grow w-full sm:w-auto">
              <h3 className="text-sm font-semibold text-black m-0 mb-1 break-words">{eventData.title}</h3>
              <div className="flex items-center text-xs text-gray-600 mb-1">
                <img src={calendar} alt="Time" className="w-3 h-3 mr-1 flex-shrink-0" />
                <span>{time}</span>
              </div>
              <div className="flex items-start text-xs text-gray-600">
                <img src={pin} alt="Location" className="w-3 h-3 mr-1 mt-1 flex-shrink-0" />
                <span className="break-words">{eventData.location}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Calendar;
