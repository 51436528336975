import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import StaffAndAdminSidebar from './components/Sidebar/StaffAndAdminSidebar';
import Dashboard from './components/Dashboard/Dashboard';
import Analytics from './components/Analytics/Analytics';
import Settings from './components/Settings/Settings';
import MainLayoutWrapper from '../../components/Layout/MainLayoutWrapper';
import Users from '../admin/users';
import EditUser from '../admin/users/EditUser';
import Tools from './components/Tools/Tools';
import DynamicForm from './components/DailyEmailForm';
import AdminPanel from './components/AdminPanel/AdminPanel';
import DailyUpdatesApproval from './components/DailyUpdatesApproval/DailyUpdatesApproval';

// Import the admin components
import AddAnnouncement from '../admin/announcements';
import CreatePartner from '../admin/partners/Create_Partner';
import EditPartner from '../admin/partners/EditPartner';
import CreateVendor from '../admin/vendors/Create_Vendor';
import EditVendor from '../admin/vendors/EditVendor';
import Vendors from '../Recommended_Vendors/Vendors'
import Partners from '../partners/Partners'
import NewEventModal from '../../components/Events/NewEventModal'; // Add this import

const StaffAndAdminPage: React.FC = () => {
  const [showEventModal, setShowEventModal] = useState(false);

  return (
    <div className="flex h-screen">
      <StaffAndAdminSidebar />
      <MainLayoutWrapper>
        <div className="container mx-auto px-6 py-8">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="settings" element={<Settings />} />
            <Route path="users" element={<Users />} />
            <Route path="users/edit/:id" element={<EditUser />} />
            <Route path="tools" element={<Tools />} />
            <Route path="tools/daily-email-form" element={<DynamicForm />} />
            <Route path="admin-panel" element={<AdminPanel setShowEventModal={setShowEventModal} />} />
            <Route path="daily-updates-approval" element={<DailyUpdatesApproval />} />
            
            {/* Admin Panel Routes */}
            <Route path="announcements/create" element={<AddAnnouncement />} />
            <Route path="partners/create" element={<CreatePartner />} />
            <Route path="partners/edit" element={<Partners editMode={true} />} />
            <Route path="vendors/create" element={<CreateVendor />} />
            <Route path="vendors/edit" element={<Vendors editMode={true} />} />
          </Routes>
        </div>
      </MainLayoutWrapper>
      {showEventModal && (
        <NewEventModal
          showModal={showEventModal}
          setShowModal={setShowEventModal}
          setList={() => {}}
          setEventData={() => {}}
          activeEvent={[]}
        />
      )}
    </div>
  );
};

export default StaffAndAdminPage;
