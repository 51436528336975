// @ts-nocheck
import React, { useState, useEffect, useRef  } from "react";
import MainEditor from "../../../editor";
import Sidebar from "../../../components/Sidebar/Sidebar";
import supabaseConnect from "../../../utils/supabaseConnect";
import { useParams } from "react-router-dom";
import MainLayoutWrapper from "../../../components/Layout/MainLayoutWrapper"; 

const EditAnnouncement = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const supabase = supabaseConnect();
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [body, setBody] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [editor, setEditor] = useState(null);
  const clearContent = () => {
    setEditor("");
    setTitle("");
    setDescription("");
    setBody("");
  };
  let rolesId = [];
  const announcementId = useParams();
  const [typeOptions, setTypeOptions] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const deleteButtonRef = useRef(null);
  const [expirationDate, setExpirationDate] = useState('');


  const checkAdmin = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user.id;

    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", userId);

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      console.log(data);
      if (data.length <= 0) {
        return (window.location.href = "/");
      }
    }
  };

  // todo: add auth logic that you have to be admin to view page

  const handleSubmit = async () => {
    // get text from inputs and editor and set to state
    console.log("test");
    const title = document.getElementById("grid-title") as HTMLInputElement;
    const description = document.getElementById(
      "grid-description"
    ) as HTMLInputElement;
    const whoIsThisFor = options;

    const titleValue = title.value;
    const descriptionValue = description.value;
    const editorValue = editor;

    // submit resource to db
    await submitResource(titleValue, descriptionValue, editorValue);
  };

  const getAllImages = async () => {
    const { data, error } = await supabase.storage.from("media").list();

    if (error) alert(error?.message);

    console.log(data);

    return data;
  };

  const getRole = async (roles) => {
    for (let i = 0; i < roles.length; i++) {
      let roleName = roles[i];
      const { data, error } = await supabase
        .from("role")
        .select("*")
        .eq("role_name", roleName);

      rolesId.push(data[0]);

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        console.log(rolesId);
      }
    }
    return rolesId;
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const { data, error } = await supabase.from("role").select();

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        // filter out the admin role
        const newData = data.filter((role) => role.role_name !== "Admin");
        setOptions(newData);
      }
    };
    const fetchTypeOptions = async () => {
      const { data, error } = await supabase.from("tag").select();

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        // filter out the admin role
        console.log(data);
        setTypeOptions(data);
      }
    };
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("announcement")
        .select("*")
        .eq("id", announcementId.id);

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        console.log(data);
        setTitle(data[0].title);
        setDescription(data[0].description);
        setEditor(data[0].info);
        setExpirationDate(data[0].expiration_date);
        setCoverImage(data[0].cover_image);
      }
    };

    const announcementRoleFetch = async () => {
      const { data, error } = await supabase
        .from("announcement_role")
        .select("*")
        .eq("announcement_id", announcementId.id);

      if (error) {
        console.error("Error fetching options: ", error);
      }

      console.log(data);

      if (data.length > 0) {
        const { data: role, error: roleError } = await supabase
          .from("announcement_role")
          .select("*")
          .eq("id", data[0].role_id);

        if (error) {
          console.error("Error fetching options: ", error);
        }

        const { data: roleData, error: roleDataError } = await supabase
          .from("role")
          .select("*")
          .eq("id", data[0].role_id);

        if (error) {
          console.error("Error fetching options: ", error);
        }
        setSelectedOptions([roleData[0].role_name]);
      }
    };

    const fetches = async () => {
      await fetchData();
      await checkAdmin();
      await fetchTypeOptions();
      await fetchOptions();
      await announcementRoleFetch();
    };
    fetches();
  }, []);

  const handleUpload = async (e) => {
    const file = e.target.files[0];

    console.log(file);

    const filename = `${Math.floor(Math.random() * 1000000000)}`;

    if (file) {
      const { data, error } = await supabase.storage
        .from("media")
        .upload(`${filename}.${file.name}`, file);

      const { data: publicUrl, error: publicUrlError } = await supabase.storage
        .from("media")
        .getPublicUrl(`${filename}.${file.name}`);

      if (error) {
        console.log(error);
      }
      setCoverImage(publicUrl.publicUrl);

      const images = getAllImages();

      return images;
    }
    return "no file";
  };

  const submitResource = async (titleValue, descriptionVaue, editorValue) => {
    const { data, error } = await supabase
      .from("announcement")
      .update({
        title: titleValue,
        description: descriptionVaue,
        info: editorValue,
        cover_image: coverImage,
        expiration_date: expirationDate,
      })
      .eq("id", announcementId.id)
      .select("*");

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      const roleId = await getRole(selectedOptions);
      console.log(roleId);
      for (let i = 0; i < roleId.length; i++) {
        const newRoleId = roleId[i].id;
        const announcementId = data[0].id;
        const { data: announcement_role, error: announcement_role_error } =
          await supabase
            .from("announcement_role")
            .update({
              announcement_id: announcementId,
              role_id: newRoleId,
            })
            .eq("id", announcementId)
            .select("*");

        if (announcement_role_error) {
          console.error("Error fetching options: ", announcement_role_error);
        } else {
          console.log(announcement_role);
        }
      }
      rolesId = [];
      clearContent();
      window.location.href = `/`;
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedOptions((prev) => [...prev, event.target.value]);
    } else {
      setSelectedOptions((prev) =>
        prev.filter((option) => option !== event.target.value)
      );
    }
  };

  const handleDelete = async () => {
    const { data: announcement_role, error: announcement_role_error } = await supabase
      .from("announcement_role")
      .delete()
      .eq("announcement_id", announcementId.id);

    if (announcement_role_error) {
      console.error("Error fetching options: ", announcement_role_error);
    }
    else {
    const { data, error } = await supabase
      .from("announcement")
      .delete()
      .eq("id", announcementId.id);

      console.log(error)

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      window.location.href = `/`;
    }
  }
  };

  const handleDeleteClick = () => {
    if (showConfirmDelete) {
      // If already in confirmation mode, run the delete function
      handleDelete();
    } else {
      // Show confirm delete message
      setShowConfirmDelete(true);
    }
  };

  // Reset the confirmation mode if needed elsewhere in your component
  const resetConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const handleClickOutside = (event) => {
    if (showConfirmDelete && deleteButtonRef.current && !deleteButtonRef.current.contains(event.target)) {
      resetConfirmation();
    }
  };

  // Add event listeners when component mounts and handles the conditional logic
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    // Clean up event listener to prevent memory leaks
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showConfirmDelete]); // Only re-run if showConfirmDelete changes

  return (
    <div className="flex w-full h-screen overflow-hidden text-left  font-inter">
      <Sidebar />
      <MainLayoutWrapper>
        <div className=" p-5">
          <div className=" py-2 text-white">
            <div className="flex sm:flex-row flex-col justify-between items-center pl-4 pr-4 border-solid border-lightGray border-[.1px]">
              <h1 className="text-[1.2rem] font-bold">Edit Announcement</h1>
              <div className="flex xs:flex-row flex-col sm:pb-0 pb-3  gap-3">
                {/* Conditionally render the Delete button text based on the confirmation state */}
                
                <button
                  ref={deleteButtonRef}
                  onClick={handleDeleteClick}
                  className="border px-4 py-2 cursor-pointer text-white border-none bg-red-500 rounded-md xs:mr-2"
                >
                  {showConfirmDelete ? "Confirm Delete?" : "Delete Announcement"}
                </button>
                {/* Publish Changes Button */}
                <button
                  onClick={handleSubmit}
                  className="border px-4 py-2 cursor-pointer text-white border-none bg-lightBlue rounded-md"
                >
                  Publish Changes
                </button>
              </div>
            </div>
            <form className=" border-solid border-lightGray border-[.1px] p-[1rem]">
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-title"
                  >
                    Title
                  </label>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none rounded-md border-solid border-[.2px]"
                    id="grid-title"
                    type="text"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-description"
                  >
                    Description
                  </label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none focus:outline-[.1px] rounded-md border-solid border-[.1px] border-none resize-none"
                    id="grid-description"
                    placeholder="Description"
                  ></input>
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-description"
                  >
                    Cover Image
                  </label>
                  {/* Display the current image if available */}
                  {coverImage && (
                    <div className="mb-3">
                      <img
                        src={coverImage}
                        alt="Current Cover"
                        className="w-[15rem]"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUpload(e)}
                    className="appearance-none block w-full bg-gray text-white border border-gray rounded py-3 leading-tight focus:border-gray focus:outline-none focus:outline-[.1px] rounded-md border-solid border-[.1px] border-none resize-none"
                    id="grid-description"
                  />
                  {/* Optionally, add a note about keeping the current image */}
                  {coverImage && (
                    <p className="text-xs text-gray-500">
                      If you don't choose a new image, the current image will be
                      kept.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <div className="w-full">
                    <label
                      className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-description"
                    >
                      Who is this Announcement for?
                    </label>
                    <div className="flex flex-row space-x-4 p-2 bg-gray border-lightGray rounded">
                      {options.map((option, index) => (
                        <label key={index} className="p-2">
                          <input
                            type="checkbox"
                            value={option.role_name}
                            checked={selectedOptions.includes(option.role_name)}
                            onChange={handleCheckboxChange}
                            className="mr-2"
                          />
                          {option.role_name}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-[8rem]">
                <div className="w-[8rem] mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-expiration-date"
                  >
                    Expiration Date
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none rounded-md border-solid border-[.2px]"
                    id="grid-expiration-date"
                    type="date"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-content"
                  >
                    Content
                  </label>
                  <div className="">
                    <MainEditor editor={editor} setEditor={setEditor} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default EditAnnouncement;
