import React, { useState, useEffect } from 'react';
import StarRating from './StarRating';

interface RatingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (rating: number, comment: string) => void;
  initialRating: number | null;
}

const RatingModal: React.FC<RatingModalProps> = ({ isOpen, onClose, onSubmit, initialRating }) => {
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (isOpen && initialRating !== null) {
      setRating(initialRating);
    }
  }, [isOpen, initialRating]);

  const handleClose = () => {
    setRating(0);
    setComment('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#1d2025] rounded-lg p-8 max-w-md w-full text-white">
        <h2 className="text-2xl font-bold mb-4">Rate this vendor</h2>
        <div className="mb-4">
          <StarRating rating={rating} onRatingChange={setRating} />
        </div>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Leave a comment..."
          className="w-full h-32 p-2 border border-gray-600 rounded mb-4 bg-[#2d3035] text-white"
        />
        <div className="flex justify-end space-x-2">
          <button 
            onClick={() => onSubmit(rating, comment)}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
          <button 
            onClick={handleClose}
            className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatingModal;