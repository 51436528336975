import React, {useEffect, useState} from "react";
import { calendar } from "../../assets";
import { pin } from "../../assets";
import EventModal from "../Events/EventModal";
import supabaseConnect from "../../utils/supabaseConnect";
import NewEventModal from "../Events/NewEventModal";

interface CardLayoutProps {
  start_date: string;
  title: string;
  description: string;
  id: number;
  info: string;
  location: string;
  setActiveEvent: (event: any) => void;
  setIsEventModalOpen: (event: boolean) => void;
}

interface CardStyles {
  backgroundColor: string;
  textColor: string;
  numberColor: string;
}

const CardLayout: React.FC<CardLayoutProps & { cardStyles?: CardStyles }> = ({
  description,
  id,
  info,
  location,
  start_date,
  title,
  setActiveEvent,
  setIsEventModalOpen,
  cardStyles = {
    backgroundColor: 'white',
    textColor: 'black',
    numberColor: '#08C2F8'
  }
}) => {
  const dateObj = new Date(start_date);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "long" });
  const year = dateObj.getFullYear();
  const dayOfWeek = dateObj.toLocaleString("default", { weekday: "long" });
  const time = dateObj.toLocaleString("default", { hour: "numeric", minute: "numeric", hour12: true });

  const handleCardClick = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase.from("event").select("*").eq("id", id);
    console.log(data);
    setActiveEvent(data);
    setIsEventModalOpen(true);
    return data;
  };

  return (
    <div 
      onClick={handleCardClick} 
      className="shadow-md py-8 w-full h-[18rem] cursor-pointer"
      style={{ backgroundColor: cardStyles.backgroundColor }}
    >
      <div id="modal-root"></div>
      <div className="flex gap-3 card-number w-full card-number items-center" >
        <p className="text-[3rem] font-bold m-0 pl-3 " style={{ color: cardStyles.numberColor }}>{day}</p>
        <div className="">
          <p className="m-0 text-sm font-bold">{dayOfWeek}</p>
          <p className="m-0 text-sm">{month} {year}</p>
        </div>
      </div>
      <div className="my-[20px] px-6">
        <p className="m-0 mb-1 text-[18px] font-semibold" style={{ color: cardStyles.textColor }}>{title}</p>
        <p className="m-0 text-[14px]" style={{ color: cardStyles.textColor }}>{description}</p>
      </div>
      <div className="px-10" style={{ color: cardStyles.textColor }}>
        <div className="flex gap-2 text-sm mb-3">
          <img src={calendar} alt="svg of an calendar" className="w-4" />
          <p className="m-0">{time}</p>
        </div>
        <div className="flex gap-2 text-sm">
          <img src={pin} alt="svg of an calendar" className="w-4" />
          <p className="m-0">{location}</p>
        </div>
      </div>
    </div>
  );
};

interface CardGridProps {
  data: CardLayoutProps[];
  isOpen: boolean;
  onClose: () => void;
  activeEvent: any;
  setBeingEdited: (event: boolean) => void;
  setShowEventModal: (event: boolean) => void;
  setActiveEvent: (event: any) => void;
  title: string;
  cardStyles?: CardStyles;
}

const CalendarCards: React.FC<CardGridProps> = ({
  data,
  cardStyles = {
    backgroundColor: 'black',
    textColor: 'white',
    numberColor: '#08C2F8'
  }
}) => {
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<any>();
  const [showEventModal, setShowEventModal] = useState(false);
  const [beingEdited, setBeingEdited] = useState(false);
  const [list, setList] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const supabase = supabaseConnect();

  useEffect(() => {
    const getEvents = async () => {
      const { data, error } = await supabase
        .from("event")
        .select("*")
        .order("id", { ascending: false });
      if (error) console.error("Error fetching events: ", error);
      // order by start_date
      console.log("Events: ", data);
      const sortedData = data?.sort((a: any, b: any) => {
        return (
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        );
      });
      // filter out events that have already passed
      const currentDate = new Date();
      let filteredData = sortedData?.filter((event: any) => {
        return new Date(event.start_date) >= currentDate;
      });

      // slice the first 3 events
      filteredData = filteredData?.slice(0, 6);

      // run through the data and shorten the title to 20 characters
      filteredData = filteredData?.map((event: any) => {
        if (event.title.length > 20) {
          event.title = event.title.substring(0, 15) + "...";
        }
        return event;
      });

      setEventData(filteredData);
      return data;
    };
    getEvents();
  }
  , []);

  return (
    <div>
      <EventModal
        isOpen={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        activeEvent={activeEvent}
        setBeingEdited={setBeingEdited}
        setShowEventModal={setShowEventModal}
        setActiveEvent={setActiveEvent}
      ></EventModal>
      <NewEventModal
        showModal={showEventModal}
        setShowModal={setShowEventModal}
        setList={setList}
        setEventData={setEventData}
        activeEvent={activeEvent}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:px-4"> {/* Removed px-4, added sm:px-4 */}
        {data?.map((card, index) => (
          <div className="w-full" key={index}>
            <CardLayout
              {...card}
              setIsEventModalOpen={setIsEventModalOpen}
              setActiveEvent={setActiveEvent}
              cardStyles={cardStyles}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarCards;
