import { info } from "console";
import React, { useState, FunctionComponent, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import supabaseConnect from "../../../utils/supabaseConnect";

interface CardProps {
  first_name: string;
  last_name: string;
  email: string;
  phone: string; 
  job_title: string;
  id: number;
  profile_image_url: string;
}


// Define these states at a higher level, outside of the click handler

const Card: React.FC<CardProps> = ({
  first_name,
  last_name,
  email,
  phone,
  job_title,
  profile_image_url,
  id,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const supabase = supabaseConnect();
  const [users, setUsers] = useState([]) as any[];
  const navigate = useNavigate();

  const handleCardClick = async (id: number ) => {
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", id)
      .limit(1);
    if (error) console.log("error", error);
    setUsers(data);
    navigate(`/users/edit/${id}`);
  };
  
  return (
    <div
      onClick={() => handleCardClick(id)}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden h-full bg-black cursor-pointer"
    >
      <div className="flex-shrink-0">
        <img
          className="h-48 w-full object-cover"
          src={profile_image_url}
          alt=""
        />
      </div>
      <div className="flex-1 bg-black p-4 flex flex-col justify-between">
        <div>
          <p className="text-xl font-semibold text-white">
            {first_name} {last_name}
          </p>
          <p className="mt-2 text-sm text-white">{job_title}</p>
          <p className="mt-1 text-xs text-gray-400">{email}</p>
        </div>
        <div className="mt-4 text-sm text-white flex justify-end">
          Edit Users Info <span>&rarr;</span>
        </div>
      </div>
    </div>
  );
};

interface CardGridProps {
  data: CardProps[];
}

const CardGrid: React.FC<CardGridProps> = ({ data }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-52">
    {data?.map((card, index) => (
      <Card key={index} {...card} />
    ))}
  </div>
);

export default CardGrid;
