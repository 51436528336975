import React, { useState, useEffect, Suspense, useRef } from 'react';
import MainLayoutWrapper from '../../../../components/Layout/MainLayoutWrapper';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Snackbar,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import supabaseConnect from '../../../../utils/supabaseConnect';
import { ZonedDateTime, ZoneId } from '@js-joda/core';
import '@js-joda/timezone';
import Editor from '../../../../editor';
const LazyMainEditor = React.lazy(() => import('../../../../editor/index'));

interface Item {
  id: number;
  title: string;
  content: string;
}

export default function DynamicForm(): JSX.Element {
  /**
   * @description State management for the DailyEmailForm component
   * @breakage If this state management code is missing, the component won't be able to track items, handle item selection, manage dialog visibility, or display notifications.
   * @dependencies addItem, removeItem, updateItem, handleItemClick, handleCloseDialog, handleSubmit
   */
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [isAfterDeadline, setIsAfterDeadline] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    checkDeadline();
  }, []);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const checkDeadline = () => {
    const mstZone = ZoneId.of('America/Denver');
    const now = ZonedDateTime.now(mstZone);
    const deadline = now.withHour(13).withMinute(45).withSecond(0).withNano(0);
    setIsAfterDeadline(now.isAfter(deadline));
  };

  /**
   * @description Adds a new item to the form
   * @breakage Without this function, users won't be able to add new items to the form.
   * @dependencies EmptyState, Button in main component
   */
  const addItem = (): void => {
    const newItem: Item = {
      id: Date.now(),
      title: '',
      content: ''
    };
    setItems([...items, newItem]);
  };

  /**
   * @description Removes an item from the form
   * @breakage If this function is missing, users won't be able to delete items from the form.
   * @dependencies ItemCard
   */
  const removeItem = (idToRemove: number): void => {
    setItems(items.filter(item => item.id !== idToRemove));
  };

  /**
   * @description Updates an item's details
   * @breakage Without this function, users won't be able to edit item details in the dialog.
   * @dependencies Dialog content, TextField components in dialog
   */
  const updateItem = (id: number, field: keyof Omit<Item, 'id'>, value: string): void => {
    setItems(items.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    ));
    // Update the selectedItem if it's currently being edited
    if (selectedItem && selectedItem.id === id) {
      setSelectedItem({ ...selectedItem, [field]: value });
    }
  };

  const handleItemClick = (item: Item): void => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  /**
   * @description Submits the daily updates to the database
   * @breakage If this function is missing, users won't be able to submit their daily updates to the database.
   * @dependencies Submit button, Snackbar component
   */
  const handleSubmit = async (): Promise<void> => {
    const mstZone = ZoneId.of('America/Denver');
    const now = ZonedDateTime.now(mstZone);
    const deadline = now.withHour(13).withMinute(45).withSecond(0).withNano(0);

    if (now.isAfter(deadline)) {
      setConfirmDialogOpen(true);
    } else {
      await submitUpdates();
    }
  };

  const submitUpdates = async (forTomorrow: boolean = false): Promise<void> => {
    const supabase = supabaseConnect();
    const { data: { user } } = await supabase.auth.getUser();

    if (!user) {
      setSnackbarMessage('User not authenticated');
      setSnackbarOpen(true);
      return;
    }

    const mstZone = ZoneId.of('America/Denver');
    let submissionDate = ZonedDateTime.now(mstZone);
    
    if (forTomorrow) {
      submissionDate = submissionDate.plusDays(1);
    }

    const dailyUpdates = items.map(item => ({
      profile_id: user.id,
      title: item.title,
      content: item.content,
      created_at: submissionDate.toInstant().toString(),
    }));

    const { data, error } = await supabase
      .from('daily_updates')
      .insert(dailyUpdates);

    if (error) {
      console.error('Error submitting daily updates:', error);
      setSnackbarMessage('Error submitting daily updates');
    } else {
      console.log('Daily updates submitted successfully:', data);
      setSnackbarMessage(`Daily updates submitted successfully${forTomorrow ? ' for tomorrow' : ''}`);
      setItems([]); // Clear the form after successful submission
    }
    setSnackbarOpen(true);
    setConfirmDialogOpen(false);
  };

  /**
   * @description Renders an individual item card
   * @breakage Without this component, individual items won't be displayed in the form.
   * @dependencies handleItemClick, removeItem
   */
  const ItemCard: React.FC<{ item: Item }> = ({ item }) => (
    <Card 
      className="relative cursor-pointer hover:shadow-lg transition-shadow duration-300 h-full flex flex-col text-white border border-gray-700"
      style={{ backgroundColor: '#000000', border: '1px solid #000000', borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)' }}
    >
      <CardContent onClick={() => handleItemClick(item)} className="flex-grow">
        <div className="flex justify-between items-start mb-2">
          <Typography variant="h6" component="h3" className="text-white">
            {item.title || 'Untitled Item'}
          </Typography>
          <div className="flex space-x-1">
            <IconButton
              size="small"
              sx={{
                color: 'white',
                '&:hover': {
                  color: '#099aad'
                }
              }}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleItemClick(item);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                color: 'white',
                '&:hover': {
                  color: '#099aad'
                }
              }}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                removeItem(item.id);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
        <Typography 
          variant="body2" 
          className="text-gray-300 overflow-hidden line-clamp-2"
          dangerouslySetInnerHTML={{ __html: item.content || 'No content yet' }}
        />
      </CardContent>
    </Card>
  );

  /**
   * @description Renders an empty state when no items are present
   * @breakage If this component is missing, there won't be a proper display or action when no items are present.
   * @dependencies addItem
   */
  const EmptyState: React.FC = () => (
    <div className="text-center py-6 bg-gray-800 rounded border border-dashed border-gray-600">
      <Typography className="text-gray-300 mb-2">
        No items yet
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={addItem}
        sx={{ 
          backgroundColor: '#099aad',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#0b7c8a',
          },
        }}
      >
        Add Your First Item
      </Button>
    </div>
  );

  return (
    <MainLayoutWrapper>
      <div className="px-4 py-4 bg-gray-900 relative">
        <div className="mb-4 flex justify-between items-center pb-8">
          <Typography variant="h4" component="h1" className="text-2xl font-semibold text-white">
            What would you like to include in the Daily Email?
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addItem}
            sx={{ 
              backgroundColor: '#099aad',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#0b7c8a',
              },
            }}
          >
            Add Item
          </Button>
        </div>

        {isAfterDeadline && (
          <Typography 
            variant="body2" 
            className="mb-4 p-3 bg-yellow-900 text-yellow-200 rounded-md"
          >
            Note: It's past 1:45 PM MST. Your submission will be included in tomorrow's email.
          </Typography>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {items.map((item) => (
            <div key={item.id}>
              <ItemCard item={item} />
            </div>
          ))}
        </div>

        {items.length === 0 && <EmptyState />}

        {items.length > 0 && (
          <div className="mt-6 flex justify-end">
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ 
                backgroundColor: '#099aad',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#0b7c8a',
                },
              }}
            >
              Submit
            </Button>
          </div>
        )}

        {/* /**
          * @description Dialog for editing item details
          * @breakage Without this dialog, users won't be able to edit item details.
          * @dependencies handleCloseDialog, updateItem, selectedItem state
        */ }
        <Dialog 
          open={isDialogOpen} 
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: '#1f2937',
              color: '#ffffff',
              borderRadius: '8px',
            },
          }}
          hideBackdrop={true}
          disableEnforceFocus={true}
          disableAutoFocus={true}
          disablePortal={true}
        >
          <DialogTitle 
            style={{ 
              backgroundColor: '#111827',
              color: '#ffffff',
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Edit Item</Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                color: 'white',
                '&:hover': {
                  color: '#099aad',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {selectedItem && (
            <>
              <DialogContent dividers style={{ borderColor: '#374151', padding: '24px' }}>
                <div className="flex flex-col gap-4">
                  <TextField
                    label="Title"
                    fullWidth
                    value={selectedItem.title}
                    onChange={(e) => updateItem(selectedItem.id, 'title', e.target.value)}
                    placeholder="Enter title"
                    variant="outlined"
                    InputLabelProps={{ style: { color: '#9ca3af' } }}
                    InputProps={{
                      style: { 
                        color: '#ffffff',
                        backgroundColor: '#374151',
                        borderColor: '#4b5563',
                      }
                    }}
                  />
                  <div className="mt-4">
                    <Typography variant="subtitle1" className="mb-2 text-gray-300">
                      Content
                    </Typography>
                    {editorLoaded ? (
                      <Suspense fallback={<div>Loading editor...</div>}>
                        <div className="">
                          <LazyMainEditor 
                            editor={selectedItem.content}
                            setEditor={(newContent: string) => updateItem(selectedItem.id, 'content', newContent)}
                            body={selectedItem.content}
                          />
                        </div>
                      </Suspense>
                    ) : (
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        value={selectedItem.content}
                        onChange={(e) => updateItem(selectedItem.id, 'content', e.target.value)}
                        placeholder="Enter content"
                        variant="outlined"
                        InputLabelProps={{ style: { color: '#9ca3af' } }}
                        InputProps={{
                          style: { 
                            color: '#ffffff',
                            backgroundColor: '#374151',
                            borderColor: '#4b5563',
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: '16px', backgroundColor: '#111827' }}>
                <Button 
                  onClick={handleCloseDialog}
                  sx={{ 
                    backgroundColor: '#111827',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#099aad',
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  variant="contained" 
                  onClick={handleCloseDialog}
                  sx={{ 
                    backgroundColor: '#111827',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#099aad',
                    },
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

        {/* /**
         * @description Snackbar for displaying feedback messages
         * @breakage If the Snackbar is missing, users won't receive feedback messages after actions like submitting updates.
         * @dependencies snackbarOpen state, snackbarMessage state, handleSubmit
         */ }
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />

        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: '#1f2937',
              color: '#ffffff',
              borderRadius: '8px',
            },
          }}
        >
          <DialogTitle id="confirm-dialog-title" style={{ backgroundColor: '#111827', color: '#ffffff' }}>
            Confirm Submission
          </DialogTitle>
          <DialogContent style={{ backgroundColor: '#1f2937', color: '#ffffff' }}>
            <Typography id="confirm-dialog-description">
              It's past 1:45 PM MST. Do you want to include this item in tomorrow's email?
            </Typography>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#111827', padding: '16px' }}>
            <Button 
              onClick={() => setConfirmDialogOpen(false)} 
              sx={{ 
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#374151',
                },
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => submitUpdates(true)} 
              sx={{ 
                backgroundColor: '#099aad',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#0b7c8a',
                },
              }}
              autoFocus
            >
              Submit for Tomorrow
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </MainLayoutWrapper>
  );
}
