import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { magnifyingglass } from "../../../assets";
import CardGrid from "./UsersCards";
import supabaseConnect from "../../../utils/supabaseConnect";
import MainLayoutWrapper from "../../../components/Layout/MainLayoutWrapper";

const Users = () => {
  const { query } = useParams<{ query: string }>();
  const [searchTerm, setSearchTerm] = useState(query);
  const supabase = supabaseConnect();
  const [data, setData] = useState([]) as any[];
  const [userAdmin, setUserAdmin] = useState(false);

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) {
      setUserAdmin(true);
      return true;
    } else return false;
  };

  const search = async () => {
    try {
      // Execute search queries for each table
      const firstName = await Promise.all([
        supabase
          .from("profiles")
          .select("*")
          .textSearch("first_name", searchTerm || "", { type: "websearch" }),
      ]);
      const lastName = await Promise.all([
        supabase
          .from("profiles")
          .select("*")
          .textSearch("last_name", searchTerm || "", { type: "websearch" }),
      ]);
      console.log(firstName);
      if (firstName?.[0]?.data && firstName[0].data.length === 0) {
        const results = await Promise.all([
          supabase.from("profiles").select("*"),
        ]);

        return setData(results?.[0]?.data ?? []);
      }

      console.log("Search results: ", firstName[0]?.data);

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(firstName[0]?.data ?? []),
        ...(lastName[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);
      setData(results);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      return [];
    }
  };

  useEffect(() => {
    search();
  }, []);

  return (
      <div className="flex flex-col h-full overflow-hidden -ml-[3rem] sm:p-6 md:p-8 -mt-[6rem]">
        <div className="">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-5 text-white">
            Users
          </h1>
          <div className="relative mb-5">
            <input
              className="w-full sm:w-[28rem] md:w-[33rem] py-3 pl-[60px] pr-4 rounded-xl bg-black text-white placeholder:text-gray-400 focus:outline-none"
              placeholder="Search agent name here"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search();
                }
              }}
            />
            <img
              className="absolute top-1/2 left-[15px] w-5 h-5 -translate-y-1/2"
              alt=""
              src={magnifyingglass}
            />
          </div>
        </div>
        <div className="flex-grow overflow-y-auto">
          <CardGrid data={data} />
        </div>
      </div>
  );
};

export default Users;
