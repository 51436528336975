import React, { useRef, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

interface UpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

/**
 * UpdateModal component
 * 
 * This component renders a modal overlay that displays its children when open.
 * It handles closing the modal when clicking outside of its content.
 * 
 * @param {boolean} isOpen - Determines if the modal is visible.
 * @param {() => void} onClose - Callback function to close the modal.
 * @param {React.ReactNode} children - The content to display inside the modal.
 * 
 * @returns {JSX.Element | null} The rendered modal component or null if not open.
 */
const UpdateModal: React.FC<UpdateModalProps> = ({
  isOpen,
  onClose,
  children
}) => {
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Stop if clicking on a card or survey content
    if (
      (e.target as HTMLElement).closest('[data-testid="announcement-container"]') ||
      (e.target as HTMLElement).closest('[data-testid="survey-content"]')
    ) {
      return;
    }
    
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div 
      onClick={handleBackdropClick}
      className="fixed inset-0 bg-black/50 overflow-y-auto z-50 scrollbar-hide backdrop-blur-sm"
    >
      {children}
    </div>
  );
};

export default UpdateModal; 