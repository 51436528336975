import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import MainLayoutWrapper from '../../components/Layout/MainLayoutWrapper';
import supabaseConnect from '../../utils/supabaseConnect';
import { sanitizeHtmlContent } from '../../utils/sanitizeHtml';
import StarRating from '../../components/Rating/StarRating';
import PartnerAndVendorSearchAndFilter from '../../components/PartnerAndVendorSearchAndFilter/PartnerAndVendorSearchAndFilter';
import { sortAlphabetically } from '../../utils/sortAlphabetically';
import { FaPlus, FaTimes } from 'react-icons/fa'; // Add this import at the top of the file
import Button from '../../components/Button/Button';

interface Vendor {
  id: number;
  name: string;
  description: string;
  info: string;
  logo_url: string;
  categories: number[];
}

interface VendorCardProps extends Vendor {
  onClick: () => void;
  onEdit: () => void;
  showEditButton: boolean;
}

interface Category {
  id: number;
  name: string;
}

/**
 * VendorCard component
 * Renders a card displaying vendor information
 * @param {Object} props - Component props
 * @param {number} props.id - Vendor ID
 * @param {string} props.name - Vendor name
 * @param {string} props.description - Vendor description
 * @param {string} props.logo_url - URL of vendor's logo
 * @param {Function} props.onClick - Function to handle card click
 * @param {Function} props.onEdit - Function to handle edit button click
 * @param {boolean} props.isAdmin - Flag indicating if user is admin
 * 
 * @remarks
 * If missing: Vendor information would not be displayed, breaking the UI and user interaction
 */
const VendorCard: React.FC<VendorCardProps> = ({ id, name, description, logo_url, onClick, onEdit, showEditButton }) => {
  const [averageRating, setAverageRating] = useState<number>(0);
  const [ratingCount, setRatingCount] = useState<number>(0);

  useEffect(() => {
    fetchRatings();
  }, [id]);

  const fetchRatings = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('vendor_ratings')
      .select('rating')
      .eq('vendor_id', id);

    if (error) {
      console.error('Error fetching ratings:', error);
    } else {
      const ratings = data.map(r => r.rating);
      const avg = ratings.length > 0 ? ratings.reduce((a, b) => a + b, 0) / ratings.length : 0;
      setAverageRating(avg);
      setRatingCount(ratings.length);
    }
  };

  const tempElement = document.createElement('div');
  tempElement.innerHTML = description;
  const extractedText = tempElement.textContent || tempElement.innerText;
  const limitedDescription = extractedText.slice(0, 35) + (extractedText.length > 35 ? '...' : '');
  
  return (
    <div 
      className="flex flex-col rounded-lg shadow-lg overflow-hidden h-[22rem] bg-[#2d3035] cursor-pointer relative"
      onClick={onClick}
    >
      <div className="flex justify-center items-center h-36 bg-white" style={{ border: '1px solid #D1D5DB' }}>
        <img
          className="h-full w-full object-cover"
          src={logo_url}
          alt={`${name} logo`}
        />
      </div>
      <div className="flex-1 bg-black p-3 flex flex-col">
        <div className="flex-1">
          <p className="text-sm font-medium text-tourquoise mb-1">
            Vendor
          </p>
          <p className="text-lg font-semibold text-white mb-1 truncate">
            {name}
          </p>
          <p className="text-sm text-white line-clamp-3">
            {limitedDescription}
          </p>
        </div>
        <div className="flex justify-between items-center text-[.8rem] text-white mt-2">
          <div className="flex items-center">
            <StarRating rating={averageRating} readonly={true} />
            <span className="ml-2">({ratingCount})</span>
          </div>
          <div>
            View Vendor<span>&rarr;</span>
          </div>
        </div>
      </div>
      {showEditButton && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          className="absolute top-2 right-2 bg-[#2d3035] text-white p-2 rounded text-sm cursor-pointer border border-white hover:bg-black"
        >
          Edit
        </button>
      )}
    </div>
  );
};

// Update the SelectedCategories component to match the previous style
const SelectedCategories: React.FC<{
  categories: Category[];
  onRemove: (id: number) => void;
}> = ({ categories, onRemove }) => {
  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {categories.map((category) => (
        <div
          key={category.id}
          className="bg-black text-white rounded-full px-3 py-1 m-1 flex items-center"
        >
          {category.name}
          <FaTimes
            className="ml-2 cursor-pointer"
            onClick={() => onRemove(category.id)}
          />
        </div>
      ))}
    </div>
  );
};

interface VendorsProps {
  editMode?: boolean;
}

/**
 * Vendors component
 * Main component for the Vendors page
 * Manages state, data fetching, and rendering of vendor cards
 * 
 * @remarks
 * If missing: The entire Vendors page would be non-functional
 */
const Vendors: React.FC<VendorsProps> = ({ editMode = false }) => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const navigate = useNavigate();
  const [visibleVendors, setVisibleVendors] = useState(8);

  /**
   * Loads more vendors by increasing the number of visible vendors.
   * 
   * @remarks
   * If missing: Users would not be able to load more vendors, limiting the number of vendors displayed.
   */
  const loadMoreVendors = () => {
    setVisibleVendors(prev => prev + 8);
  };

  /**
   * Fetches vendors data from the database
   * 
   * @remarks
   * If missing: Vendors data would not be loaded, resulting in an empty page
   */
  const fetchVendors = async () => {
    setLoading(true);
    setError(null);
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('vendors')
      .select(`
        *,
        categories:vendor_categories(category_id)
      `);
  
    if (error) {
      console.error('Error fetching vendors:', error);
      setError('Failed to fetch vendors');
    } else if (data) {
      setVendors(data.map(vendor => ({
        ...vendor,
        info: sanitizeHtmlContent(vendor.info),
        categories: vendor.categories?.map((c: { category_id: number }) => c.category_id) || []
      })));
    } else {
      setVendors([]);
    }
    setLoading(false);
  };

  /**
   * Fetches categories data from the database
   * 
   * @remarks
   * If missing: Category filtering would not work
   */
  const fetchCategories = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('categories')
      .select('*');
    
    if (error) {
      console.error('Error fetching categories:', error);
    } else {
      // Sort categories alphabetically
      setCategories(sortAlphabetically(data, 'name'));
    }
  };

  /**
   * Effect hook to fetch data and check admin status on component mount
   * 
   * @remarks
   * If missing: Initial data load and admin check would not occur
   */
  useEffect(() => {
    fetchVendors();
    fetchCategories();
    const checkAdminStatus = async () => {
      const supabase = supabaseConnect();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('profile_role')
          .select('role_id')
          .eq('user_id', user.id)
          .single();

        if (data && data.role_id === 1) {
          setIsAdmin(true);
        }
      }
    };

    checkAdminStatus();
  }, []);

  const filteredVendors = vendors.filter(vendor =>
    vendor.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedCategories.length === 0 || selectedCategories.some(cat => 
      vendor.categories && vendor.categories.includes(cat.id)
    ))
  );

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(categorySearchTerm.toLowerCase())
  );

  /**
   * Handles category selection for filtering
   * 
   * @remarks
   * If missing: Users would not be able to add category filters
   */
  const handleCategorySelect = (category: Category) => {
    if (!selectedCategories.some(cat => cat.id === category.id)) {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  /**
   * Handles category removal from filter
   * 
   * @remarks
   * If missing: Users would not be able to remove category filters
   */
  const handleCategoryRemove = (categoryId: number) => {
    setSelectedCategories(selectedCategories.filter(cat => cat.id !== categoryId));
  };

  /**
   * Navigates to vendor creation page
   * 
   * @remarks
   * If missing: Admins would not be able to create new vendors
   */
  const handleCreateVendor = () => {
    navigate('/admin/create-vendor/create');
  };

  /**
   * Navigates to individual vendor page
   * 
   * @remarks
   * If missing: Users would not be able to view vendor details
   */
  const handleVendorClick = (vendorId: number) => {
    navigate(`/recommended-vendors/${vendorId}`);
  };

  /**
   * Navigates to vendor edit page
   * 
   * @remarks
   * If missing: Admins would not be able to edit existing vendors
   */
  const handleEditClick = (vendorId: number) => {
    navigate(`/admin/edit-vendor/${vendorId}`);
  };

  return (
    <div className={`flex w-full ${!editMode ? 'h-screen' : ''} overflow-hidden text-white bg-[#1d2025] ${editMode ? '-ml-[3rem] -mt-[3rem]' : ''}`}>
      {!editMode && <Sidebar />}
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-16 lg:p-12 md:p-10 sm:p-8 p-6 flex sm:flex-row flex-col leading-[150%] justify-between items-center bg-black rounded-2xl">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer">
              Recommended Vendors
            </p>
          </div>
          <div className="flex items-center mt-5">
            <PartnerAndVendorSearchAndFilter
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedCategories={selectedCategories}
              handleCategoryRemove={handleCategoryRemove}
              setIsFilterMenuOpen={setIsFilterMenuOpen}
              isFilterMenuOpen={isFilterMenuOpen}
              categorySearchTerm={categorySearchTerm}
              setCategorySearchTerm={setCategorySearchTerm}
              filteredCategories={filteredCategories}
              handleCategorySelect={handleCategorySelect}
              allCategories={categories}
              isAdmin={isAdmin}
              handleCreateItem={handleCreateVendor}
            />
          </div>
          <SelectedCategories
            categories={selectedCategories}
            onRemove={handleCategoryRemove}
          />
          <div className="mt-5">
            {loading && <p className="text-center">Loading...</p>}
            {error && <p className="text-center text-red-500">{error}</p>}
            {!loading && !error && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {filteredVendors.slice(0, visibleVendors).map((vendor: Vendor) => (
                  <VendorCard 
                    key={vendor.id}
                    {...vendor} 
                    onClick={() => handleVendorClick(vendor.id)} 
                    onEdit={() => handleEditClick(vendor.id)}
                    showEditButton={editMode}
                  />
                ))}
              </div>
            )}
            {filteredVendors.length === 0 && !loading && (
              <p className="text-center mt-4">No vendors found</p>
            )}
            {filteredVendors.length > visibleVendors && (
              <div className="flex justify-center mt-4">
                <Button onClick={loadMoreVendors}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default Vendors;