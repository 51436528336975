import React, { useState, useEffect, useRef } from "react";
import LoadingAnimation from "./loadingAnimation";
import supabaseConnect from "../../utils/supabaseConnect";
import { FaUser, FaRobot } from 'react-icons/fa';
const supabase = supabaseConnect();

interface Message {
  text: string;
  sender: "user" | "bot";
  sources?: { title: string; url: string }[];
  showFeedback?: boolean;
}

interface FeedbackState {
  messageIndex: number;
  shown: boolean;
  responded: boolean;
  wasHelpful?: boolean;
}

const filterUniqueSources = (sources: { title: string; url: string }[]) => {
  const seen = new Set();
  return sources.filter(source => {
    // If URL is null, use title as fallback for uniqueness check
    const key = source.url || source.title;
    const isDuplicate = seen.has(key);
    seen.add(key);
    return !isDuplicate;
  });
};

const FeedbackComponent: React.FC<{
  onResponse: (wasHelpful: boolean) => void;
}> = ({ onResponse }) => {
  return (
    <div className="my-2 p-3 rounded-lg bg-gray-700 text-white self-start">
      <p className="mb-2">Did you find what you were looking for?</p>
      <div className="flex gap-2">
        <button
          onClick={() => onResponse(true)}
          className="px-4 py-2 bg-gray-600 hover:bg-communityBlue rounded-lg transition-colors text-white cursor-pointer"
          aria-label="Yes, this was helpful"
        >
          Yes
        </button>
        <button
          onClick={() => onResponse(false)}
          className="px-4 py-2 bg-gray-600 hover:bg-communityBlue rounded-lg transition-colors text-white cursor-pointer"
          aria-label="No, I need more help"
        >
          No
        </button>
      </div>
    </div>
  );
};

interface ChatBotProps {
  onSwitchToSupport: () => void;
}

const ChatBot: React.FC<ChatBotProps> = ({ onSwitchToSupport }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState<any[]>([]); // History state
  const socketRef = useRef<WebSocket | null>(null);
  const [streamedMessage, setStreamedMessage] = useState<string>("");
  let botId = process.env.REACT_APP_AGENT_BOT_ID || "";
  const teamId = process.env.REACT_APP_TEAM_ID || "";
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [feedbackState, setFeedbackState] = useState<FeedbackState>({
    messageIndex: -1,
    shown: false,
    responded: false
  });

  const getUserRole = async () => {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) {
      window.location.href = '/login'
    }

    let profile: any = user;
  
    ({ data: profile } = await supabase
      .from('profile_role')
      .select('*')
      .eq('user_id', user?.id)
      .single());
  
    let roleId = profile?.role_id;

    const { data: role } = await supabase
      .from('role')
      .select('*')
      .eq('id', roleId)
      .single();

    if (role.role_name === 'Staff' || role.role_name === 'Admin'){
      console.log("staff")
      botId = process.env.REACT_APP_STAFF_BOT_ID || "";
    }

    return role?.name;
  };

  useEffect(() => {

    const connectWebSocket = () => {
      const apiUrl = `wss://api.docsbot.ai/teams/${teamId}/bots/${botId}/chat`;
      socketRef.current = new WebSocket(apiUrl);

      socketRef.current.onopen = () => {
        console.log("WebSocket connection established");
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.sender === "bot") {
          if (data.type === "start") {
            setIsLoading(true);
          } else if (data.type === "stream") {
            setMessages((prevMessages) => {
              const lastMessage = prevMessages[prevMessages.length - 1];
              if (lastMessage && lastMessage.sender === "bot") {
                return [
                  ...prevMessages.slice(0, -1),
                  {
                    ...lastMessage,
                    text: lastMessage.text + data.message,
                  },
                ];
              } else {
                setIsLoading(false); 
                return [
                  ...prevMessages,
                  {
                    text: data.message,
                    sender: "bot",
                  },
                ];
              }
            });
          } else if (data.type === "end") {
            const endData = JSON.parse(data.message);
            setMessages((prevMessages) => {
              const lastMessage = prevMessages[prevMessages.length - 1];
              if (lastMessage && lastMessage.sender === "bot") {
                // Filter unique sources before adding them to the message
                const uniqueSources = filterUniqueSources(endData.sources);
                return [
                  ...prevMessages.slice(0, -1),
                  {
                    ...lastMessage,
                    sources: uniqueSources,
                  },
                ];
              } else {
                return prevMessages;
              }
            });
            setHistory([...history, [inputMessage, endData.answer]]);
            setFeedbackState({
              messageIndex: messages.length,
              shown: true,
              responded: false
            });
          }
        } else if (data.type === "error") {
          console.error("Error:", data.message);
          setIsLoading(false);
        }
      };

      socketRef.current.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
        connectWebSocket();
        setIsLoading(false);
      };
    };
    
    const runFunctions = async () => {
      await getUserRole();

      await connectWebSocket();
    }

    runFunctions();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      const userMessage: Message = { text: inputMessage, sender: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputMessage("");

      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        const req = {
          question: inputMessage,
          full_source: true,
          history: history,
          context_items: 5,
        };
        socketRef.current.send(JSON.stringify(req));
      } else {
        console.log("WebSocket is not open. Reconnecting...");
        // Attempt to reconnect immediately
        const apiUrl = `wss://api.docsbot.ai/teams/${teamId}/bots/${botId}/chat`;
        socketRef.current = new WebSocket(apiUrl);
      }
    }
  };

  const renderMessage = (message: Message) => {
    return (
      <div
        className={`my-2 p-3 rounded-lg ${
          message.sender === 'user'
            ? 'bg-communityBlue text-white self-end'
            : 'bg-transparent text-white self-start'
        } max-w-[80%] break-words`}
      >
        <div className="text-sm whitespace-pre-wrap">{message.text}</div>
        {message.sources && message.sources.length > 0 && (
          <div className="mt-2 text-xs">
            <strong className={`${message.sender === 'user' ? 'text-blue-200' : 'text-gray-600'}`}>
              Sources:
            </strong>
            <ul className="list-none mt-1">
              {message.sources.map((source, index) => (
                <li key={index} className="flex items-center mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-3 w-3 mr-1 ${message.sender === 'user' ? 'text-blue-200' : 'text-communityBlue'}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                    />
                  </svg>
                  <a
                    href={source.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${
                      message.sender === 'user' ? 'text-blue-200' : 'text-communityBlue'
                    } hover:underline`}
                  >
                    {source.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const handleFeedback = async (wasHelpful: boolean) => {
    setFeedbackState(prev => ({ 
      ...prev, 
      responded: true,
      wasHelpful
    }));
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Get the last bot message for context
      const lastBotMessage = messages
        .slice()
        .reverse()
        .find(msg => msg.sender === "bot");

      await supabase.from('analytics_events').insert({
        category: 'chatbot',
        action: wasHelpful ? 'helpful' : 'not_helpful',
        label: wasHelpful ? 'helpful' : 'not_helpful',
        value: wasHelpful ? 1 : 0,
        user_id: user?.id,
        metadata: {
          question: messages[messages.length - 2]?.text,
          answer: lastBotMessage?.text,
          sources: lastBotMessage?.sources,
          timestamp: new Date().toISOString()
        }
      });

      if (!wasHelpful) {
        onSwitchToSupport();
      }
    } catch (error) {
      console.error('Error saving feedback:', error);
    }
  };

  return (
    <div className="flex flex-col h-full bg-bakcgroundColor">
      <div className="flex-1 overflow-y-auto p-4">
        <div className="flex flex-col space-y-4">
          {messages.map((message, index) => (
            <React.Fragment key={index}>{renderMessage(message)}</React.Fragment>
          ))}
          {isLoading && (
            <div className="my-2 p-2 rounded-lg bg-gray-700 self-start">
              <LoadingAnimation />
            </div>
          )}
          <div ref={messagesEndRef}></div>
          {feedbackState.shown && (
            <>
              {!feedbackState.responded ? (
                <FeedbackComponent onResponse={handleFeedback} />
              ) : feedbackState.wasHelpful ? (
                <div className="my-2 p-3 rounded-lg bg-gray-700 text-white self-start">
                  <p>Glad we could help! Let us know if you need anything else.</p>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className="p-4 bg-lightBackground">
        <div className="flex items-center">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            className="flex-1 px-4 py-2 mr-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
            placeholder="Type your message..."
          />
          <button
            onClick={handleSendMessage}
            className="bg-[#2d3035] hover:bg-black text-white rounded-full p-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200 cursor-pointer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
