import { useState, useEffect } from 'react';
import supabaseConnect from "../../../utils/supabaseConnect";

interface UseStaffAdminUserReturn {
  user: any;
  isAdmin: boolean;
  isStaff: boolean;
  isLoading: boolean;
  error: Error | null;
}

/**
 * Custom hook to handle staff and admin user permissions in one place
 * 
 * @returns {UseStaffAdminUserReturn} Object containing user data and role information
 * 
 * @example
 * const { user, isAdmin, isStaff, isLoading } = useStaffAdminUser();
 * 
 * @remarks
 * If this hook is missing, the staff/admin section would lack proper permission handling
 * and role-based access control.
 */
export const useStaffAdminUser = (): UseStaffAdminUserReturn => {
  const [user, setUser] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  
  const supabase = supabaseConnect();

  useEffect(() => {
    const checkUserAndRoles = async () => {
      try {
        // Get current user
        const { data: { user: currentUser }, error: userError } = await supabase.auth.getUser();
        
        if (userError) throw userError;
        if (!currentUser) return;

        setUser(currentUser);

        // Get user's role
        const { data: roleData, error: roleError } = await supabase
          .from("profile_role")
          .select("role_id")
          .eq("user_id", currentUser.id)
          .single();

        if (roleError) throw roleError;

        // Set role flags based on role_id
        // Assuming role_id 1 is admin and 2 is staff
        setIsAdmin(roleData?.role_id === 1);
        setIsStaff(roleData?.role_id === 2 || roleData?.role_id === 1); // Admin also has staff privileges

      } catch (err) {
        setError(err as Error);
        console.error('Error checking user roles:', err);
      } finally {
        setIsLoading(false);
      }
    };

    checkUserAndRoles();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(() => {
      checkUserAndRoles();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { user, isAdmin, isStaff, isLoading, error };
};