import React, { useState, useEffect, Suspense, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import MainLayoutWrapper from '../../../components/Layout/MainLayoutWrapper';
import supabaseConnect from '../../../utils/supabaseConnect';
import CountdownOverlay from '../../../components/CountdownOverlay/CountdownOverlay';

const LazyMainEditor = React.lazy(() => import('../../../editor/index'));

interface Partner {
  id: number;
  name: string;
  phone: string;
  email: string;
  logo_url: string;
  description: string;
  info: string;
  website_url: string;
}

const EditPartner: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [partner, setPartner] = useState<Partner | null>(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [description, setDescription] = useState('');
  const [info, setInfo] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [showCountdownOverlay, setShowCountdownOverlay] = useState(false);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{ id: number; name: string }[]>([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const categoryDropdownRef = useRef<HTMLDivElement>(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const supabase = supabaseConnect();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('profile_role')
          .select('role_id')
          .eq('user_id', user.id)
          .single();

        if (data && data.role_id === 1) {
          setIsAdmin(true);
        } else {
          navigate('/');
        }
      }
    };

    

    const fetchPartnerData = async () => {
      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('partners')
        .select('*')
        .eq('id', id)
        .single();
      
      if (error) {
        console.error('Error fetching partner:', error);
        setError('Failed to fetch partner data');
      } else {
        setPartner(data);
        setName(data.name);
        setPhone(data.phone);
        setEmail(data.email);
        setWebsiteUrl(data.website_url);
        setLogoUrl(data.logo_url);
        setDescription(data.description);
        setInfo(data.info);
        setWebsiteUrl(data.website_url || '');
      }
    };

    const fetchCategories = async () => {
      const supabase = supabaseConnect();
      const { data, error } = await supabase
        .from('categories')
        .select('id, name');
      
      if (error) {
        console.error('Error fetching categories:', error);
      } else {
        setCategories(data || []);
      }
    };

    const fetchPartnerCategories = async () => {
      if (id) {
        const supabase = supabaseConnect();
        const { data, error } = await supabase
          .from('partner_categories')
          .select('categories(id, name)')
          .eq('partner_id', id);
        
        if (error) {
          console.error('Error fetching partner categories:', error);
        } else {
          setSelectedCategories(data.flatMap(item => item.categories).map(cat => ({
            id: Number(cat.id),
            name: String(cat.name)
          })));
        }
      }
    };

    checkAdminStatus();
    fetchPartnerData();
    fetchCategories();
    fetchPartnerCategories();
    setEditorLoaded(true);
  }, [id, navigate]);

  const validateForm = () => {
    if (!name || !phone || !email || !description || !info) {
      setError('All fields are required');
      return false;
    }
    if (name === 'N/A' || phone === 'N/A' || email === 'N/A' || description === 'N/A' || info === 'N/A') {
      return true;
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError('Invalid email format');
      return false;
    }
    if (websiteUrl && !/^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})([\/\w \.-]*)*\/?$/.test(websiteUrl)) {
      setError('Invalid website URL format');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    setError(null);
    setSuccess(false);

    const supabase = supabaseConnect();
    
    try {
      const { error } = await supabase
        .from('partners')
        .update({
          name,
          phone,
          email,
          website_url: websiteUrl,
          logo_url: logoUrl,
          description,
          info
        })
        .eq('id', id);

      if (error) throw error;

      // Update partner categories
      await supabase
        .from('partner_categories')
        .delete()
        .eq('partner_id', id);

      const partnerCategories = selectedCategories.map(category => ({
        partner_id: id,
        category_id: category.id
      }));

      const { error: categoriesError } = await supabase
        .from('partner_categories')
        .insert(partnerCategories);

      if (categoriesError) throw categoriesError;

      setSuccess(true);
      setShowConfirmation(false);
      setShowCountdownOverlay(true);
    } catch (error) {
      console.error('Error updating partner:', error);
      setError('Failed to update partner. Please try again.');
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const supabase = supabaseConnect();
      const { data, error } = await supabase.storage
        .from('media')
        .upload(`${Date.now()}_${file.name}`, file);

      if (error) {
        setError('Failed to upload logo');
      } else if (data) {
        const { data: publicURL } = supabase.storage
          .from('media')
          .getPublicUrl(data.path);

        if (publicURL) {
          setLogoUrl(publicURL.publicUrl);
        }
      }
    }
  };

  const handleConfirmSubmit = () => {
    setShowConfirmation(true);

    if (success && countdown === null) {
      setCountdown(3);
    }

    if (countdown !== null && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      window.location.href = `/staff-admin/partners/edit`;
    }
  };

  const handleCancelSubmit = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (success && countdown !== null && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      window.location.href = `/staff-admin/partners/edit`;
    }
  }, [success, countdown]);

  const filteredCategories = useMemo(() => {
    return categories.filter(category => 
      category.name.toLowerCase().includes(categorySearch.toLowerCase()) &&
      !selectedCategories.some(selected => selected.id === category.id)
    );
  }, [categories, categorySearch, selectedCategories]);

  const handleCategorySelect = (category: { id: number; name: string }) => {
    setSelectedCategories([...selectedCategories, category]);
    setCategorySearch('');
  };

  const handleCategoryRemove = (categoryId: number) => {
    setSelectedCategories(selectedCategories.filter(cat => cat.id !== categoryId));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target as Node)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    const supabase = supabaseConnect();
    
    try {
      // First delete related categories
      await supabase
        .from('partner_categories')
        .delete()
        .eq('partner_id', id);

      // Then delete the partner
      const { error } = await supabase
        .from('partners')
        .delete()
        .eq('id', id);

      if (error) throw error;

      // Redirect to partners list
      window.location.href = '/staff-admin/partners/edit';
    } catch (error) {
      console.error('Error deleting partner:', error);
      setError('Failed to delete partner. Please try again.');
    }
  };

  if (!isAdmin) return null;

  return (
    <div className="flex w-full h-full overflow-hidden text-white">
      {/* <Sidebar /> */}
      <MainLayoutWrapper>
        <div className="p-3 max-w-3xl">
          <h1 className="text-2xl font-bold mb-4">Edit Community Partner</h1>
          <form onSubmit={handleSubmit} className="space-y-5 flex flex-col">
            <div>
              <label htmlFor="name" className="block mb-2">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block mb-2">Phone</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
                required
              />
            </div>
            <div>
              <label htmlFor="website" className="block mb-2">Website</label>
              <input
                type="url"
                id="website"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
              />
            </div>
            <div>
              <label htmlFor="logo" className="block mb-2">Logo</label>
              <input
                type="file"
                id="logo"
                accept="image/*"
                onChange={handleLogoUpload}
                className="pb-3"
              />
              {logoUrl && (
                <img src={logoUrl} alt="Partner logo" className="mt-2 h-20 object-contain" />
              )}
            </div>
            <div>
              <label htmlFor="description" className="block mb-2">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg resize-vertical border-solid border-[.2px] border-lightGray"
                rows={1}
                style={{
                  resize: 'none',
                }}
                required
              ></textarea>
            </div>
            <div className="relative" ref={categoryDropdownRef}>
              <label className="block mb-2">Categories</label>
              <div className="flex flex-wrap gap-2 mb-2">
                {selectedCategories.map(category => (
                  <div key={category.id} className="flex items-center bg-gray-700 rounded-full px-3 py-1">
                    <span>{category.name}</span>
                    <button
                      type="button"
                      onClick={() => handleCategoryRemove(category.id)}
                      className="ml-2 focus:outline-none text-sm font-bold"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="text"
                placeholder="Search categories..."
                value={categorySearch}
                onChange={(e) => setCategorySearch(e.target.value)}
                onClick={() => setShowCategoryDropdown(true)}
                className="w-full pb-3 bg-gray-800 rounded text-white text-lg border-solid border-[.2px] border-lightGray"
              />
              {showCategoryDropdown && (
                <div className="absolute z-10 w-full mt-1 bg-gray-700 border border-gray-600 rounded-md shadow-lg max-h-60 overflow-y-auto">
                  {filteredCategories.map(category => (
                    <div
                      key={category.id}
                      className="px-4 py-2 hover:bg-gray-600 cursor-pointer"
                      onClick={() => handleCategorySelect(category)}
                    >
                      {category.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="info" className="block mb-2">Detailed Information</label>
              <div className="w-full">
                {editorLoaded ? (
                  <Suspense fallback={<div>Loading editor...</div>}>
                    <LazyMainEditor editor={info} setEditor={setInfo} body={info} />
                  </Suspense>
                ) : (
                  <textarea
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                    className="w-full pb-3 bg-gray-800 rounded text-white text-lg resize-vertical border-solid border-[.2px] border-lightGray"
                    rows={10}
                  ></textarea>
                )}
              </div>
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {success && (
              <div className="text-green-500">
                <p>Partner updated successfully!</p>
                {countdown !== null && (
                  <p>Redirecting in {countdown} seconds...</p>
                )}
              </div>
            )}
            <div className="flex gap-4">
              {showConfirmation ? (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                  <div className="bg-gray-800 p-4 rounded max-w-sm w-full">
                    <p>Are you sure you want to update this partner?</p>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button 
                        onClick={handleSubmit} 
                        className="bg-gray-800 hover:bg-communityBlue text-white px-6 py-2 rounded w-fit cursor-pointer"
                      >
                        Confirm
                      </button>
                      <button 
                        onClick={handleCancelSubmit} 
                        className="bg-gray-800 hover:bg-red-600 text-white px-6 py-2 rounded w-fit cursor-pointer"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex gap-4">
                  <button 
                    type="button"
                    onClick={handleConfirmSubmit} 
                    className="bg-gray-800 hover:bg-communityBlue text-white px-6 py-2 rounded w-fit cursor-pointer"
                  >
                    Update Partner
                  </button>
                  <button 
                    type="button"
                    onClick={isConfirmingDelete ? handleDelete : () => setIsConfirmingDelete(true)}
                    className="bg-gray-800 hover:bg-red-600 text-white px-6 py-2 rounded w-fit cursor-pointer"
                  >
                    {isConfirmingDelete ? 'Confirm Delete' : 'Delete Partner'}
                  </button>
                </div>
              )}
            </div>
          </form>
          {showCountdownOverlay && (
            <CountdownOverlay
              initialCountdown={3}
              onComplete={() => window.location.href = `/staff-admin/partners/edit`}
            />
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default EditPartner;