import React, { useState, useEffect } from 'react';
import ChatBot from '.';
import ChatbotInterface from './ChatbotInterface';
import SupportForm from './SupportForm';
import { BsDashLg } from 'react-icons/bs';

interface ChatbotBubbleProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ChatbotBubble: React.FC<ChatbotBubbleProps> = ({ isOpen, setIsOpen }) => {
  const [selectedOption, setSelectedOption] = useState<'home' | 'ai' | 'support' | 'docs'>('home');

  useEffect(() => {
    if (isOpen) {
      setSelectedOption('home');
    }
  }, [isOpen]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSelectedOption('home');
    }
  };

  const handleSelectOption = (option: 'ai' | 'support' | 'docs') => {
    setSelectedOption(option);
  };

  const handleBack = () => {
    setSelectedOption('home');
  };

  const scrollbarStyle = {
    scrollbarWidth: 'thin' as 'thin',
    scrollbarColor: '#4A5568 #2D3748',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#2D3748',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#4A5568',
      borderRadius: '4px',
    },
  };

  const chatInterfaceStyle = {
    border: '.5px solid #4A5568',
    transformOrigin: 'bottom center',
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'ai':
        return <ChatBot />;
      case 'support':
        return <SupportForm />;
      case 'docs':
        return <div className="p-4 text-gray-300">Help documentation goes here.</div>;
      default:
        return (
          <ChatbotInterface
            onSelectOption={handleSelectOption}
            showBackButton={selectedOption !== 'home'}
            onBack={handleBack}
          />
        );
    }
  };

  return (
    <div className="fixed bottom-4 right-6 z-50 md:bottom-4 md:right-6">
      <div 
        className={`bg-gray-900 rounded-lg shadow-xl md:w-96 md:h-[600px] w-full h-full fixed md:absolute overflow-hidden flex flex-col transition-all duration-300 ease-in-out ${
          isOpen 
            ? 'opacity-100 scale-100 inset-0 md:inset-auto md:bottom-16 md:right-0' 
            : 'opacity-0 scale-95 inset-0 md:inset-auto md:bottom-16 md:right-0 pointer-events-none'
        }`}
        style={chatInterfaceStyle}
      >
        <div className="flex justify-between items-center p-4 bg-gray-800 text-gray-100">
          <div className="flex items-center">
            {selectedOption !== 'home' && (
              <div
                onClick={handleBack}
                className="mr-3 text-gray-100 hover:text-blue-400 transition-colors duration-200 cursor-pointer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
              </div>
            )}
            <svg className="w-6 h-6 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z" fill="currentColor"/>
            </svg>
            <h3 className="text-lg font-semibold">Chat</h3>
          </div>
          <div
            onClick={toggleChat}
            className="text-gray-100 p-1 transition-all duration-200 cursor-pointer hover:scale-110"
          >
            <BsDashLg />
          </div>
        </div>
        <div className="flex-grow overflow-hidden" style={scrollbarStyle}>
          {renderContent()}
        </div>
      </div>
      <button
        onClick={toggleChat}
        className={`${
          isOpen ? 'bg-gray-800 hover:bg-[#099aad]' : 'bg-gray-800 hover:bg-[#099aad]'
        } text-gray-100 rounded-full p-3 shadow-lg transition-all duration-300 ease-in-out transform hover:scale-110 cursor-pointer absolute bottom-0 right-0 md:bottom-0 md:right-0 ${
          isOpen ? 'hidden md:block' : 'block'
        }`}
      >
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
          </svg>
        )}
      </button>
    </div>
  );
};

export default ChatbotBubble;
