import React from 'react';

interface MainLayoutWrapperProps {
  children: React.ReactNode;
}

const MainLayoutWrapper: React.FC<MainLayoutWrapperProps> = ({ children }) => {
  return (
    <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray p-4" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }} >
      {children}
    </main>
  );
};

export default MainLayoutWrapper;