import React, { useState, useEffect } from 'react';
import { ConfigurationPanel } from './shared/ConfigurationPanel';
import { FormField } from './shared/FormField';
import { ScheduleTriggerConfig as ConfigType } from './types';
import { validateScheduleTrigger } from '../utils/validation';

interface ScheduleTriggerConfigProps {
  config: ConfigType;
  onSave: (config: ConfigType) => void;
  onClose: () => void;
}

export const ScheduleTriggerConfig: React.FC<ScheduleTriggerConfigProps> = ({
  config: initialConfig,
  onSave,
  onClose,
}) => {
  const [config, setConfig] = useState<ConfigType>(initialConfig);
  const [validation, setValidation] = useState(validateScheduleTrigger(initialConfig));

  useEffect(() => {
    setValidation(validateScheduleTrigger(config));
  }, [config]);

  const handleSave = () => {
    if (validation.isValid) {
      onSave(config);
    }
  };

  const baseInputStyles = "block w-full p-2 bg-[#2a2828] text-white rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500";
  const dateTimeStyles = `${baseInputStyles} w-full sm:max-w-[200px] appearance-none [&::-webkit-calendar-picker-indicator]:opacity-70 [&::-webkit-calendar-picker-indicator]:filter [&::-webkit-calendar-picker-indicator]:invert [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-clear-button]:appearance-none`;

  return (
    <ConfigurationPanel
      title="Schedule Trigger Configuration"
      onClose={onClose}
      onSave={handleSave}
      isValid={validation.isValid}
    >
      <div className="space-y-4 w-full max-w-full overflow-hidden">
        <FormField
          label="Frequency"
          required
          error={validation.errors.frequency?.[0]}
        >
          <select
            value={config.schedule.frequency}
            onChange={(e) =>
              setConfig({
                ...config,
                schedule: { ...config.schedule, frequency: e.target.value as any },
              })
            }
            className={`${baseInputStyles} sm:max-w-[300px]`}
          >
            <option value="once">Once</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </FormField>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <FormField
            label="Time"
            required
            error={validation.errors.time?.[0]}
          >
            <div className="w-full overflow-hidden">
              <input
                type="time"
                value={config.schedule.time}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    schedule: { ...config.schedule, time: e.target.value },
                  })
                }
                className={dateTimeStyles}
                style={{ colorScheme: 'dark' }}
              />
            </div>
          </FormField>

          <FormField
            label="Start Date"
            required
            error={validation.errors.startDate?.[0]}
          >
            <div className="w-full overflow-hidden">
              <input
                type="date"
                value={config.schedule.startDate}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    schedule: { ...config.schedule, startDate: e.target.value },
                  })
                }
                className={dateTimeStyles}
                style={{ colorScheme: 'dark' }}
              />
            </div>
          </FormField>
        </div>

        {config.schedule.frequency !== 'once' && (
          <FormField
            label="End Date (Optional)"
            error={validation.errors.endDate?.[0]}
          >
            <div className="w-full overflow-hidden">
              <input
                type="date"
                value={config.schedule.endDate || ''}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    schedule: { ...config.schedule, endDate: e.target.value },
                  })
                }
                className={dateTimeStyles}
                style={{ colorScheme: 'dark' }}
              />
            </div>
          </FormField>
        )}

        {config.schedule.frequency === 'weekly' && (
          <FormField
            label="Days of Week"
            required
            error={validation.errors.daysOfWeek?.[0]}
          >
            <div className="flex flex-wrap gap-2">
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                <button
                  key={day}
                  type="button"
                  onClick={() => {
                    const daysOfWeek = config.schedule.daysOfWeek || [];
                    const newDays = daysOfWeek.includes(index)
                      ? daysOfWeek.filter((d) => d !== index)
                      : [...daysOfWeek, index];
                    setConfig({
                      ...config,
                      schedule: { ...config.schedule, daysOfWeek: newDays },
                    });
                  }}
                  className={`flex-1 sm:flex-none px-3 py-1 rounded-md ${
                    config.schedule.daysOfWeek?.includes(index)
                      ? 'bg-communityBlue text-white'
                      : 'bg-[#2a2828] text-gray-300'
                  }`}
                >
                  {day}
                </button>
              ))}
            </div>
          </FormField>
        )}

        {config.schedule.frequency === 'monthly' && (
          <FormField
            label="Day of Month"
            required
            error={validation.errors.dayOfMonth?.[0]}
          >
            <select
              value={config.schedule.dayOfMonth || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  schedule: {
                    ...config.schedule,
                    dayOfMonth: parseInt(e.target.value),
                  },
                })
              }
              className={`${baseInputStyles} sm:max-w-[300px]`}
            >
              <option value="">Select day</option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </FormField>
        )}
      </div>
    </ConfigurationPanel>
  );
}; 