import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  ReactFlowProvider,
  Node,
  Edge,
  Connection,
  addEdge,
  useReactFlow,
  NodeChange,
  EdgeChange,
  useKeyPress,
  SelectionMode,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { NodeSelector } from './NodeSelector';
import { CustomNode } from './CustomNode';
import { WorkflowToolbar } from './WorkflowToolbar';
import { NodeConfigModal } from './NodeConfig/NodeConfigModal';
import { BaseNodeConfig, ScheduleTriggerConfig, DatabaseTriggerConfig, EmailActionConfig } from './NodeConfig/types';
import { validateNodeConfig } from './utils/validation';

// Define node types
const nodeTypes = {
  customNode: CustomNode,
};

type FrequencyType = 'once' | 'daily' | 'weekly' | 'monthly';
type DatabaseEventType = 'insert' | 'update' | 'delete';
export type NodeType = 'schedule' | 'database' | 'email';
type RecipientType = 'static' | 'dynamic';
type CustomNodeType = 'customNode';

interface BaseNodeData {
  id: string;
  type: NodeType;
  label: string;
  isConfigured: boolean;
  onConfigClick?: () => void;
}

interface ScheduleTriggerData extends BaseNodeData {
  type: 'schedule';
  schedule: {
    frequency: FrequencyType;
    time: string;
    startDate: string;
  };
}

interface DatabaseTriggerData extends BaseNodeData {
  type: 'database';
  table: string;
  event: DatabaseEventType;
  conditions: any[];
}

interface EmailActionData extends BaseNodeData {
  type: 'email';
  templateId: string;
  subject: string;
  recipients: {
    type: RecipientType;
    value: string[];
  };
}

type NodeData = ScheduleTriggerData | DatabaseTriggerData | EmailActionData;

interface CustomNode extends Node<NodeData> {
  type: CustomNodeType;
}

interface WorkflowDesignerProps {
  workflowId?: string;
}

interface NodeSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectNode: (nodeType: NodeType) => void;
  filterCategory?: 'triggers' | 'actions';
  title?: string;
}

// Helper function to create initial node data
const createInitialNodeData = (type: NodeType, id: string, label: string): NodeData => {
  const baseData = {
    id,
    type,
    label,
    isConfigured: false,
  };

  switch (type) {
    case 'schedule':
      return {
        ...baseData,
        type: 'schedule',
        schedule: {
          frequency: 'once',
          time: '',
          startDate: '',
        },
      };
    case 'database':
      return {
        ...baseData,
        type: 'database',
        table: '',
        event: 'insert',
        conditions: [],
      };
    case 'email':
      return {
        ...baseData,
        type: 'email',
        templateId: '',
        subject: '',
        recipients: {
          type: 'static',
          value: [],
        },
      };
    default:
      throw new Error(`Unsupported node type: ${type}`);
  }
};

// Helper function to update node data while preserving type information
const updateNodeData = (node: CustomNode): CustomNode => {
  const currentData = node.data;
  const updates = { isConfigured: !currentData.isConfigured };
  
  switch (currentData.type) {
    case 'schedule':
      return {
        ...node,
        data: {
          ...currentData,
          ...updates,
        },
      };
    case 'database':
      return {
        ...node,
        data: {
          ...currentData,
          ...updates,
        },
      };
    case 'email':
      return {
        ...node,
        data: {
          ...currentData,
          ...updates,
        },
      };
    default:
      throw new Error(`Unsupported node type: ${(currentData as NodeData).type}`);
  }
};

// Default nodes and edges
const defaultNodes: CustomNode[] = [
  {
    id: 'trigger-1',
    type: 'customNode',
    position: { x: 250, y: 100 },
    data: createInitialNodeData('schedule', 'trigger-1', 'Trigger'),
    dragHandle: '.node-drag-handle',
  },
  {
    id: 'action-1',
    type: 'customNode',
    position: { x: 250, y: 250 },
    data: createInitialNodeData('email', 'action-1', 'Action'),
    dragHandle: '.node-drag-handle',
  },
];

const defaultEdges: Edge[] = [
  {
    id: 'edge-1',
    source: 'trigger-1',
    target: 'action-1',
    type: 'smoothstep',
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
];

// Create a new component for the flow content
const FlowContent: React.FC<{
  nodes: CustomNode[];
  edges: Edge[];
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  onConnect: (connection: Connection) => void;
  onNodeClick: (event: React.MouseEvent, node: Node) => void;
  onNodeConfigClick: (node: CustomNode) => void;
  onNodesDelete: (nodes: CustomNode[]) => void;
}> = ({ 
  nodes, 
  edges, 
  onNodesChange, 
  onEdgesChange, 
  onConnect, 
  onNodeClick,
  onNodeConfigClick, 
  onNodesDelete 
}) => {
  const { project } = useReactFlow();
  const deletePressed = useKeyPress('Delete');
  const backspacePressed = useKeyPress('Backspace');

  // Handle keyboard deletion
  useEffect(() => {
    if (deletePressed || backspacePressed) {
      const selectedNodes = nodes.filter(node => node.selected);
      if (selectedNodes.length > 0) {
        onNodesDelete(selectedNodes);
      }
    }
  }, [deletePressed, backspacePressed, nodes, onNodesDelete]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onNodeClick={onNodeClick}
      nodeTypes={nodeTypes}
      fitView
      className="bg-[#1a1a1a]"
      selectionMode={SelectionMode.Partial}
      selectNodesOnDrag={false}
    >
      <Background />
      <Controls />
    </ReactFlow>
  );
};

export const WorkflowDesigner: React.FC<WorkflowDesignerProps> = ({ workflowId }) => {
  const [nodes, setNodes] = useState<CustomNode[]>(defaultNodes);
  const [edges, setEdges] = useState<Edge[]>(defaultEdges);
  const [isNodeSelectorOpen, setIsNodeSelectorOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<CustomNode | null>(null);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  
  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  // Initialize nodes with config click handlers
  useEffect(() => {
    setNodes(nodes => nodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        onConfigClick: () => onNodeConfigClick(node),
      },
    })));
  }, []);

  // Handle node changes (position, selection, etc.)
  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((nds) => {
      return changes.reduce((acc, change) => {
        if (change.type === 'position' && change.dragging) {
          // Update node position while dragging
          return acc.map((node) => {
            if (node.id === change.id) {
              return {
                ...node,
                position: change.position || node.position,
              };
            }
            return node;
          });
        } else if (change.type === 'select') {
          // Update node selection state
          return acc.map((node) => {
            if (node.id === change.id) {
              return {
                ...node,
                selected: change.selected,
              };
            }
            return node;
          });
        }
        return acc;
      }, [...nds]);
    });
  }, []);

  // Handle edge changes
  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((eds) => {
      return changes.reduce((acc, change) => {
        if (change.type === 'remove') {
          return acc.filter((edge) => edge.id !== change.id);
        }
        return acc;
      }, [...eds]);
    });
  }, []);

  // Handle node deletion
  const onNodesDelete = useCallback((nodesToDelete: CustomNode[]) => {
    // Remove the nodes
    setNodes((nds) => nds.filter((node) => !nodesToDelete.find((n) => n.id === node.id)));
    
    // Remove any connected edges
    const nodeIds = nodesToDelete.map((node) => node.id);
    setEdges((eds) => 
      eds.filter((edge) => !nodeIds.includes(edge.source) && !nodeIds.includes(edge.target))
    );
  }, []);

  // Handle new connections between nodes
  const onConnect = useCallback((connection: Connection) => {
    // Validate connection
    const sourceNode = nodes.find((n) => n.id === connection.source);
    const targetNode = nodes.find((n) => n.id === connection.target);

    if (!sourceNode || !targetNode) return;

    // Prevent multiple connections to the same target
    const existingConnection = edges.find((e) => e.target === connection.target);
    if (existingConnection) {
      setErrors((prev) => ({
        ...prev,
        connections: ['Node already has an incoming connection'],
      }));
      return;
    }

    setEdges((eds) => addEdge(connection, eds));
  }, [nodes, edges]);

  // Handle node selection
  const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
    const customNode = node as CustomNode;
    // If it's a default placeholder node, open replacement modal
    if (customNode.id === 'trigger-1' || customNode.id === 'action-1') {
      setSelectedNode(customNode);
      setIsReplacementModalOpen(true);
    } else {
      // For other nodes, just select them
      setSelectedNode(customNode);
    }
  }, []);

  // Handle node configuration
  const onNodeConfigClick = useCallback((node: CustomNode) => {
    const nodeConfig = createInitialConfig(node.data.type as NodeType, node.id);
    setSelectedNode({
      ...node,
      data: {
        ...nodeConfig,
        isConfigured: node.data.isConfigured,
        onConfigClick: node.data.onConfigClick,
      },
    });
    setIsConfigModalOpen(true);
  }, []);

  // Create initial node configuration based on type
  const createInitialConfig = (nodeType: NodeType, id: string): NodeData => {
    const baseData = {
      id,
      isConfigured: false,
    };

    switch (nodeType) {
      case 'schedule':
        return {
          ...baseData,
          type: 'schedule' as const,
          label: 'Schedule Trigger',
          schedule: {
            frequency: 'once' as FrequencyType,
            time: '',
            startDate: '',
          },
        };
      case 'database':
        return {
          ...baseData,
          type: 'database' as const,
          label: 'Database Trigger',
          table: '',
          event: 'insert' as DatabaseEventType,
          conditions: [],
        };
      case 'email':
        return {
          ...baseData,
          type: 'email' as const,
          label: 'Send Email',
          templateId: '',
          subject: '',
          recipients: {
            type: 'static' as RecipientType,
            value: [],
          },
        };
      default:
        throw new Error(`Unknown node type: ${nodeType}`);
    }
  };

  // Handle node replacement
  const handleNodeReplace = useCallback((nodeType: NodeType) => {
    if (!selectedNode) return;

    const config = createInitialConfig(nodeType, selectedNode.id);
    
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNode.id) {
          const updatedNode: CustomNode = {
            ...node,
            data: {
              ...config,
              isConfigured: false,
              onConfigClick: () => onNodeConfigClick(node),
            },
          };
          return updatedNode;
        }
        return node;
      })
    );

    setIsReplacementModalOpen(false);
    setSelectedNode(null);
  }, [selectedNode, onNodeConfigClick]);

  // Add new node to the canvas
  const handleAddNode = useCallback((nodeType: NodeType) => {
    const id = `node-${nodes.length + 1}`;
    const config = createInitialConfig(nodeType, id);
    
    const newNode: CustomNode = {
      id,
      type: 'customNode',
      position: { x: 100, y: 100 },
      data: {
        ...config,
        isConfigured: false,
        onConfigClick: () => onNodeConfigClick(newNode),
      },
      dragHandle: '.node-drag-handle',
    };

    setNodes((prevNodes) => [...prevNodes, newNode]);
    setSelectedNode(newNode);
    setIsNodeSelectorOpen(false);
  }, [nodes, onNodeConfigClick]);

  // Handle node configuration updates
  const handleConfigSave = useCallback((config: BaseNodeConfig) => {
    const validationResult = validateNodeConfig(config);
    if (!validationResult.isValid) {
      setErrors(validationResult.errors);
      return;
    }

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === config.id) {
          return {
            ...node,
            data: {
              ...config,
              isConfigured: true,
              onConfigClick: node.data.onConfigClick,
            },
          } as CustomNode;
        }
        return node;
      })
    );

    setErrors({});
    setIsConfigModalOpen(false);
    setSelectedNode(null);
  }, []);

  // Save workflow
  const handleSaveWorkflow = async () => {
    // Validate all nodes are configured
    const unconfiguredNodes = nodes.filter((node) => !node.data.isConfigured);
    if (unconfiguredNodes.length > 0) {
      setErrors({
        workflow: ['All nodes must be configured before saving'],
      });
      return;
    }

    // Validate workflow has at least one trigger and one action
    const hasTrigger = nodes.some((node) => 
      ['schedule', 'database'].includes(node.data.type)
    );
    const hasAction = nodes.some((node) => 
      node.data.type === 'email'
    );

    if (!hasTrigger || !hasAction) {
      setErrors({
        workflow: ['Workflow must have at least one trigger and one action'],
      });
      return;
    }

    // TODO: Save workflow to database
  };

  return (
    <ReactFlowProvider>
      <div className="flex flex-col h-full bg-[#1a1a1a] rounded-lg overflow-hidden my-4">
        <WorkflowToolbar 
          onAddNode={() => setIsNodeSelectorOpen(true)} 
          onSave={handleSaveWorkflow}
        />
        
        {Object.keys(errors).length > 0 && (
          <div className="mx-4 my-2">
            <div className="p-4 bg-red-500/10 border border-red-500 rounded-md">
              {Object.entries(errors).map(([key, messages]) => (
                <div key={key}>
                  {messages.map((message, i) => (
                    <p key={i} className="text-red-400 text-sm">{message}</p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
        
        <div ref={reactFlowWrapper} className="flex-1 min-h-0 w-full">
          <FlowContent
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={onNodeClick}
            onNodeConfigClick={onNodeConfigClick}
            onNodesDelete={onNodesDelete}
          />
        </div>

        {/* Node selector for adding new nodes */}
        <NodeSelector
          isOpen={isNodeSelectorOpen}
          onClose={() => setIsNodeSelectorOpen(false)}
          onSelectNode={handleAddNode}
        />

        {/* Node selector for replacing existing nodes */}
        <NodeSelector
          isOpen={isReplacementModalOpen}
          onClose={() => {
            setIsReplacementModalOpen(false);
            setSelectedNode(null);
          }}
          onSelectNode={handleNodeReplace}
          filterCategory={selectedNode?.id === 'trigger-1' ? 'triggers' : selectedNode?.id === 'action-1' ? 'actions' : undefined}
          title={`Select ${selectedNode?.data.label}`}
        />

        {/* Configuration modal */}
        {selectedNode && (
          <NodeConfigModal
            isOpen={isConfigModalOpen}
            onClose={() => {
              setIsConfigModalOpen(false);
              setSelectedNode(null);
            }}
            nodeType={selectedNode.data.type}
            config={selectedNode.data}
            onSave={handleConfigSave}
          />
        )}
      </div>
    </ReactFlowProvider>
  );
}; 