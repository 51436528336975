import { FunctionComponent, useState } from "react";
import { ResourcePages, TrainingPages } from "../../../assets";
import { FaChevronDown } from 'react-icons/fa'; // Import FaChevronDown from react-icons

interface QuickLinksProps {
  handleREACTrainingCoursesClick: () => void;
  handleResourcePageClick: () => void;
}

const QuickLinks: FunctionComponent<QuickLinksProps> = ({
  handleREACTrainingCoursesClick,
  handleResourcePageClick,
}) => {
  const [showRealDropdown, setShowRealDropdown] = useState(false);
  const [showCommunityDropdown, setShowCommunityDropdown] = useState(false);

  const realLinks = [
    { name: "REAL Workvivo", url: "https://onereal.workvivo.us/login" },
    { name: "REAL Marketing Center", url: "https://marketing.onereal.com/" },
    { name: "REAL Partners", url: "https://onereal.widencollective.com/portals/taqiemso/Partners" },
    { name: "REAL Design Center", url: "https://www.realdesigncenter.com/" },
    { name: "ONE REAL Mortgage", url: "https://www.onerealmortgage.com/" },
    { name: "REAL Academy", url: "https://www.real.academy/" },
    { name: "REAL Colorado Portal", url: "https://onereal.widencollective.com/portals/lkukhxmv/COPortal" },
    { name: "reZEN", url: "https://bolt.therealbrokerage.com/" },
  ];

  const communityLinks = [
    { name: "Follow Up Boss", url: "https://www.followupboss.com/" },
    { name: "Sisu", url: "https://my.sisu.co/" },
    { name: "CTM", url: "https://www.ctmecontracts.com/" },
    { name: "ReColorado", url: "https://recolorado.com/" },
    { name: "Real Scout", url: "https://www.realscout.com/" },
    { name: "Gusto", url: "https://gusto.com/" },
    { name: "IRGC Portal", url: "https://www.irgcsolutions.com/community-portal/" },
    { name: "Zillow Premier Agent", url: "https://www.zillow.com/premier-agent/" },
    { name: "Proof Over Promise Library", url: "https://drive.google.com/drive/folders/1vR6lhenlDCSCTRrNa8raEXdauPSDvI6q?usp=drive_link" },
  ];

  const renderDropdown = (links: { name: string; url: string }[], show: boolean) => (
    <div 
      className={`overflow-hidden transition-all duration-300 ease-in-out ${
        show ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
      }`}
    >
      <div className="rounded-md shadow-lg mt-2">
        {links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              borderTop: ".5px solid #099aad",
            }}
            className="block px-4 py-2 text-sm text-white hover:bg-[#099aad] no-underline"
          >
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <div className="md:min-h-[29rem] min-h-80 border rounded-lg text-base bg-black p-4">
      <h2
        style={{
          borderBottom: "2px solid #099aad",
        }}
        className="text-white font-thin max-lg:m-0 pb-[1rem] mb-2"
      >
        Quick Links
      </h2>
      <div className="block pb-[1rem] h-[23rem] overflow-y-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }} >
        <div className="relative cursor-pointer pt-2 pb-2">
          <p 
            className="m-0 font-semibold hover:text-[#099aad] flex items-center justify-between"
            onClick={() => setShowCommunityDropdown(!showCommunityDropdown)}
          >
            Community
            <FaChevronDown className={`w-4 h-4 transition-transform duration-300 ${showCommunityDropdown ? 'transform rotate-180' : ''}`} />
          </p>
          {renderDropdown(communityLinks, showCommunityDropdown)}
        </div>
        <div
          style={{
            borderBottom: "2px solid #099aad",
          }}
          className="pb-[1rem] mb-2"
        />
        <div className="relative cursor-pointer pt-2 pb-2">
          <p 
            className="m-0 font-semibold hover:text-[#099aad] flex items-center justify-between"
            onClick={() => setShowRealDropdown(!showRealDropdown)}
          >
            REAL
            <FaChevronDown className={`w-4 h-4 transition-transform duration-300 ${showRealDropdown ? 'transform rotate-180' : ''}`} />
          </p>
          {renderDropdown(realLinks, showRealDropdown)}
        </div>
        <div
          style={{
            borderBottom: "2px solid #099aad",
          }}
          className="pb-[1rem] mb-2"
        />
        <div
          className="relative cursor-pointer py-2 pb-[2rem] hover:text-[#099aad]"
          style={{
            borderBottom: "2px solid #099aad",
          }}
          onClick={handleREACTrainingCoursesClick}
        >
          <p className="m-0 font-semibold">REAC Training Courses</p>
        </div>
        <div
          className="relative cursor-pointer py-2 pb-[2rem] hover:text-[#099aad]"
          style={{
            borderBottom: "2px solid #099aad",
          }}
          onClick={handleResourcePageClick}
        >
          <p className="m-0 font-semibold">Resources</p>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
