import React from "react";
import { Skeleton } from "./ui/skeleton";

const ProfileSkeleton: React.FC = () => {
  return (
    <div className="flex items-center space-x-4 p-4">
      <Skeleton className="h-10 w-10 rounded-full" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[150px]" />
        <Skeleton className="h-3 w-[100px]" />
      </div>
    </div>
  );
};

export default ProfileSkeleton;
