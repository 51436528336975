import { useState, useEffect } from 'react';
import supabaseConnect from "../../../utils/supabaseConnect";

/**
 * Custom hook to check if the current user has admin privileges.
 * 
 * @param {string | undefined} userId - The ID of the current user
 * @returns {Object} An object indicating whether the user is an admin
 * @property {boolean} isAdmin - True if the user is an admin, false otherwise
 * 
 * @example
 * const { isAdmin } = useAdmin(currentUserId);
 * 
 * @remarks
 * If this hook is missing, the application would not be able to determine user roles,
 * potentially exposing admin-only features to regular users or hiding necessary admin
 * functionality from administrators.
 */
export const useAdmin = (userId: string | undefined) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const supabase = supabaseConnect();

  useEffect(() => {
    const checkAdmin = async () => {
      if (!userId) return;

      const { data, error } = await supabase
        .from("profile_role")
        .select("*")
        .eq("user_id", userId)
        .limit(1);

      if (error) {
        console.log("error", error);
        return;
      }

      setIsAdmin(data?.[0]?.role_id === 1);
    };

    checkAdmin();
  }, [userId]);

  return { isAdmin };
};