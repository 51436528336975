import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { magnifyingglass } from "../../assets";
import CardGrid from "./SearchCard";
import supabaseConnect from "../../utils/supabaseConnect";
import MainLayoutWrapper from "../../components/Layout/MainLayoutWrapper";
import ChatbotBubble from "../../components/Chatbot/ChatbotBubble";
import { trackEvent } from "../../utils/analytics";
import Button from "../../components/Button/Button";
import { newCalculateRelevanceScore } from "../../utils/searchUtils";

/**
 * SearchResults component
 * Renders the search results page with search functionality and result display
 * @returns {JSX.Element} The SearchResults component
 * @breaks Without this component, the entire search functionality and results display would be missing
 */
const SearchResults = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const supabase = supabaseConnect();
  const [data, setData] = useState([]) as any[];
  const [userRole, setUserRole] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [displayedItems, setDisplayedItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  /**
   * Effect hook to initialize search term and user permissions
   * @breaks Without this, the initial search term won't be set from URL and user permissions won't be fetched
   */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryFromUrl = queryParams.get('q') || "";
    setSearchTerm(queryFromUrl);

    const getUserPerms = async () => {
      let authUser;
      const { data: { user } } = await supabase.auth.getUser()
      console.log(user);
      authUser = user;

      const { data: profileRoleData, error: profileRoleDataError } = await supabase
        .from('profile_role')
        .select('*')
        .eq('user_id', authUser?.id)
        .single();
      console.log(profileRoleData);

      if (profileRoleDataError) console.log("error", profileRoleDataError);

      const { data: roledata, error: roledataError } = await supabase
        .from('role')
        .select('*')
        .eq('id', profileRoleData?.role_id)
        .single();
      console.log(roledata);

      if (roledataError) console.log("error", roledataError);

      setUserRole(roledata?.role_name);

      return roledata?.role_name;

    }

    getUserPerms()
    search();
  }, [location.search]);

  /**
   * Effect hook to trigger search when search term changes
   * @breaks Without this, changes to the search term won't automatically trigger a new search
   */
  useEffect(() => {
    if (searchTerm) {
      search().then((results) => {
        setData(results);
        setDisplayedItems(results.slice(0, itemsPerPage));
        setCurrentPage(1);
      });
    }
  }, [searchTerm]);

  /**
   * Fetches search results from a specific table and column
   * @param {string} table - The table to search in
   * @param {string} column - The column to search in
   * @param {string} searchTerm - The term to search for
   * @returns {Promise<any>} The search results
   * @breaks Without this, the search functionality for specific tables would not work
   */
  const fetchResults = async (table: string, column: string, searchTerm: string) => {
    return await supabase
      .from(table)
      .select("*")
      .textSearch(column, searchTerm || "", { type: "websearch" });
  };
  
  /**
   * Adds table name to search results
   * @param {any} results - The search results to process
   * @param {string} tableName - The name of the table to add
   * @breaks Without this, search results wouldn't have a table_name property, affecting result filtering and display
   */
  const addTableName = (results: any, tableName: string) => {
    if (results?.data) {
      results.data.forEach((item: any) => (item.table_name = tableName));
    }
  };
  

  /**
   * Performs the search operation across multiple tables
   * @returns {Promise<any[]>} The combined search results
   */
  const search = async () => {
    console.log("Search term: ", searchTerm);
  
    try {
      setLoading(true);

      const [titleResultsPage, bodyResultsPage, titleResultsAnnouncements, descriptionResultsAnnouncements, bodyResultsAnnouncements, linkResultsTitle, linkResultsDescription, eventResultsTitle, eventResultsDescription, eventResultsInfo] = await Promise.all([
        fetchResults("google_docs", "name", searchTerm ?? ""),
        fetchResults("google_docs", "content", searchTerm ?? ""),
        fetchResults("announcement", "title", searchTerm ?? ""),
        fetchResults("announcement", "description", searchTerm ?? ""),
        fetchResults("announcement", "body", searchTerm ?? ""),
        fetchResults("link", "name", searchTerm ?? ""),
        fetchResults("link", "description", searchTerm ?? ""),
        fetchResults("event", "title", searchTerm ?? ""),
        fetchResults("event", "description", searchTerm ?? ""),
        fetchResults("event", "info", searchTerm ?? "")
      ]);

      // Remove the processResults calls that were truncating the text
      // processResults(titleResultsPage, "name", 25);
      // processResults(bodyResultsPage, "content", 30);
      // processResults(titleResultsAnnouncements, "title", 25);
      // processResults(descriptionResultsAnnouncements, "description", 30);
      // processResults(bodyResultsAnnouncements, "body", 30);
      // processResults(linkResultsTitle, "name", 25);
      // processResults(linkResultsDescription, "description", 30);
  
      addTableName(titleResultsPage, "page");
      addTableName(bodyResultsPage, "page");
      addTableName(titleResultsAnnouncements, "announcement");
      addTableName(descriptionResultsAnnouncements, "announcement");
      addTableName(bodyResultsAnnouncements, "announcement");
      addTableName(linkResultsTitle, "link");
      addTableName(linkResultsDescription, "link");
      addTableName(eventResultsTitle, "event");
      addTableName(eventResultsDescription, "event");
      addTableName(eventResultsInfo, "event");
  
      let results = [
        ...(titleResultsPage?.data ?? []),
        ...(bodyResultsPage?.data ?? []),
        ...(titleResultsAnnouncements?.data ?? []),
        ...(descriptionResultsAnnouncements?.data ?? []),
        ...(bodyResultsAnnouncements?.data ?? []),
        ...(linkResultsTitle?.data ?? []),
        ...(linkResultsDescription?.data ?? []),
        ...(eventResultsTitle?.data ?? []),
        ...(eventResultsDescription?.data ?? []),
        ...(eventResultsInfo?.data ?? []),
      ];
  
      if (userRole === "Agent") {
        console.log("Agent");
        results = results.filter((item: any) => 
          item.topLevelParentName === "Agent Resources" || 
          item.topLevelParentName === "_Everyone"
        );
      }
  
      results = results.map((item) => {
        let formattedItem = { ...item };
  
        if (item.table_name === "page" || item.table_name === "link") {
          formattedItem = {
            ...formattedItem,
            title: item.name || "", 
            body: item.content || "",
            description: item.content || "", 
            cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
            tag: item.tag || "Resource",
            link: item.link || ""
          };
        } else if (item.table_name === "link") {
          formattedItem = {
            ...formattedItem,
            title: item.name || "",
            description: item.description || "",
            tag: item.tag || "Link",
            link: item.link || ""
          };
        } else if (item.table_name === "event") {
          formattedItem = {
            ...formattedItem,
            title: item.title || "",
            description: item.description || "",
            info: item.info || "",
            tag: "Event"
          };
        } else if (item.table_name === "announcement") {
          formattedItem = {
            ...formattedItem,
            description: formattedItem.description || "",
            tag: "Announcement"
          };
        } else if (item.table_name === "event") {
          formattedItem = {
            ...formattedItem,
            title: item.title,
            description: item.description,
            info: item.info,
            tag: "Event"
          };
        } else if (item.table_name === "announcement") {
          formattedItem.tag = "Announcement";
        }
        
        // Calculate and add relevance score
        formattedItem.relevanceScore = newCalculateRelevanceScore(formattedItem, searchTerm);
        
        return formattedItem;
      });
  
      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );
  
      // Sort results by relevance score (highest to lowest)
      results.sort((a, b) => b.relevanceScore - a.relevanceScore);
  
      console.log("Search results: ", results);
      setData(results);
  
      setLoading(false);
  
      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      setLoading(false);
      return [];
    }
  };

  /**
   * Handles the search button click
   * @breaks Without this, clicking the search button wouldn't update the URL or trigger a new search
   */
  const handleSearch = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('q', searchTerm.toLowerCase()); // Convert to lowercase
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    search();
  };

  /**
   * Handles the "Need More Help" button click
   * @breaks Without this, the "Need More Help" button wouldn't open the chatbot or track the event
   */
  const handleNeedMoreHelp = () => {
    setIsChatbotOpen(true);
    trackEvent('Search', 'Need More Help', searchTerm);
  };

  /**
   * Handles the "Found It" button click
   * @breaks Without this, the "Found It" button wouldn't show the success message or track the event
   */
  const handleFoundIt = () => {
    setShowSuccessMessage(true);
    trackEvent('Search', 'Found It', searchTerm);
    setTimeout(() => setShowSuccessMessage(false), 3000); 
  };

  /**
   * Loads more search results
   * @breaks Without this, the "Load More" button wouldn't function, limiting the number of displayed results
   */
  const loadMore = () => {
    const nextPage = currentPage + 1;
    const startIndex = (nextPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newItems = data.slice(startIndex, endIndex);
    setDisplayedItems([...displayedItems, ...newItems]);
    setCurrentPage(nextPage);
  };

  /**
   * Handles changes to the search input
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event
   * @breaks Without this, typing in the search input wouldn't update the search term or URL
   */
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    
    // Update URL as user types
    const searchParams = new URLSearchParams(location.search);
    if (newSearchTerm) {
      searchParams.set('q', newSearchTerm.toLowerCase()); // Convert to lowercase
    } else {
      searchParams.delete('q');
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  return (
    <div>
      <div className="flex w-full h-screen overflow-hidden text-white">
        <Sidebar />
        <MainLayoutWrapper>
          <div className="p-5">
            <div className="text-[3rem] leading-[150%] w-[full] ml-4">
              Search Results
            </div>
            <div className="">
              <div className="mt-3 relative ml-4 pb-5">
                <input
                  className="py-3 pl-[60px] rounded-xl leading-[150%] inline-block w-[33rem] bg-black focus:outline-none text-white border-none h-[1.5rem] text-[1rem] placeholder:font-medium"
                  value={searchTerm}
                  placeholder="Search for resources"
                  onChange={handleSearchInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />

                <img
                  className="absolute top-[15px] left-[15px] w-5 h-5 overflow-hidden cursor-pointer"
                  alt=""
                  src={magnifyingglass}
                  onClick={handleSearch}
                />
              </div>
            </div>
            {loading ? (
              <div className="mt-10 ml-4 flex flex-col items-center">
                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </div>
            ) : searchTerm === "" ? (
              <div className="mt-10 ml-4 text-center">
                <p className="text-lg"></p>
              </div>
            ) : (
              <>
                <CardGrid data={displayedItems} />
                {displayedItems.length < data.length && (
                  <div className="flex justify-center mt-6">
                    <Button onClick={loadMore}>Load More</Button>
                  </div>
                )}
                {data.length === 0 && (
                  <div className="mt-10 ml-4 text-center">
                    No results found for "{searchTerm}"
                  </div>
                )}
                <div className="mt-10 ml-4 text-center">
                  <p className="text-lg mb-4">Did you find what you were looking for?</p>
                  <div className="space-x-4">
                    <button 
                      onClick={handleFoundIt}
                      className="bg-communityBlue hover:bg-[#077a8a] text-white font-medium py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                    >
                      Yes, I found it
                    </button>
                    <button 
                      onClick={handleNeedMoreHelp}
                      className="bg-[#1e293b] hover:bg-[#2c3e50] text-white font-medium py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 border border-communityBlue cursor-pointer"
                    >
                      No, I need more help
                    </button>
                  </div>
                  {showSuccessMessage && (
                    <div className="mt-4 text-green-500 font-medium animate-fade-in-out">
                      Great! We're glad you found what you were looking for.
                    </div>
                  )}
                  <p className="mt-4 text-sm text-gray-400">
                    If you need more assistance, try asking our AI chatbot for help!
                  </p>
                </div>
              </>
            )}
          </div>
        </MainLayoutWrapper>
      </div>
      <ChatbotBubble isOpen={isChatbotOpen} setIsOpen={setIsChatbotOpen} />
    </div>
  );
};

export default SearchResults;
