import React, { useEffect, useState } from 'react';
import WeeklyAnnouncementModal, { Announcement } from '../WeeklyAnnouncementModal';
import MonthlySurveyModal, { SurveyQuestion } from '../MonthlySurveyModal';
import supabaseConnect from '../../../utils/supabaseConnect';
import ReactConfetti from 'react-confetti';

/**
 * WeeklyAnnouncementHandler component
 * 
 * Manages the display logic for weekly announcements and monthly surveys.
 * Handles the timing of when to show announcements and surveys based on various conditions:
 * - Shows announcements on Mondays or if they haven't been shown since last Monday
 * - Shows monthly surveys in the last week of the month if not completed
 * - Manages local storage for tracking shown/completed states
 * 
 * @component
 * @example
 * <WeeklyAnnouncementHandler />
 * 
 * @remarks
 * This component is responsible for:
 * - Fetching and displaying announcements from Supabase
 * - Managing survey questions and responses
 * - Handling confetti animation on survey completion
 * - Managing the order of display between surveys and announcements
 * - Persisting user interaction states in localStorage
 */
const WeeklyAnnouncementHandler: React.FC = () => {
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [surveyQuestions, setSurveyQuestions] = useState<SurveyQuestion[]>([]);
  const [pendingAnnouncement, setPendingAnnouncement] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);


  useEffect(() => {
    checkAndShowSurvey();
    checkAndShowAnnouncement();
  }, []);

  const checkAndShowSurvey = async () => {
    const lastSurveySeen = localStorage.getItem('lastMonthlySurveySeen');
    const today = new Date();
    const todayString = today.toISOString().split('T')[0];
    
    const shouldShowSurvey = (() => {
      if (!lastSurveySeen) return true;
      
      const lastSeenMonth = new Date(lastSurveySeen).getMonth();
      const currentMonth = today.getMonth();
      const lastSeenYear = new Date(lastSurveySeen).getFullYear();
      const currentYear = today.getFullYear();

      const lastSurveyCompleted = localStorage.getItem('lastMonthlySurveyCompleted');
      return (
        !lastSurveyCompleted ||
        lastSeenMonth !== currentMonth ||
        lastSeenYear !== currentYear
      );
    })();



    if (shouldShowSurvey) {
      const supabase = supabaseConnect();
      const { data: questions, error: questionsError } = await supabase
        .from('survey_questions')
        .select('*')
        .order('order');

      if (questionsError) {
        console.error('Error fetching survey questions:', questionsError);
      } else if (questions && questions.length > 0) {
        setSurveyQuestions(questions);
        setShowSurvey(true);
      }

        localStorage.setItem('lastMonthlySurveySeen', todayString);

    }
  };

  const checkAndShowAnnouncement = async () => {
    const lastShownDate = localStorage.getItem('lastShownWeeklyAnnouncement');
    const today = new Date();
    const todayString = today.toISOString().split('T')[0];

    if (lastShownDate !== todayString) {
      const isMonday = today.getDay() === 1;
      const supabase = supabaseConnect();
      
      const lastSaturday = new Date(today);
      lastSaturday.setDate(today.getDate() - today.getDay() - 1);
      lastSaturday.setHours(23, 59, 59, 999);
      
      const lastSunday = new Date(lastSaturday);
      lastSunday.setDate(lastSaturday.getDate() - 6);
      lastSunday.setHours(0, 0, 0, 0);
      
      const { data, error } = await supabase
        .from('announcement')
        .select('*')
        .gte('created_at', lastSunday.toISOString())
        .lte('created_at', lastSaturday.toISOString())
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching announcements:', error);
        return;
      }

      const fetchedAnnouncements = (data || []).filter((announcement: Announcement) => {
        return !announcement.expired;
      });
      
      let shouldShow = false;
      if (fetchedAnnouncements.length > 0) {
        if (isMonday) {
          shouldShow = true;
        } else if (lastShownDate) {
          const lastMonday = new Date(today);
          lastMonday.setDate(today.getDate() - ((today.getDay() + 6) % 7));
          lastMonday.setHours(0, 0, 0, 0);

          if (new Date(lastShownDate) < lastMonday) {
            shouldShow = true;
          }
        } else {
          shouldShow = true;
        }
      }

      localStorage.setItem('lastShownWeeklyAnnouncement', todayString);
      if (shouldShow) {
        setAnnouncements(fetchedAnnouncements);
        if (showSurvey) {
          // If survey is showing, set announcement as pending
          setPendingAnnouncement(true);
        } else {
          // If no survey, show announcement immediately
          setShowAnnouncement(true);
        }
      }
    }
  };

  const handleClose = () => {
    // If survey was shown but not completed, mark it as seen for the day
    const today = new Date();
    localStorage.setItem('lastSurveySeen', today.toISOString().split('T')[0]);
    setShowAnnouncement(false);
  };

  const handleSurveyClose = () => {
    setShowSurvey(false);
    // If there's a pending announcement, show it after survey closes
    if (pendingAnnouncement) {
      setShowAnnouncement(true);
      setPendingAnnouncement(false);
    }
  };

  const handleSurveySubmit = async (responses: Record<string, string>) => {
    const supabase = supabaseConnect();
    const { data: { user } } = await supabase.auth.getUser();

    // grab the profile data
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', user?.id);

    if (profileError) {
      console.error('Error fetching profile:', profileError);
    }

    if (!user) {
      console.error('No user found');
      return;
    }

    const profile_full_name = `${profile?.[0]?.first_name} ${profile?.[0]?.last_name}`;
    
    const surveyResponses = Object.entries(responses).map(([questionId, response]) => ({
      question_id: questionId,
      response,
      profile_id: user.id,
      profile_full_name,
      survey_date: new Date().toISOString()
    }));

    const { error } = await supabase
      .from('survey_responses')
      .insert(surveyResponses);

    if (error) {
      console.error('Error submitting survey:', error);
    } else {
      // Show confetti
      setShowConfetti(true);
      // Hide confetti after 5 seconds
      setTimeout(() => setShowConfetti(false), 5000);

      // Store both the monthly and weekly survey completion
      const today = new Date();
      const currentMonthYear = `${today.getFullYear()}-${today.getMonth() + 1}`;
      localStorage.setItem('lastMonthlySurveyDate', today.toISOString().split('T')[0]);
      localStorage.setItem('lastWeeklySurveyCompleted', 'true');
      localStorage.setItem('lastWeeklySurveyMonth', currentMonthYear);
      localStorage.removeItem('lastSurveySeen'); // Clear the seen flag when completed
    }
  };

  return (
    <>
      {showConfetti && (
        <div className="contents">
          <ReactConfetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={200}
            gravity={0.3}
            style={{ 
              position: 'fixed', 
              top: 0, 
              left: 0, 
              zIndex: 9998,
              pointerEvents: 'none',
              touchAction: 'none'
            }}
          />
          <div 
            aria-live="polite"
            className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[9999] pointer-events-none select-none w-[90%] md:w-auto"
          >
            <div className="text-3xl md:text-4xl font-bold text-center text-primary-600 bg-white/50 px-4 md:px-8 py-4 rounded-lg shadow-lg animate-fade-in-out whitespace-normal md:whitespace-nowrap">
              Thank you for submitting! 😊
            </div>
          </div>
        </div>
      )}
      <WeeklyAnnouncementModal 
        isOpen={showAnnouncement} 
        onClose={handleClose}
        announcements={announcements}
        showSurvey={true}
        surveyQuestions={surveyQuestions}
        onSurveySubmit={handleSurveySubmit}
      />
    </>
  );
};

export default WeeklyAnnouncementHandler; 