import { DateTime } from 'luxon';
import supabaseConnect from './supabaseConnect';

/**
 * Updates the last_active timestamp for a user
 * @param userId - The ID of the user to update
 * @returns void
 * @throws Error if the update fails
 */
export const updateLastActive = async (userId: string) => {
  const supabase = supabaseConnect();
  const { error } = await supabase
    .from('profiles')
    .update({ last_active: new Date().toISOString() })
    .eq('id', userId);
    
  if (error) {
    console.error('Error updating last active:', error);
  }
};

/**
 * Formats a timestamp into a human-readable "time ago" string
 * @param timestamp - ISO timestamp string
 * @returns Formatted string like "2 hours ago" or "Active now"
 */
export const formatLastActive = (timestamp: string | null): string => {
  if (!timestamp) return 'Never';
  
  const date = DateTime.fromISO(timestamp);
  const now = DateTime.now();
  const diff = now.diff(date, 'minutes').minutes;
  
  if (diff < 5) {
    return 'Active now';
  }
  
  if (diff < 60) {
    return `${Math.floor(diff)} minutes ago`;
  }
  
  const diffHours = now.diff(date, 'hours').hours;
  if (diffHours < 24) {
    return `${Math.floor(diffHours)} hours ago`;
  }
  
  const diffDays = now.diff(date, 'days').days;
  if (diffDays < 7) {
    return `${Math.floor(diffDays)} days ago`;
  }
  
  return date.toRelative() || 'Some time ago';
}; 