import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import MainLayoutWrapper from '../../components/Layout/MainLayoutWrapper';
import supabaseConnect from '../../utils/supabaseConnect';
import { sanitizeHtmlContent } from '../../utils/sanitizeHtml';
import PartnerAndVendorSearchAndFilter from '../../components/PartnerAndVendorSearchAndFilter/PartnerAndVendorSearchAndFilter';
import StarRating from '../../components/Rating/StarRating';
import { sortAlphabetically } from '../../utils/sortAlphabetically';
import { FaPlus, FaTimes } from 'react-icons/fa'; // Add FaTimes import
import Button from '../../components/Button/Button';

interface Partner {
  id: number;
  name: string;
  description: string;
  info: string;
  logo_url: string;
  categories: number[];
}

interface Category {
  id: number;
  name: string;
}

/**
 * PartnerCard component
 * Renders a card displaying partner information
 * @param {Object} props - Component props
 * @param {number} props.id - Partner ID
 * @param {string} props.name - Partner name
 * @param {string} props.description - Partner description
 * @param {string} props.logo_url - URL of partner's logo
 * @param {Function} props.onClick - Function to handle card click
 * @param {Function} props.onEdit - Function to handle edit button click
 * @param {boolean} props.showEditButton - Flag indicating if edit button should be shown
 * 
 * @remarks
 * If missing: Partner information would not be displayed, breaking the UI and user interaction
 */
const PartnerCard: React.FC<Partner & { onClick: () => void; onEdit: () => void; showEditButton: boolean }> = ({ id, name, description, logo_url, onClick, onEdit, showEditButton }) => {
  const [averageRating, setAverageRating] = useState<number>(0);
  const [ratingCount, setRatingCount] = useState<number>(0);

  useEffect(() => {
    fetchRatings();
  }, [id]);

  /**
   * Fetches ratings data for the partner
   * 
   * @remarks
   * If missing: Ratings data would not be loaded, resulting in no ratings displayed
   */
  const fetchRatings = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('partner_ratings')
      .select('rating')
      .eq('partner_id', id);

    if (error) {
      console.error('Error fetching ratings:', error);
    } else {
      const ratings = data.map(r => r.rating);
      const avg = ratings.length > 0 ? ratings.reduce((a, b) => a + b, 0) / ratings.length : 0;
      setAverageRating(avg);
      setRatingCount(ratings.length);
    }
  };

  const tempElement = document.createElement('div');
  tempElement.innerHTML = description;
  const extractedText = tempElement.textContent || tempElement.innerText;
  const limitedDescription = extractedText.slice(0, 35) + (extractedText.length > 35 ? '...' : '');
  
  return (
    <div 
      className="flex flex-col rounded-lg shadow-lg overflow-hidden h-[22rem] bg-[#2d3035] cursor-pointer relative"
      onClick={onClick} 
    >
      <div className="flex justify-center items-center h-36 bg-white p-2" style={{ border: '1px solid #D1D5DB' }}>
        <img
          className="h-full w-auto max-w-full object-contain"
          src={logo_url}
          alt={`${name} logo`}
        />
      </div>
      <div className="flex-1 bg-black p-3 flex flex-col">
        <div className="flex-1">
          <p className="text-sm font-medium text-tourquoise mb-1">
            Partner
          </p>
          <p className="text-lg font-semibold text-white mb-1 truncate">
            {name}
          </p>
          <p className="text-sm text-white line-clamp-3">
            {limitedDescription}
          </p>
        </div>
        <div className="flex justify-between items-center text-[.8rem] text-white mt-2">
          <div className="flex items-center">
            <StarRating rating={averageRating} readonly={true} />
            <span className="ml-2">({ratingCount})</span>
          </div>
          <div>
            View Partner<span>&rarr;</span>
          </div>
        </div>
      </div>
      {showEditButton && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          className="absolute top-2 right-2 bg-[#2d3035] text-white p-2 rounded text-sm cursor-pointer border border-white hover:bg-black"
        >
          Edit
        </button>
      )}
    </div>
  );
};

// Add the SelectedCategories component
const SelectedCategories: React.FC<{
  categories: Category[];
  onRemove: (id: number) => void;
}> = ({ categories, onRemove }) => {
  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {categories.map((category) => (
        <div
          key={category.id}
          className="bg-black text-white rounded-full px-3 py-1 m-1 flex items-center"
        >
          {category.name}
          <FaTimes
            className="ml-2 cursor-pointer"
            onClick={() => onRemove(category.id)}
          />
        </div>
      ))}
    </div>
  );
};

interface PartnersProps {
  editMode?: boolean;
}

/**
 * Partners component
 * Main component for the Partners page
 * Manages state, data fetching, and rendering of partner cards
 * 
 * @remarks
 * If missing: The entire Partners page would be non-functional
 */
const Partners: React.FC<PartnersProps> = ({ editMode = false }) => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const [visiblePartners, setVisiblePartners] = useState(8);
  const navigate = useNavigate();

  /**
   * Fetches partners data from the database
   * 
   * @remarks
   * If missing: Partners data would not be loaded, resulting in an empty page
   */
  const fetchPartners = async () => {
    setLoading(true);
    setError(null);
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('partners')
      .select(`
        *,
        categories:partner_categories(category_id)
      `);
    
    if (error) {
      console.error('Error fetching partners:', error);
      setError('Failed to fetch partners');
    } else if (data) {
      setPartners(data.map(partner => ({
        ...partner,
        info: sanitizeHtmlContent(partner.info),
        categories: partner.categories?.map((c: { category_id: number }) => c.category_id) || []
      })));
    } else {
      setPartners([]);
    }
    setLoading(false);
  };

  /**
   * Fetches categories data from the database
   * 
   * @remarks
   * If missing: Category filtering would not work
   */
  const fetchCategories = async () => {
    const supabase = supabaseConnect();
    const { data, error } = await supabase
      .from('categories')
      .select('*');
    
    if (error) {
      console.error('Error fetching categories:', error);
    } else {
      // Sort categories alphabetically
      setCategories(sortAlphabetically(data, 'name'));
    }
  };

  useEffect(() => {
    fetchPartners();
    fetchCategories();
    const checkAdminStatus = async () => {
      const supabase = supabaseConnect();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('profile_role')
          .select('role_id')
          .eq('user_id', user.id)
          .single();

        if (data && data.role_id === 1) {
          setIsAdmin(true);
        }
      }
    };

    checkAdminStatus();
  }, []);

  const filteredPartners = partners.filter(partner =>
    partner.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedCategories.length === 0 || selectedCategories.some(cat => 
      partner.categories && partner.categories.includes(cat.id)
    ))
  );

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(categorySearchTerm.toLowerCase())
  );

  const handleCategorySelect = (category: Category) => {
    if (!selectedCategories.some(cat => cat.id === category.id)) {
      setSelectedCategories(prevCategories => [...prevCategories, category]);
      setSearchTerm(prevTerm => prevTerm.replace(new RegExp(category.name, 'i'), '').trim());
    }
  };

  const handleCategoryRemove = (categoryId: number) => {
    setSelectedCategories(selectedCategories.filter(cat => cat.id !== categoryId));
  };

  const handleCreatePartner = () => {
    navigate('/admin/create-partner/create');
  };

  const handlePartnerClick = (partnerId: number) => {
    navigate(`/partners/${partnerId}`);
  };

  const handleEditClick = (partnerId: number) => {
    navigate(`/admin/edit-partner/${partnerId}`);
  };

  const loadMorePartners = () => {
    setVisiblePartners(prev => prev + 8);
  };

  return (
    <div className={`flex w-full ${!editMode ? 'h-screen' : ''} ${editMode ? '-ml-[3rem] -mt-[3rem]' : ''} overflow-hidden text-white bg-[#1d2025] `}>
      {!editMode && <Sidebar />}
      <MainLayoutWrapper>
        <div className="p-5">
          <div 
            className="page-bg-header xl:p-16 lg:p-12 md:p-10 sm:p-8 p-6 flex sm:flex-row flex-col leading-[150%] justify-between items-center bg-black rounded-2xl"
          >
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer">
              Community Partners
            </p>
          </div>
          <div className="flex items-center mt-5">
            <PartnerAndVendorSearchAndFilter
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedCategories={selectedCategories}
              handleCategoryRemove={handleCategoryRemove}
              setIsFilterMenuOpen={setIsFilterMenuOpen}
              isFilterMenuOpen={isFilterMenuOpen}
              categorySearchTerm={categorySearchTerm}
              setCategorySearchTerm={setCategorySearchTerm}
              filteredCategories={filteredCategories}
              handleCategorySelect={handleCategorySelect}
              allCategories={categories}
              isAdmin={isAdmin}
              handleCreateItem={handleCreatePartner}
            />
          </div>
          <SelectedCategories
            categories={selectedCategories}
            onRemove={handleCategoryRemove}
          />
          <div className="mt-5">
            {loading && <p className="text-center">Loading...</p>}
            {error && <p className="text-center text-red-500">{error}</p>}
            {!loading && !error && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {filteredPartners.slice(0, visiblePartners).map((partner: Partner) => (
                  <PartnerCard 
                    key={partner.id}
                    {...partner} 
                    onClick={() => handlePartnerClick(partner.id)} 
                    onEdit={() => handleEditClick(partner.id)}
                    showEditButton={editMode}
                  />
                ))}
              </div>
            )}
            {partners.length === 0 && !loading && (
              <p className="text-center mt-4">No partners found</p>
            )}
            {filteredPartners.length > visiblePartners && (
              <div className="flex justify-center mt-4">
                <Button onClick={loadMorePartners}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default Partners;