import React, { useEffect, useState } from "react";
import supabaseConnect from "../../../utils/supabaseConnect";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import MainLayoutWrapper from "../../../components/Layout/MainLayoutWrapper";
import { equal } from "assert";

const EditUsers = () => {
  const supabase = supabaseConnect();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    phoneNumber: "",
  });
  const [profile, setProfile] = useState<any>(null);
  const [coverImage, setCoverImage] = useState<any>(null);
  const [profileRoles, setProfileRoles] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const [newProfileRoles, setNewProfileRoles] = useState<any>(null);
  const [profileNewRole, setProfileNewRole] = useState<any>(null);

  useEffect(() => {
    const getUserProfile = async () => {
      const user = await supabase.from("profiles").select("*").eq("id", id);

      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", id);

      setProfile(data);

      if (error) {
        console.log(error);
      }

      setFormData({
        firstName: data?.[0].first_name,
        lastName: data?.[0].last_name,
        email: data?.[0].email,
        jobTitle: data?.[0].job_title,
        phoneNumber: data?.[0].phone,
      });
      console.log(formData);
    };
    const getProfileRoles = async () => {
      const { data: profileRole, error: profileRoleError } = await supabase
        .from("role")
        .select("*");

      if (profileRoleError) {
        console.log(profileRoleError);
      }
      setProfileRoles(profileRole);
    };

    const profileRoles = async () => {
      const { data: profileRoles, error: profileRoleErrors } = await supabase
        .from("profile_role")
        .select("*")
        .eq("user_id", id);

      if (profileRoles) {
        console.log(profileRoleErrors);
      }

      const { data: newRole, error: newRoleError } = await supabase
        .from("role")
        .select("*")
        .eq("id", profileRoles?.[0].role_id);

      if (newRoleError) {
        console.log(newRoleError);
      }

      setProfileNewRole(newRole?.[0].role_name);
    };

    const checkAdmin = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      const userId = user?.id;

      const { data, error } = await supabase
        .from("profile_role")
        .select("*")
        .eq("user_id", userId);

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        console.log(data);
        if (data.length <= 0) {
          return (window.location.href = "/");
        }
      }
    };

    checkAdmin();
    getProfileRoles();
    profileRoles();
    getUserProfile();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (formData.email != profile?.[0].email) {
      const { data: user, error } = await supabase.auth.updateUser({
        email: formData.email,
      });
      alert("Please check your email to finish updating your email address. You will get an email from noreply@mail.app.supabase.io");
    }

    const { data, error } = await supabase
      .from("profiles")
      .update({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        job_title: formData.jobTitle,
        phone: Number(formData.phoneNumber),
        profile_image_url: coverImage || profile?.[0].profile_image_url,
      })
      .eq("id", profile?.[0].id);

    console.log(data);

    if (error) {
      console.log(error);
    }

    const findRoleInProfileRole = profileRoles?.find(
      (role: any) => role.role_name === profileNewRole
    );

    const { data: profileRole, error: profileRoleError } = await supabase
      .from("profile_role")
      .update({
        role_id: findRoleInProfileRole?.id,
      })
      .eq("user_id", id)
      .select("*");

    console.log();

    if (profileRoleError) {
      console.log(profileRoleError);
    }

    return (window.location.href = "/");
  };

  const getAllImages = async () => {
    const { data, error } = await supabase.storage.from("media").list();

    if (error) alert(error?.message);

    console.log(data);

    return data;
  };

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];

    console.log(file);

    const filename = `${Math.floor(Math.random() * 1000000000)}`;

    if (file) {
      const { data, error } = await supabase.storage
        .from("media")
        .upload(`${filename}.${file.name}`, file);

      const { data: publicUrl } = await supabase.storage
        .from("media")
        .getPublicUrl(`${filename}.${file.name}`);

      if (error) {
        console.log(error);
      }
      setCoverImage(publicUrl.publicUrl);

      const images = getAllImages();

      return images;
    }
    return "no file";
  };

  const handleActivate = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .update({ active: !profile?.[0].active })
      .eq("id", profile?.[0].id);

    if (error) {
      console.log(error);
    }
    console.log("testing");

    return (window.location.href = "/users");
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-left font-inter">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="sm:p-5 p-1">
          <div className="flex flex-col items-center w-2/5 h-full bg-gradient-to-tl from-gray to-black">
            {/* The left side of the screen would have similar content/design as signup page */}
            {/* ... */}
          </div>
          <div className="flex justify-center bg-white sm:w-1/2 w-11/12 sm:p-5 p-1 m-auto rounded-md">
            <div className="w-fit md:w-full">
              <h1 className="text-xl font-bold  font-Poppins">Profile</h1>
              <div className="flex justify-center">
                <img
                  className="rounded-full w-32"
                  src={
                    profile?.[0].profile_image_url ||
                    "https://via.placeholder.com/150"
                  }
                  alt=""
                />
              </div>
              <input
                type="file"
                className="w-full h-10 mt-1 focus:outline-none rounded-md"
                onChange={handleUpload}
              />
              <form
                className="md:w-full space-y-6 z-[10]"
                onSubmit={handleSubmit}
              >
                <div>
                  <label className="text-sm">First Name</label>
                  <input
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                    type="text"
                  />
                </div>

                <div>
                  <label className="text-sm">Last Name</label>
                  <input
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                    type="text"
                  />
                </div>
                <div>
                  <label className="text-sm">Email Address</label>
                  <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                    type="email"
                  />
                </div>
                <div>
                  <label className="text-sm">Job Title</label>
                  <input
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                    type="text"
                  />
                </div>
                <div>
                  <label className="text-sm">Phone Number</label>
                  <input
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                    type="tel"
                  />
                </div>
                <div className="flex flex-row space-x-4 p-2 bg-gray border-lightGray rounded">
                  <div className="w-full">
                    <label
                      className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="grid-description"
                    >
                      What role is this user?
                    </label>
                    {profileRoles?.map((option: any, index: number) => (
                      <label key={index} className="p-2 text-sm">
                        <input
                          type="radio"
                          value={option.role_name}
                          checked={profileNewRole === option.role_name}
                          onChange={(e) => {
                            setProfileNewRole(e.target.value);
                            setNewProfileRoles(profileNewRole);
                          }}
                          name="tag"
                          className="mr-2"
                        />
                        {option.role_name}
                      </label>
                    ))}
                  </div>
                </div>
                {/* make a button that if the account is activated it says deactivate and if its deactivated it says activate */}
                <div className="flex sm:flex-row flex-col w-full justify-center mt-3">
                  <button
                    className=" w-full sm:w-5/12 sm:mx-5 h-10 text-white border-none bg-lightBlue rounded-md cursor-pointer"
                    type="submit"
                  >
                    Update Profile
                  </button>
                  <button
                    onClick={handleActivate}
                    className=" w-full sm:w-5/12 sm:mx-5 h-10 text-white border-none bg-blue-500 rounded-md cursor-pointer max-sm:mt-3"
                  >
                    {profile?.[0].active ? "Deactivate" : "Activate"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default EditUsers;
