import React from 'react';

const QuickLinksSkeleton: React.FC = () => {
  return (
    <div className="md:min-h-[29rem] min-h-80 border rounded-lg text-base bg-black p-4">
      {/* Title Skeleton */}
      <div className="animate-pulse">
        <div className="h-8 w-32 bg-gray-700 rounded mb-4" />
        <div className="border-b-2 border-communityBlue mb-4" />
      </div>

      {/* Links Container */}
      <div className="block pb-[1rem] h-[23rem]">
        {/* Community Section */}
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <div className="h-6 w-28 bg-gray-700 rounded" />
            <div className="h-4 w-4 bg-gray-700 rounded" />
          </div>
          {/* Community Links */}
          {[...Array(4)].map((_, index) => (
            <div 
              key={`community-${index}`}
              className="h-8 bg-gray-700 bg-opacity-40 rounded mt-2"
            />
          ))}
        </div>

        <div className="border-b-2 border-communityBlue my-4" />

        {/* REAL Section */}
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <div className="h-6 w-20 bg-gray-700 rounded" />
            <div className="h-4 w-4 bg-gray-700 rounded" />
          </div>
          {/* REAL Links */}
          {[...Array(4)].map((_, index) => (
            <div 
              key={`real-${index}`}
              className="h-8 bg-gray-700 bg-opacity-40 rounded mt-2"
            />
          ))}
        </div>

        <div className="border-b-2 border-communityBlue my-4" />
      </div>
    </div>
  );
};

export default QuickLinksSkeleton; 