import React from 'react';
import { DownloadButtonProps } from '../../types';

/**
 * DownloadButton component
 * @description A reusable button component with click animation for downloading assets
 * @param {DownloadButtonProps} props - Component props
 * @returns {JSX.Element} Animated download button
 * 
 * @example
 * <DownloadButton onClick={() => handleDownload('file-id')}>
 *   Download
 * </DownloadButton>
 */
const DownloadButton = ({ onClick, children = 'Download' }: DownloadButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="rounded-full bg-gray-200 px-8 py-2 cursor-pointer
                transition-all duration-200 ease-in-out
                hover:bg-gray-300 hover:shadow-md
                active:transform active:scale-95"
      aria-label="Download asset"
    >
      {children}
    </button>
  );
};

export default DownloadButton; 