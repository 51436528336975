import sanitizeHtml from 'sanitize-html';

export const sanitizeHtmlContent = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': ['style', 'class', 'id'],
      'img': ['src', 'alt', 'title', 'width', 'height', 'style'],
    },
    allowedSchemes: ['http', 'https', 'mailto'],
  });
};