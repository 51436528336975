import { createClient, SupabaseClient } from "@supabase/supabase-js";

let supabase: SupabaseClient | null = null;

const supabaseConnect = (): SupabaseClient => {
  if (supabase) {
    return supabase;
  }

  const supabaseURL = process.env.REACT_APP_DB_URL || "";
  const supabaseKey = process.env.REACT_APP_ANON_KEY || "";

  supabase = createClient(supabaseURL, supabaseKey);

  if (!supabase) {
    throw new Error("Could not connect to Supabase");
  }

  return supabase;
}

export default supabaseConnect;

