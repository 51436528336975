import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaUsers,
  FaBullhorn,
  FaHandshake,
  FaStore,
  FaSearch,
  FaSpinner,
  FaCalendarPlus,
  FaClipboardList
} from 'react-icons/fa';

interface AdminToolItem {
  icon: React.ComponentType;
  title: string;
  description: string;
  link: string;
  category: string;
  action?: () => void;
}

interface AdminPanelProps {
  setShowEventModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminLink: React.FC<AdminToolItem> = ({ icon: Icon, title, description, link, action }) => {
  const content = (
    <div className="bg-[#100f0f] text-[#f1f2f6] border-2 border-[#2a2828] rounded-lg p-4 transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-lg cursor-pointer h-full">
      <div className="flex items-center gap-4">
        <div className="bg-communityBlue rounded-lg p-4 flex items-center justify-center text-[#f1f2f6]">
          <Icon />
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-1">{title}</h3>
          <p className="text-sm text-[#f1f2f6]/70">{description}</p>
        </div>
      </div>
    </div>
  );

  if (action) {
    return <div onClick={action}>{content}</div>;
  }

  return <Link to={link} className="no-underline">{content}</Link>;
};

const AdminPanel: React.FC<AdminPanelProps> = ({ setShowEventModal }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredTools, setFilteredTools] = useState<AdminToolItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const adminTools: AdminToolItem[] = [
    {
      icon: FaUsers,
      title: "User Management",
      description: "Manage user accounts and permissions",
      link: "/staff-admin/users",
      category: "Users"
    },
    {
      icon: FaBullhorn,
      title: "Create Announcement",
      description: "Create new announcements for the community",
      link: "/staff-admin/announcements/create",
      category: "Announcements"
    },
    {
      icon: FaHandshake,
      title: "Create Partner",
      description: "Add new partners to the platform",
      link: "/staff-admin/partners/create",
      category: "Partners"
    },
    {
      icon: FaHandshake,
      title: "Edit Partners",
      description: "Update or remove partner information",
      link: "/staff-admin/partners/edit",
      category: "Partners"
    },
    {
      icon: FaStore,
      title: "Create Vendor",
      description: "Add new vendors to the platform",
      link: "/staff-admin/vendors/create",
      category: "Vendors"
    },
    {
      icon: FaStore,
      title: "Edit Vendors",
      description: "Update or remove vendor information",
      link: "/staff-admin/vendors/edit",
      category: "Vendors"
    },
    {
      icon: FaCalendarPlus,
      title: "Create Event",
      description: "Add new events to the platform",
      link: "",
      category: "Events",
      action: () => setShowEventModal(true)
    },
    {
      icon: FaClipboardList,
      title: "Survey Manager",
      description: "Manage monthly feedback survey questions",
      link: "/staff-admin/survey-manager",
      category: "Surveys"
    }
  ];

  const categories = ['All', ...Array.from(new Set(adminTools.map(tool => tool.category)))];

  useEffect(() => {
    const filtered = adminTools.filter(tool => 
      (selectedCategory === 'All' || tool.category === selectedCategory) &&
      (tool.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       tool.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredTools(filtered);
  }, [searchTerm, selectedCategory]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
    if (!isSearchExpanded) {
      setTimeout(() => {
        const input = document.getElementById('search-input');
        if (input) input.focus();
      }, 100);
    }
  };

  if (isLoading) {
    return (
      <div className="flex-grow bg-[#100f0f] text-[#f1f2f6] flex items-center justify-center">
        <FaSpinner className="animate-spin text-4xl" />
      </div>
    );
  }

  return (
    <div className="flex-grow bg-[#100f0f] text-[#f1f2f6] p-4 mr-[2rem]">
      <header className="bg-[#100f0f] border-b border-[#2a2828]">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-3xl font-semibold"></h1>
          <div className={`relative overflow-hidden transition-all duration-300 ease-in-out ${isSearchExpanded ? 'w-64' : 'w-10'} h-10 bg-[#f1f2f6]/15 hover:bg-[#f1f2f6]/25 mr-[1rem]`}>
            <input
              id="search-input"
              type="text"
              placeholder="Search admin Panel..."
              value={searchTerm}
              onChange={handleSearch}
              className={`bg-transparent text-[#f1f2f6] pl-4 pr-10 w-full h-full ${isSearchExpanded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
            />
            <button
              onClick={toggleSearch}
              className="absolute right-0 top-0 w-10 h-10 flex items-center justify-center text-[#f1f2f6] hover:text-communityBlue transition-colors duration-300 bg-[#2a2828] cursor-pointer"
            >
              <FaSearch className="text-xl" />
            </button>
          </div>
        </div>
      </header>
      
      <main className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-4xl font-bold mb-2">Admin Panel</h1>
          <p className="text-xl text-[#f1f2f6]/70 pr-[1rem]">
            Manage users, announcements, partners, and vendors
          </p>
        </div>
        
        <div className="mb-6 flex flex-wrap gap-2">
          {categories.map((category, index) => (
            <button
              key={index}
              onClick={() => handleCategoryChange(category)}
              className={`px-4 py-2 rounded-full text-sm cursor-pointer ${
                selectedCategory === category
                  ? 'bg-communityBlue text-[#f1f2f6]'
                  : 'bg-[#f1f2f6]/15 text-[#f1f2f6]/70 hover:bg-[#f1f2f6]/25'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {filteredTools.length > 0 ? (
            filteredTools.map((tool, index) => (
              <AdminLink key={index} {...tool} />
            ))
          ) : (
            <p className="col-span-full text-center text-xl text-[#f1f2f6]/70">
              No admin tools found matching your criteria.
            </p>
          )}
        </div>
      </main>
    </div>
  );
};

export default AdminPanel;