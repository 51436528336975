import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MainLayoutWrapper from "../../components/Layout/MainLayoutWrapper";
import { Search } from "../dashboard/Components";
import supabaseConnect from "../../utils/supabaseConnect";
import CalendarCards from "../../components/Calendarv1Cards/CalenderCard";
import { get } from "http";
import Button from "../../components/Button/Button";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { DateTime } from "luxon";

interface Event {
  id: number;
  title: string | null;
  description: string | null;
  start_date: string;
  info?: string;
  location?: string;
  cover_image?: string;
  tag_name?: string;
  all_day?: boolean;
}

const CalendarV1 = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [calendarData, setCalendarData] = useState<any>([]);
  const supabase = supabaseConnect();
  const [isOpen, setIsOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<any>();
  const [beingEdited, setBeingEdited] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const onClose = () => setIsOpen(false);
  const title = "Event";
  const [loading, setLoading] = useState(false);
  const [displayCount, setDisplayCount] = useState(8);
  const [allEvents, setAllEvents] = useState<any>([]);

  const handleClick = () => {
    console.log("clicked");
  };

  const search = async () => {
    setLoading(true);
    try {
      // Execute search queries for each table
      const titleResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("title", searchTerm, { type: "websearch" }),
      ]);
      const descriptionResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("description", searchTerm, { type: "websearch" }),
      ]);
      const locationResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("location", searchTerm, { type: "websearch" }),
      ]);
      const bodyResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("info", searchTerm, { type: "websearch" }),
      ]);


      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(titleResults[0]?.data ?? []),
        ...(bodyResults[0]?.data ?? []),
        ...(descriptionResults[0]?.data ?? []),
        ...(locationResults[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);

      // results = results.map((item) => ({
      //   ...item,
      //   title: item.name.substring(0, 25),
      //   body: item.content,
      //   description: item.content.substring(0, 30),
      //   cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      //   tag_name: "Resource",
      // }));

      // Convert current time to MST for comparison
      const currentMST = DateTime.now().setZone("America/Denver");
      const currentMSTTimestamp = currentMST.toJSDate().getTime();

      results = results.filter((event: any) => {
        const eventDate = DateTime.fromISO(event.start_date, { zone: "UTC" })
          .setZone("America/Denver");
        return eventDate.toJSDate().getTime() >= currentMSTTimestamp;
      });

      setCalendarData(results);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getCalendarData = async () => {
      const { data, error } = await supabase
        .from("event")
        .select("*")
        .order("id", { ascending: true });
      if (error) {
        console.log("Error fetching calendar data: ", error);
      } else {
        const sortedData = data?.sort((a: any, b: any) => {
          return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
        });
        
        // Convert current time to MST for comparison
        const currentMST = DateTime.now().setZone("America/Denver");
        const currentMSTTimestamp = currentMST.toJSDate().getTime();

        let filteredData = sortedData?.filter((event: any) => {
          // Convert event time to MST
          const eventDate = DateTime.fromISO(event.start_date, { zone: "UTC" })
            .setZone("America/Denver");
          return eventDate.toJSDate().getTime() >= currentMSTTimestamp;
        });
        
        // Store all filtered events
        setAllEvents(filteredData || []);
        
        // Display only first 8 events
        const displayData = filteredData?.slice(0, displayCount)?.map((item) => {
          // Format the date in MST
          const mstDateTime = DateTime.fromISO(item.start_date, { zone: "UTC" })
            .setZone("America/Denver");
          
          const formattedDate = item.all_day 
            ? 'All Day' 
            : mstDateTime.toFormat('MM/dd/yyyy hh:mm a ZZZZ');

          return {
            ...item,
            title: item.title ? 
              (item.title.length > 20 ? item.title.substring(0, 20) + "..." : item.title)
              : "Untitled",
            description: item.description ? 
              (item.description.length > 30 ? item.description.substring(0, 30) + "..." : item.description)
              : "No description available",
            cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
            tag_name: "Resource",
            display_date: formattedDate,
            start_date: item.start_date
          };
        });

        setCalendarData(displayData || []);
      }
    };
    getCalendarData();
  }, [displayCount]);

  const handleLoadMore = () => {
    setDisplayCount(prev => prev + 8);
  };

  const cardStyles = {
    backgroundColor: 'black',
    textColor: 'white',
    numberColor: '#08C2F8',
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="sm:p-5"> {/* Changed p-5 to sm:p-5 */}
          <div className="bg-black xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer" onClick={handleClick}>
              Events
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Events"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="mt-8">
                <CalendarCards
                  data={calendarData}
                  isOpen={isOpen}
                  onClose={onClose}
                  activeEvent={activeEvent}
                  setBeingEdited={setBeingEdited}
                  setShowEventModal={setShowEventModal}
                  setActiveEvent={setActiveEvent}
                  title={title}
                  cardStyles={cardStyles}
                />
              </div>
              {allEvents.length > displayCount && (
                <div className="flex justify-center mt-8 mb-8">
                  <button
                    onClick={handleLoadMore}
                    className="bg-black text-white px-6 py-2 rounded-lg hover:bg-communityBlue cursor-pointer"
                  >
                    Load More
                  </button>
                </div>
              )}
              {calendarData.length === 0 && searchTerm && (
                <div className="mt-10 ml-4 text-center">
                  No events found for "{searchTerm}"
                </div>
              )}
            </>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default CalendarV1;
