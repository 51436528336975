import { useState, useEffect } from 'react';
import supabaseConnect from "../../../utils/supabaseConnect";

interface Profile {
  profile_image_url?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

/**
 * Custom hook to fetch and manage the current user's profile data.
 * 
 * @param {string | undefined} userId - The ID of the current user
 * @returns {Object} An object containing the user's profile data
 * @property {Profile | null} profile - The user's profile data or null if not found
 * 
 * @example
 * const { profile } = useProfile(currentUserId);
 * 
 * @remarks
 * If this hook is missing, the application would not be able to display or manage user profile information,
 * affecting personalized content and user-specific settings throughout the app.
 */


export const useProfile = (userId: string | undefined) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const supabase = supabaseConnect();

  useEffect(() => {
    const getProfile = async () => {
      if (!userId) return;

      const cachedProfile = localStorage.getItem(`profile_${userId}`);
      if (cachedProfile) {
        setProfile(JSON.parse(cachedProfile));
        return;
      }

      const { data: profile, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", userId)
        .single();

      if (error) {
        console.log(error);
        return;
      }

      localStorage.setItem(`profile_${userId}`, JSON.stringify(profile));
      setProfile(profile);
    };

    getProfile();
  }, [userId]);

  return { profile };
};