/**
 * Creates a default logo image using canvas with the vendor name
 * @param text - The vendor name to display on the image
 * @returns Promise<Blob> - The generated image as a Blob
 */
export const createDefaultLogoImage = async (text: string): Promise<Blob | null> => {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 250;
    const ctx = canvas.getContext('2d');
    
    if (!ctx) return null;

    // Set background
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Configure text
    ctx.fillStyle = '#FFFFFF';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Calculate font size and wrap text
    const maxWidth = canvas.width * 0.8;
    let fontSize = 40;
    ctx.font = `500 ${fontSize}px Arial`;
    
    // Split text into lines if needed
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);

    // Calculate total height of text block
    const lineHeight = fontSize * 1.2;
    const totalTextHeight = (lines.length - 1) * lineHeight;
    
    // Calculate vertical center of canvas
    const canvasMiddle = canvas.height / 2;
    // Calculate starting Y position to center text block
    const startY = canvasMiddle - (totalTextHeight / 2);

    // Draw text lines
    lines.forEach((line, i) => {
      ctx.fillText(
        line,
        canvas.width / 2,
        startY + (i * lineHeight)
      );
    });

    // Convert canvas to blob
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');
    });
  } catch (error) {
    console.error('Error creating default logo:', error);
    return null;
  }
}; 