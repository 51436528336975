import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  banner9,
  banner10,
  banner11,
  banner12
} from "../../assets";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Announcements, Calendar, QuickLinks, Search } from "./Components";
import getPages from "../../utils/getPages";
import supabaseConnect from "../../utils/supabaseConnect";
import { title } from "process";
import AnnouncementModal from "../announcement/AnnouncementModal";
import CreateNewFooterMenu from "../../components/CreateNewFooterMenu";
import MainLayoutWrapper from "../../components/Layout/MainLayoutWrapper";
import NewEventModal from "../../components/Events/NewEventModal";
import EventModal from "../../components/Events/EventModal";
import { removeDiacritics, calculateRelevanceScore } from '../../utils/searchUtils';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSkeleton from "../../components/Loading/CarouselSkeleton";
import CalendarSkeleton from "../../components/Loading/CalendarSkeleton";

interface page {
  id: number;
  title: string;
  description: string;
  body: string;
  cover_image: string;
  created_at: string;
  tag: string;
  tag_id: number;
}

interface AnnouncementData {
  id: string;
  title: string;
  description: string;
  created_at: string;
  info: string;
  cover_image: string;
}

// Update this constant for banner images with focus points
const bannerImages = [
  { src: banner1, focusPoint: "center 30%" },
  { src: banner2, focusPoint: "center 50%" },
  { src: banner3, focusPoint: "center 50%" },
  { src: banner4, focusPoint: "center 30%" },
  { src: banner5, focusPoint: "center 50%" },
  { src: banner6, focusPoint: "center 50%" },
  { src: banner7, focusPoint: "center 45%" },
  { src: banner8, focusPoint: "center 50%" },
  { src: banner9, focusPoint: "center 60%" },
  { src: banner10, focusPoint: "center 50%" },
  { src: banner11, focusPoint: "center 60%" },
  { src: banner12, focusPoint: "center 50%" }
];

const Dashboard: FunctionComponent = () => {
  const supabase = supabaseConnect();
  const [pages, setPages] = useState([]) as any[];
  const [users, setUsers] = useState(null) as any;
  const [tags, setTags] = useState([]) as any[];
  let pages1: any[] = [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState<
    AnnouncementData[]
  >([]);
  const [query, setQuery] = useState("");
  const [profile, setProfile] = useState(null) as any[];
  const [isAdmin, setIsAdmin] = useState(false) as any[];
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]) as any[];
  const [eventData, setEventData] = useState([]) as any[];
  const [activeEvent, setActiveEvent] = useState([]) as any[];
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [beingEdited, setBeingEdited] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [isWide, setIsWide] = useState(false);
  const navigate = useNavigate();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);

  const checkAdmin = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user?.id;

    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", userId);

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      setIsAdmin(true);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const { data: user, error } = await supabase.auth.getSession();
      if (error) return console.log(error);
      if (!user) {
        return (window.location.href = "/login");
      }
      setUsers(user);
      if (!user.session) {
        return (window.location.href = "/login");
      }
      console.log(users?.session?.user.id);
      return user;
    };
    const getProfile = async (id: string) => {
      const { data: profile, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", id);
      setProfile(profile);
      if (error) return console.log(error);
      setProfile(profile);
      return profile;
    };
    const checkActive = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      const userId = user?.id;

      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", userId);
      if (error) return console.log(error);
      if (data?.[0].active === false || !data?.[0].active) {
        window.location.href = "/inactive";
      }
    };
    const fetchPages = async () => {
      const pages = await getPages();
      setPages(pages);
      fetchTags(pages);
    };
    const fetchTags = async (pages: page[]) => {
      const { data: tags, error } = await supabase.from("tag").select("*");

      if (error) return console.log(error);

      const pagesWithTags = pages.map((page: page) => {
        let pageTag = page.tag_id
          ? tags.find((tag: any) => page.tag_id === tag.id)
          : null;
        return { ...page, tag: pageTag ? pageTag.tag_name : null };
      });

      setPages(pagesWithTags);

      if (!tags) return console.log("no tags found");
    };

    const runGetFunctions = async () => {
      const user = await getUser();
      if (typeof user === "object" && user !== null && user.session) {
        const userId = user.session.user.id;
        await fetchPages();

        await getProfile(userId);
      }
    };
    const getEvents = async () => {
      setIsLoadingEvents(true);
      const { data, error } = await supabase
        .from("event")
        .select("*")
        .order("id", { ascending: false });
      if (error) console.error("Error fetching events: ", error);
      // order by start_date
      const sortedData = data?.sort((a: any, b: any) => {
        return (
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        );
      });
      // filter out events that have already passed
      const currentDate = new Date();
      let filteredData = sortedData?.filter((event: any) => {
        return new Date(event.start_date) >= currentDate;
      });

      // slice the first 3 events
      filteredData = filteredData?.slice(0, 6);

      // run through the data and shorten the title to 20 characters
      filteredData = filteredData?.map((event: any) => {
        if (event.title.length > 20) {
          event.title = event.title.substring(0, 15) + "...";
        }
        return event;
      });

      setEventData(filteredData);
      setIsLoadingEvents(false);
      return data;
    };
    const runFunctions = async () => {
      await runGetFunctions();
      await checkAdmin();
      await checkActive();
      await getEvents();
    };
    runFunctions();
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = bannerImages.map((image) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = image.src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(imagePromises);
        setImagesLoaded(true);
      } catch (error) {
        console.error("Error loading images:", error);
        setImagesLoaded(true); // Set to true even on error to show something
      }
    };

    loadImages();
  }, []);

  const search = async () => {
    try {
      if (!query.trim()) return;

      const normalizedQuery = removeDiacritics(query.toLowerCase());

      const searchResults = pages.map((page: page) => {
        const score = calculateRelevanceScore(normalizedQuery, page.title, page.description);
        return { ...page, score };
      });

      // Sort results by score in descending order
      searchResults.sort((a: any, b: any) => b.score - a.score);

      // Filter out results with a score of 0
      const filteredResults = searchResults.filter((result: any) => result.score > 0);

      // Store the search results in localStorage
      localStorage.setItem('searchResults', JSON.stringify(filteredResults));

      // Navigate to the search results page using React Router
      navigate(`/search?q=${encodeURIComponent(query)}`);
    } catch (error) {
      console.error("Search failed: ", error);
    }
  };

  const handleTrainingPageClick = () => {
    return () => {
      window.location.href = "/training";
    };
  };

  const handleREACTrainingCoursesClick = () => {
    return () => {
      window.open("https://portal.realcommunity.com/courses/library-v2", "_blank");
    };
  };

  const handleResourcePageClick = () => {
    return () => {
      window.location.href = "/page";
    };
  };

  // Remove handleChatBotClick and handleMarketingPageClick functions as they're no longer needed

  const handleLoadMore = async () => {
    window.location.href = "/events";
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 1500);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styling = {
    border: '1px solid',
    borderImage: 'linear-gradient(45deg, #3a3a3a, #4a4a4a) 1',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    maxHeight: '29rem',
  }

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <div className="flex w-screen h-screen overflow-hidden text-left text-white">
      <div id="modal-root"></div>
      <Sidebar />
      <AnnouncementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        activeAnnouncment={activeAnnouncement}
      ></AnnouncementModal>
      <NewEventModal
        showModal={showEventModal}
        setShowModal={setShowEventModal}
        setList={setList}
        setEventData={setEventData}
        activeEvent={activeEvent}
      />
      <EventModal
        isOpen={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        activeEvent={activeEvent}
        setBeingEdited={setBeingEdited}
        setShowEventModal={setShowEventModal}
        setActiveEvent={setActiveEvent}
      />
      <MainLayoutWrapper>
        <div className="sm:p-5 p-2 flex flex-col max-h-full">
          {/* Carousel */}
          <div className="relative -mt-4">
            {imagesLoaded ? (
              <Slider {...carouselSettings}>
                {bannerImages.map((image, index) => (
                  <div key={index} className="w-full">
                    <img
                      src={image.src}
                      alt={`Banner ${index + 1}`}
                      className="w-full h-auto max-h-[23rem] sm:max-h-[23rem] md:max-h-[23rem] lg:max-h-[23rem] object-contain"
                      style={{ objectPosition: image.focusPoint }}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <CarouselSkeleton />
            )}
          </div>

          <div className="flex flex-wrap m-auto w-full pt-4 gap-4">
            <div className="w-full xl:w-[calc(33.33%-1rem)] lg:w-[calc(50%-0.5rem)] flex-grow" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}>
              <div className="flex-grow h-full">
                <QuickLinks
                  handleREACTrainingCoursesClick={handleREACTrainingCoursesClick()}
                  handleResourcePageClick={handleResourcePageClick()}
                />
              </div>
            </div>

            <div className="w-full xl:w-[calc(33.33%-1rem)] lg:w-[calc(50%-0.5rem)] flex-grow" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }}>
              <div className="flex-grow h-full">
                <Announcements
                  setIsModalOpen={setIsModalOpen}
                  setActiveAnnouncement={setActiveAnnouncement}
                  activeAnnouncement={activeAnnouncement}
                />
              </div>
            </div>

            <div className="w-full xl:w-[calc(33.33%-1rem)] lg:w-full flex-grow sm:mb-5 mb-2">
              <div className="flex-grow border rounded-lg text-base bg-black p-4 overflow-y-auto pr-[2.5rem] min-h-[29rem] max-h-[29rem]">
                <h2 className="text-white font-thin mb-[2rem]">
                  Community Annual Calendar
                </h2>
                <div className="flex flex-col gap-5">
                  {isLoadingEvents ? (
                    <CalendarSkeleton />
                  ) : (
                    eventData.slice(0, 3).map((event: any) => (
                      <Calendar
                        eventData={event}
                        setActiveEvent={setActiveEvent}
                        activeEvent={activeEvent}
                        setIsEventModalOpen={setIsEventModalOpen}
                        key={event.id}
                      />
                    ))
                  )}
                </div>
                <div
                  onClick={handleLoadMore}
                  className="text-white pl-[1.2rem] text-[.8rem] cursor-pointer pt-[1.9rem]"
                >
                  Load More
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default Dashboard;
