export interface SearchResult {
  id: string;
  title: string;
  description: string;
  body: string;
  table_name: string;
  // Add any other relevant fields
}

export function orderByRelevance(results: SearchResult[], searchTerm: string): SearchResult[] {
  const searchTerms = removeDiacritics(searchTerm.toLowerCase()).split(' ');

  return results
    .map(result => ({
      ...result,
      relevanceScore: calculateDetailedRelevanceScore(result, searchTerms)
    }))
    .sort((a, b) => b.relevanceScore - a.relevanceScore);
}

function calculateDetailedRelevanceScore(item: SearchResult, searchTerms: string[]): number {
  let score = 0;

  const normalizedTitle = removeDiacritics(item.title.toLowerCase());
  const normalizedDescription = removeDiacritics(item.description.toLowerCase());
  const normalizedBody = removeDiacritics(item.body.toLowerCase());

  // Exact match bonuses
  if (normalizedTitle.includes(searchTerms.join(' '))) score += 50;
  if (normalizedDescription.includes(searchTerms.join(' '))) score += 30;
  if (normalizedBody.includes(searchTerms.join(' '))) score += 20;

  // Individual term matches
  searchTerms.forEach(term => {
    if (term.length > 2) {
      if (normalizedTitle.includes(term)) score += 10;
      if (normalizedDescription.includes(term)) score += 5;
      if (normalizedBody.includes(term)) score += 3;
    }
  });

  // Boost score based on content type
  switch (item.table_name) {
    case 'page':
      score *= 1.2; // 20% boost for pages
      break;
    case 'announcement':
      score *= 1.1; // 10% boost for announcements
      break;
    // Add other content types as needed
  }

  // Normalize score (0-100 range)
  return Math.min(Math.round(score), 100);
}

export function removeDiacritics(str: string): string {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function getTopResults(results: SearchResult[], n: number): SearchResult[] {
  return results.slice(0, n);
}

// Keep the existing calculateRelevanceScore function
export function calculateRelevanceScore(query: string, title: string, description: string): number {
  const normalizedTitle = removeDiacritics(title.toLowerCase());
  const normalizedDescription = removeDiacritics(description.toLowerCase());

  let score = 0;

  // Check for exact matches
  if (normalizedTitle.includes(query)) score += 10;
  if (normalizedDescription.includes(query)) score += 5;

  // Check for partial matches
  const words = query.split(' ');
  words.forEach(word => {
    if (word.length > 2) {
      if (normalizedTitle.includes(word)) score += 3;
      if (normalizedDescription.includes(word)) score += 2;
    }
  });

  return score;
}

/**
 * Calculates a relevance score for a search result
 * @param {any} item - The search result item
 * @param {string} searchTerm - The search term
 * @returns {number} The relevance score
 */
export function newCalculateRelevanceScore(item: any, searchTerm: string): number {
  let score = 0;
  const lowerSearchTerm = searchTerm.toLowerCase();

  // Check title (highest priority)
  if (item.title && item.title.toLowerCase().includes(lowerSearchTerm)) {
    score += 10;
    if (item.title.toLowerCase() === lowerSearchTerm) score += 5;
  }

  // Check description
  if (item.description && item.description.toLowerCase().includes(lowerSearchTerm)) {
    score += 5;
    if (item.description.toLowerCase() === lowerSearchTerm) score += 3;
  }

  // Check body
  if (item.body && item.body.toLowerCase().includes(lowerSearchTerm)) {
    score += 3;
    if (item.body.toLowerCase() === lowerSearchTerm) score += 2;
  }

  // Boost score for exact matches
  if (item.title && item.title.toLowerCase() === lowerSearchTerm) score += 10;
  if (item.description && item.description.toLowerCase() === lowerSearchTerm) score += 5;
  if (item.body && item.body.toLowerCase() === lowerSearchTerm) score += 3;

  return score;
};
