import React from 'react';
import * as images from "../../../assets";
import { Link } from 'react-router-dom';

interface ProfileSectionProps {
  profile: any;
  onSignOut: () => void;
  onProfileClick: () => void;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  isAdmin: boolean;
  onAdminStaffClick: () => void;
  isStaff: boolean;
}

/**
 * ProfileSection component for displaying user profile information in the sidebar.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {Profile | null} props.profile - User profile data
 * @param {() => void} props.onSignOut - Function to handle sign out action
 * @param {() => void} props.onProfileClick - Function to handle profile click action
 * @param {boolean} props.isDropdownOpen - Whether the dropdown is open
 * @param {() => void} props.toggleDropdown - Function to toggle the dropdown
 * @param {boolean} props.isAdmin - Whether the user is an admin
 * 
 * @example
 * <ProfileSection profile={userProfile} onSignOut={handleSignOut} onProfileClick={handleProfileClick} isDropdownOpen={isProfileDropdownOpen} toggleDropdown={toggleProfileDropdown} isAdmin={isAdmin} />
 * 
 * @remarks
 * If this component is missing, users would not be able to see their profile information in the sidebar,
 * access their profile page, or sign out of the application.
 */
const ProfileSection: React.FC<ProfileSectionProps> = ({ 
  profile, 
  onSignOut, 
  onProfileClick, 
  isDropdownOpen, 
  toggleDropdown,
  isAdmin,
  onAdminStaffClick,
  isStaff
}) => {
  return (
    <div className={`transition-all duration-300 ease-in-out ${isDropdownOpen ? 'mb-10' : ''}`}>
      <div 
        className="flex flex-row items-center justify-start gap-[.8rem] text-left text-base cursor-pointer p-4"
        onClick={toggleDropdown}
      >
        <img
          className="rounded-[50%] w-10 h-10 object-cover"
          alt=""
          src={profile?.profile_image_url || "https://via.placeholder.com/150"}
        />
        <div className="overflow-hidden flex-grow">
          <p className="m-0 font-sm word-clip">
            {profile.first_name && profile.last_name
              ? `${profile.first_name} ${profile.last_name}`
              : ""}
          </p>
          <p className="m-0 text-[10px] leading-[16px] word-clip">
            {profile?.email}
          </p>
        </div>
        <img 
          className={`w-4 h-4 transition-transform duration-200 ${isDropdownOpen ? 'rotate-180' : ''}`}
          src={images.chevron_right} 
          alt="expand"
        />
      </div>
      
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isDropdownOpen ? 'max-h-32 pb-2' : 'max-h-0'}`}>
        <button 
          onClick={onProfileClick}
          className="w-full text-left px-4 py-2 hover:bg-black transition-colors duration-200 bg-[#2d3035] text-white cursor-pointer"
        >
          My Profile
        </button>
        {(isAdmin || isStaff) && (
          <>
          <button 
            onClick={onAdminStaffClick}
          className="w-full text-left px-4 py-2 hover:bg-black transition-colors duration-200 bg-[#2d3035] text-white cursor-pointer"
        >
          {isAdmin ? 'Admin' : 'Staff'} Dashboard
        </button>
        </>
        )}
        <button 
          onClick={onSignOut}
          className="w-full text-left px-4 py-2 hover:bg-black transition-colors duration-200 bg-[#2d3035] text-white cursor-pointer"
        >
          Sign Out
          </button>
       
      </div>
    </div>
  );
};

export default ProfileSection;