import React, { useEffect, useState } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import { communityLogo1 } from "../../assets/index";

export interface CardProps {
  cover_image: string;
  title: string;
  description: string;
  id: number;
  tag_id: number;
  tag: string;
  docId?: string;
  tag_name?: string;  // Make this optional
  link?: string;  // Make this optional
  parent_directory: string;
  children?: CardProps[];
  body?: string;
  }

const Card: React.FC<CardProps & { onCardClick: (item: CardProps) => void }> = ({
  cover_image,
  title,
  description,
  id,
  tag,
  docId,
  link,
  children,
  onCardClick,
}) => {
  const [tagName, setTagName] = useState<string | null>(null);

  const getTag = async (tag_name: string) => {
    const supabase = supabaseConnect();
    const { data: tag, error } = await supabase
      .from("tag")
      .select("*")
      .eq("tag_name", tag_name);

    if (error) return console.log(error);
    setTagName(tag_name);
  };

  useEffect(() => {
    getTag(tag);
  }, [tag]);

  console.log(cover_image, "cover_image");

  return (
    <div
      onClick={() => onCardClick({ cover_image, title, description, id, tag_id: 0, tag, docId, tag_name: "", link, parent_directory: "", children })}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden h-[360px] bg-black cursor-pointer"
    >
      <div className="h-36 flex-shrink-0 bg-white" style={{ border: '1px solid #D1D5DB' }}>
        <img
          className="h-full w-full object-cover"
          src={cover_image}
          alt=""
        />
      </div>
      <div className="flex flex-col justify-between flex-grow p-3">
        <div>
          <p className="text-sm font-medium text-tourquoise mb-1">
            {tagName}
          </p>
          <h3
            className="text-xl font-semibold text-white mb-2"
            style={{
              fontSize: title.length > 20 ? '1rem' : '1.2rem', // Larger font size for title
              overflow: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: '#4A5568 #2D3748',
            }}
          >
            {title}
          </h3>
          <p
            className="text-sm text-white"
            style={{
              fontSize: description.length > 100 ? '0.8rem' : '0.9rem', // Smaller font size for description
              overflow: 'auto',
              scrollbarWidth: 'thin',
              scrollbarColor: '#4A5568 #2D3748',
            }}
          >
            {description}
          </p>
        </div>
        <div className="flex justify-end text-[.8rem] text-white mt-2">
          {children ? "View Resources" : "View Page"}<span>&rarr;</span>
        </div>
      </div>
    </div>
  );
};

export interface CardGridProps {
  data: CardProps[];
  onCardClick: (item: CardProps) => void;
}

const CardGrid: React.FC<CardGridProps> = ({
  data,
  onCardClick
}) => {
  return (
    <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {data.map((card, index) => (
        <Card key={index} {...card} onCardClick={onCardClick} />
      ))}
    </div>
  );
};

export default CardGrid;
