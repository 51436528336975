import React from 'react';
import { FaPlus, FaSave } from 'react-icons/fa';

interface WorkflowToolbarProps {
  onAddNode: () => void;
  onSave: () => Promise<void>;
}

export const WorkflowToolbar: React.FC<WorkflowToolbarProps> = ({ onAddNode, onSave }) => {
  return (
    <div className="flex items-center gap-4 p-4 border-b border-gray-700">
      <button
        onClick={onAddNode}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-communityBlue rounded-md hover:bg-blue-600 transition-colors"
      >
        <FaPlus className="w-4 h-4" />
        Add Node
      </button>
      
      <button
        onClick={onSave}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-300 bg-gray-700 rounded-md hover:bg-gray-600 transition-colors"
      >
        <FaSave className="w-4 h-4" />
        Save Workflow
      </button>
    </div>
  );
}; 