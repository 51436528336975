import React from 'react';

const CarouselSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      <div className="bg-gray-300 h-[20rem] w-full rounded-md"></div>
      <div className="flex justify-center mt-4">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="h-2 w-2 bg-gray-300 rounded-full mx-1"></div>
        ))}
      </div>
    </div>
  );
};

export default CarouselSkeleton;
