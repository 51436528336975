import React from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { FaPencilAlt } from 'react-icons/fa';

interface CustomNodeData extends Record<string, any> {
  label: string;
  onConfigClick?: (evt: React.MouseEvent) => void;
}

export const CustomNode: React.FC<NodeProps<CustomNodeData>> = ({ data, selected }) => {
  const handleConfigClick = (evt: React.MouseEvent) => {
    // Stop propagation to prevent node selection
    evt.stopPropagation();
    data.onConfigClick?.(evt);
  };

  return (
    <div 
      className={`
        relative px-4 py-2 shadow-lg rounded-md border-2 transition-all duration-200
        node-drag-handle cursor-move group w-48
        ${selected 
          ? 'bg-gray-800 border-blue-500 shadow-blue-500/25' 
          : 'bg-white border-gray-200 hover:border-gray-300'
        }
      `}
    >
      <Handle 
        type="target" 
        position={Position.Top} 
        className={`w-3 h-3 !bg-gray-700 ${selected ? '!bg-blue-500' : ''}`}
      />
      
      <div className="flex items-center justify-center w-full">
        {/* We'll add icons later based on node type */}
        <span className={`text-sm font-medium truncate text-center ${selected ? 'text-white' : 'text-gray-800'}`}>
          {data.label}
        </span>
      </div>

      {/* Config button */}
      <div
        onClick={handleConfigClick}
        className={`
          absolute -top-1 right-0.5
          ${selected 
            ? 'text-gray-300 hover:text-white' 
            : 'text-gray-400 hover:text-gray-600'
          }
        `}
        aria-label="Configure node"
      >
        <FaPencilAlt className="w-2 h-2 cursor-pointer" />
      </div>
      
      <Handle 
        type="source" 
        position={Position.Bottom} 
        className={`w-3 h-3 !bg-gray-700 ${selected ? '!bg-blue-500' : ''}`}
      />
    </div>
  );
}; 