import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface ConfigurationPanelProps {
  title: string;
  onClose: () => void;
  onSave: () => void;
  isValid: boolean;
  children: React.ReactNode;
}

export const ConfigurationPanel: React.FC<ConfigurationPanelProps> = ({
  title,
  onClose,
  onSave,
  isValid,
  children,
}) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b border-gray-700 flex-shrink-0">
        <h2 className="text-lg font-semibold text-white">{title}</h2>
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-white transition-colors"
        >
          <FaTimes />
        </button>
      </div>
      
      <div className="flex-1 overflow-y-auto p-4 min-h-0">
        <div className="space-y-4">
          {children}
        </div>
      </div>

      <div className="p-4 border-t border-gray-700 flex justify-end gap-2 flex-shrink-0">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm text-gray-300 hover:text-white transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={onSave}
          disabled={!isValid}
          className={`px-4 py-2 text-sm rounded-md ${
            isValid
              ? 'bg-communityBlue text-white hover:bg-blue-600'
              : 'bg-gray-600 text-gray-300 cursor-not-allowed'
          } transition-colors`}
        >
          Save
        </button>
      </div>
    </div>
  );
}; 