import React, { useState, useEffect } from 'react';
import {
  FaRobot,
  FaFileAlt,
  FaCalendarAlt,
  FaEnvelope,
  FaDatabase,
  FaUsers,
  FaComments,
  FaCog,
  FaSearch,
  FaSpinner,
  FaCheckSquare
} from 'react-icons/fa';
import { Link } from 'react-router-dom';


interface ToolItem {
  icon: React.ComponentType;
  title: string;
  description: string;
  link: string;
  category?: string;
  disabled?: boolean;
}

interface ToolCardProps extends ToolItem {
  icon: React.ComponentType;
}

const ToolCard: React.FC<ToolCardProps> = ({ icon: Icon, title, description, link, disabled }) => (
  <div 
    className={`relative bg-[#100f0f] text-[#f1f2f6] border-2 border-[#2a2828] rounded-lg p-4 transition-all duration-300 
      ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:transform hover:-translate-y-1 hover:shadow-lg cursor-pointer'} 
      h-full group`}
  >
    <div className="flex items-center gap-4">
      <div className={`bg-communityBlue rounded-lg p-4 flex items-center justify-center text-[#f1f2f6] ${disabled ? 'opacity-50' : ''}`}>
        <Icon />
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-1">{title}</h3>
        <p className="text-sm text-[#f1f2f6]/70">{description}</p>
      </div>
    </div>
    {disabled && (
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black/60 rounded-lg">
        <span className="text-white text-sm px-4 py-2">Tool not yet available</span>
      </div>
    )}
  </div>
);

const ToolsDashboard: React.FC = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredTools, setFilteredTools] = useState<ToolItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const tools: ToolItem[] = [
    {
      icon: FaEnvelope,
      title: "Daily Updates Submission",
      description: "Create and submit updates to be sent out to the team daily",
      link: "/staff-admin/tools/daily-email-form", 
      category: "Updates"
    },
    {
      icon: FaCheckSquare,
      title: "Daily Updates Approval",
      description: "Review and approve daily updates before they are sent out",
      link: "/staff-admin/daily-updates-approval",
      category: "Updates"
    },
    {
      icon: FaFileAlt,
      title: "Email Template Builder",
      description: "Create and customize email templates using a drag-and-drop interface",
      link: "/staff-admin/email-templates",
      category: "Email",
      disabled: true
    },
    {
      icon: FaRobot,
      title: "Workflow Automation",
      description: "Create and manage automated workflows with a visual builder",
      link: "/staff-admin/tools/workflow-automation",
      category: "Automation",
      disabled: true
    }
  ];

  const categories = ['All', ...Array.from(new Set(tools.map(tool => tool.category)))];


  useEffect(() => {
    const filtered = tools.filter(tool => 
      (selectedCategory === 'All' || tool.category === selectedCategory) &&
      (tool.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       tool.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredTools(filtered);
  }, [searchTerm, selectedCategory]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (category: string | undefined) => {
    setSelectedCategory(category || 'All');
  };

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
    if (!isSearchExpanded) {
      // Focus the input when expanding
      setTimeout(() => {
        const input = document.getElementById('search-input');
        if (input) input.focus();
      }, 100);
    }
  };

  if (isLoading) {
    return (
      <div className="flex-grow bg-[#100f0f] text-[#f1f2f6] flex items-center justify-center">
        <FaSpinner className="animate-spin text-4xl" />
      </div>
    );
  }


  return (
    <div className="flex-grow bg-[#100f0f] text-[#f1f2f6] p-4 mr-[2rem]">
      <header className="bg-[#100f0f] border-b border-[#2a2828]">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-3xl font-semibold"></h1>
          <div className={`relative overflow-hidden transition-all duration-300 ease-in-out ${isSearchExpanded ? 'w-64' : 'w-10'} h-10 bg-[#f1f2f6]/15 hover:bg-[#f1f2f6]/25 mr-[1rem]`}>
            <input
              id="search-input"
              type="text"
              placeholder="Search tools..."
              value={searchTerm}
              onChange={handleSearch}
              className={`bg-transparent text-[#f1f2f6] pl-4 pr-10 w-full h-full ${isSearchExpanded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
            />
            <button
              onClick={toggleSearch}
              className="absolute right-0 top-0 w-10 h-10 flex items-center justify-center text-[#f1f2f6] hover:text-communityBlue transition-colors duration-300 bg-[#2a2828] cursor-pointer"
            >
              <FaSearch className="text-xl" />
            </button>
          </div>
        </div>
      </header>
      <main className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-4xl font-bold mb-2">Community Tools</h1>
          <p className="text-xl text-[#f1f2f6]/70 pr-[1rem]">
            Access Community's tools and automations in one place
          </p>
        </div>
        
        <div className="mb-6 flex flex-wrap gap-2">
          {categories.map((category, index) => (
            <button
              key={index}
              onClick={() => handleCategoryChange(category)}
              className={`px-4 py-2 rounded-full text-sm cursor-pointer ${
                selectedCategory === category
                  ? 'bg-communityBlue text-[#f1f2f6]'
                  : 'bg-[#f1f2f6]/15 text-[#f1f2f6]/70 hover:bg-[#f1f2f6]/25'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {filteredTools.length > 0 ? (
            filteredTools.map((tool, index) => (
              tool.disabled ? (
                <div key={index}>
                  <ToolCard {...tool} />
                </div>
              ) : (
                <Link to={tool.link} key={index} className="no-underline">
                  <ToolCard {...tool} />
                </Link>
              )
            ))
          ) : (
            <p className="col-span-full text-center text-xl text-[#f1f2f6]/70">
              No tools found matching your criteria.
            </p>
          )}
        </div>
      </main>
    </div>
  );
};

export default ToolsDashboard;