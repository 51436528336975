import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStaffAdminUser } from '../hooks/useStaffAdminUser';
import LoadingSpinner from '../../../components/Loading/LoadingSpinner';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAdmin?: boolean;
  requireStaff?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requireAdmin = false,
  requireStaff = false
}) => {
  const { user, isAdmin, isStaff, isLoading, error } = useStaffAdminUser();

  console.log('ProtectedRoute State:', {
    loadingState: isLoading,
    user: {
      id: user?.id,
      exists: !!user
    },
    permissions: {
      isAdmin,
      isStaff
    },
    requirements: {
      requireAdmin,
      requireStaff
    },
    error
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    console.error('Permission check error:', error);
    return <Navigate to="/login" replace />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const hasPermission = 
    (!requireAdmin && !requireStaff) || // No special permissions required
    (isAdmin) || // Admin can access everything
    (requireStaff && isStaff); // Staff can access staff-only routes

  if (!hasPermission) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;