import { FunctionComponent, useState, useEffect } from "react";
import { FaChevronDown } from 'react-icons/fa';
import supabaseConnect from "../../../utils/supabaseConnect";
import QuickLinksSkeleton from "../../../components/Loading/QuickLinksSkeleton";

interface QuickLinksProps {
  handleREACTrainingCoursesClick: () => void;
  handleResourcePageClick: () => void;
}

interface QuickLink {
  id: number;
  name: string;
  link: string;
  parent_directory: string;
}

const QuickLinks: FunctionComponent<QuickLinksProps> = ({
  handleREACTrainingCoursesClick,
  handleResourcePageClick,
}) => {
  const [showRealDropdown, setShowRealDropdown] = useState(false);
  const [showCommunityDropdown, setShowCommunityDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quickLinks, setQuickLinks] = useState<QuickLink[]>([]);
  const supabase = supabaseConnect();

  useEffect(() => {
    const fetchQuickLinks = async () => {
      const { data, error } = await supabase
        .from('link')
        .select('*')
        .eq('tag', 'QuickLink');

      if (error) {
        console.error('Error fetching quick links:', error);
        return;
      }

      setQuickLinks(data || []);
      setLoading(false);
    };

    fetchQuickLinks();
  }, []);

  const getLinksForCategory = (category: string) => {
    return quickLinks.filter(link => link.parent_directory === category);
  };

  const renderDropdown = (category: string, show: boolean) => (
    <div 
      className={`overflow-hidden transition-all duration-300 ease-in-out ${
        show ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
      }`}
    >
      <div className="rounded-md shadow-lg mt-2">
        {getLinksForCategory(category).map((link) => (
          <a
            key={link.id}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              borderTop: ".5px solid #08C2F8",
            }}
            className="block px-4 py-2 text-sm text-white hover:bg-communityBlue no-underline"
          >
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );

  if (loading) {
    return <QuickLinksSkeleton />;
  }

  return (
    <div className="md:min-h-[29rem] min-h-80 border rounded-lg text-base bg-black p-4">
      <h2
        style={{
          borderBottom: "2px solid #08C2F8",
        }}
        className="text-white font-thin max-lg:m-0 pb-[1rem] mb-2"
      >
        Quick Links
      </h2>
      <div className="block pb-[1rem] h-[23rem] overflow-y-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4A5568 #2D3748' }} >
        <div className="relative cursor-pointer pt-2 pb-2">
          <p 
            className="m-0 font-semibold hover:text-communityBlue flex items-center justify-between"
            onClick={() => setShowCommunityDropdown(!showCommunityDropdown)}
          >
            Community
            <FaChevronDown className={`w-4 h-4 transition-transform duration-300 ${showCommunityDropdown ? 'transform rotate-180' : ''}`} />
          </p>
          {renderDropdown('Community', showCommunityDropdown)}
        </div>
        <div
          style={{
            borderBottom: "2px solid #08C2F8",
          }}
          className="pb-[1rem] mb-2"
        />
        <div className="relative cursor-pointer pt-2 pb-2">
          <p 
            className="m-0 font-semibold hover:text-communityBlue flex items-center justify-between"
            onClick={() => setShowRealDropdown(!showRealDropdown)}
          >
            REAL
            <FaChevronDown className={`w-4 h-4 transition-transform duration-300 ${showRealDropdown ? 'transform rotate-180' : ''}`} />
          </p>
          {renderDropdown('REAL', showRealDropdown)}
        </div>
        <div
          style={{
            borderBottom: "2px solid #08C2F8",
          }}
          className="pb-[1rem] mb-2"
        />
        <div
          className="relative cursor-pointer py-2 pb-[2rem] hover:text-communityBlue"
          style={{
            borderBottom: "2px solid #08C2F8",
          }}
          onClick={handleREACTrainingCoursesClick}
        >
          <p className="m-0 font-semibold">REAC Training Courses</p>
        </div>
        <div
          className="relative cursor-pointer py-2 pb-[2rem] hover:text-communityBlue"
          style={{
            borderBottom: "2px solid #08C2F8",
          }}
          onClick={handleResourcePageClick}
        >
          <p className="m-0 font-semibold">Resources</p>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
