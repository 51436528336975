import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../Sidebar/hooks/useUser';
import { useProfile } from '../Sidebar/hooks/useProfile';

const SupportForm: React.FC = () => {
  const { user } = useUser();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectFocused, setSubjectFocused] = useState(false);
  const [messageFocused, setMessageFocused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error' | null>(null);
  const { profile } = useProfile(user?.id);
  const fullName = profile?.first_name && profile?.last_name ? `${profile.first_name} ${profile.last_name}` : 'Unknown';

  useEffect(() => {
    // Reset submit status when component mounts
    setSubmitStatus(null);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setSubmitStatus('error');
      return;
    }
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await axios.post('https://realcommunityportalbackend-copy-production.up.railway.app/api/email/send-support', {
        name: fullName,
        email: user.email,
        subject,
        message
      });

      setSubmitStatus('success');
      setSubject('');
      setMessage('');
    } catch (error) {
      console.error('Error sending support request:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 40) {
      setSubject(value);
    }
  };

  return (
    <div className="p-4 bg-gray-900 text-gray-100">
      <h3 className="text-xl font-semibold mb-4">Contact Support</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="mx-1 mr-4">
          <div className="relative mb-4">
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={handleSubjectChange}
              onFocus={() => setSubjectFocused(true)}
              onBlur={() => setSubjectFocused(false)}
              className="w-full p-2 pt-6 rounded bg-gray-800 text-gray-100 border border-gray-700 focus:outline-none focus:border-blue-500 text-sm peer"
              required
            />
            <label
              htmlFor="subject"
              className={`absolute left-2 transition-all duration-200 text-sm ${
                subjectFocused || subject ? 'top-1 text-xs text-blue-500' : 'top-4 text-gray-400'
              }`}
            >
              What is your query about?
            </label>
            <div className="absolute right-2 bottom-2 text-xs text-gray-400">
              {subject.length}/40
            </div>
          </div>
          <div className="relative">
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onFocus={() => setMessageFocused(true)}
              onBlur={() => setMessageFocused(false)}
              className="w-full p-2 pt-6 rounded bg-gray-800 text-gray-100 border border-gray-700 focus:outline-none focus:border-blue-500 text-sm h-32 resize-none peer"
              required
            ></textarea>
            <label
              htmlFor="message"
              className={`absolute left-2 transition-all duration-200 text-sm ${
                messageFocused || message ? 'top-1 text-xs text-blue-500' : 'top-4 text-gray-400'
              }`}
            >
              How can we help? Please provide as much detail as possible.
            </label>
          </div>
          <button
            type="submit"
            disabled={isSubmitting || !user}
            className={`w-full bg-gray-700 hover:bg-communityBlue text-gray-100 font-bold py-2 px-4 rounded transition duration-300 cursor-pointer mt-4 ${
              isSubmitting || !user ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </div>
      </form>
      {submitStatus === 'success' && (
        <p className="text-green-500 mt-2">Your message has been sent successfully!</p>
      )}
      {submitStatus === 'error' && (
        <p className="text-red-500 mt-2">There was an error sending your message. Please try again.</p>
      )}
    </div>
  );
};

export default SupportForm;