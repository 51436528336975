import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { magnifyingglass } from "../../../assets";
import CardGrid from "./UsersCards";
import supabaseConnect from "../../../utils/supabaseConnect";
import MainLayoutWrapper from "../../../components/Layout/MainLayoutWrapper";
import { FaUserCheck, FaEllipsisV, FaUserEdit, FaTrash, FaUserSlash, FaUserPlus } from "react-icons/fa";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import { useDebounce } from "../../../hooks/useDebounce";
import { FaChevronLeft, FaChevronRight, FaSearch, FaPlus } from "react-icons/fa";
import { Menu, Transition } from '@headlessui/react';
import AddUserModal from '../../../components/User/AddUserModal';
import { formatLastActive } from '../../../utils/lastActive';

// Add types based on your schema
type Role = {
  id: number;
  role_name: string;
};

type ProfileRole = {
  id: number;
  user_id: string;
  role_id: number;
  role: Role;
};

type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_image_url: string | null;
  active: boolean;
  profile_roles: ProfileRole[];
  last_active: string | null;
  created_at: string;
};

// Role styling configuration
const ROLE_STYLES: Record<string, string> = {
  'Admin': 'bg-communityBlue/20 text-communityBlue',
};

// Add this helper function near the top of the file, after the types
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const Users = () => {
  const { query } = useParams<{ query: string }>();
  const [searchTerm, setSearchTerm] = useState(query);
  const supabase = supabaseConnect();
  const [data, setData] = useState([]) as any[];
  const [userAdmin, setUserAdmin] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Number of items to show per page
  const navigate = useNavigate();
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState<string | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Sort and paginate data
  const sortedData = [...data].sort((a, b) => 
    a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
  );
  
  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Generate page numbers array
  const getPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) {
      setUserAdmin(true);
      return true;
    } else return false;
  };

  const getTotalUsers = async () => {
    try {
      const { count, error } = await supabase
        .from('profiles')
        .select('*', { count: 'exact', head: true })
        .eq('active', showActive);

      if (error) throw error;
      setTotalUsers(count || 0);
    } catch (error) {
      console.error('Error fetching total users:', error);
      setTotalUsers(0);
    }
  };

  const search = async () => {
    try {
      setIsLoading(true);
      const searchQuery = debouncedSearchTerm?.toLowerCase().trim() || "";

      const { data: results, error } = await supabase
        .from("profiles")
        .select(`
          *,
          profile_roles:profile_role(
            id,
            role:role_id(
              id,
              role_name
            )
          )
        `)
        .eq("active", showActive)
        .or(`first_name.ilike.*${searchQuery}*,last_name.ilike.*${searchQuery}*`);

      if (error) throw error;
      
      setData(results || []);
      if (searchQuery) {
        setTotalUsers(results?.length || 0);
      } else {
        getTotalUsers();
      }
    } catch (error) {
      console.error("Search failed: ", error);
      setData([]);
      setTotalUsers(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Add function to render role badges
  const renderRoleBadges = (profileRoles: ProfileRole[]) => {
    return profileRoles?.map((profileRole) => {
      const roleName = profileRole.role?.role_name;
      const styleClass = ROLE_STYLES[roleName] || 'bg-gray-100/20 text-gray-400';

      return (
        <span
          key={profileRole.id}
          className={`px-2 py-1 text-xs rounded-full ${styleClass}`}
        >
          {roleName}
        </span>
      );
    });
  };

  const handleEditUser = (userId: string) => {
    navigate(`/staff-admin/users/edit/${userId}`);
  };

  const handleDeactivateUser = async (userId: string, currentActiveState: boolean) => {
    try {
      setIsDeactivating(true);
      
      const { error } = await supabase
        .from('profiles')
        .update({ active: !currentActiveState })
        .eq('id', userId);

      if (error) throw error;

      // Refresh the user list
      await search();
      
      console.log(`User ${currentActiveState ? 'deactivated' : 'activated'} successfully`);
    } catch (error) {
      console.error(`Error ${currentActiveState ? 'deactivating' : 'activating'} user:`, error);
    } finally {
      setIsDeactivating(false);
    }
  };

  const handleDeleteUser = async (userId: string, closeMenu: () => void) => {
    // First click - Set confirmation state
    if (deleteConfirmationId !== userId) {
      setDeleteConfirmationId(userId);
      // Reset confirmation after 3 seconds
      setTimeout(() => {
        setDeleteConfirmationId(null);
      }, 3000);
      return;
    }

    try {
      setIsDeleting(true);
      
      const { error } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);

      if (error) throw error;

      setDeleteConfirmationId(null);
      closeMenu(); // Only close menu after successful deletion
      await search();
      
      console.log('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  /**
   * Handles opening the add user modal
   * @returns void
   */
  const handleAddUser = () => {
    setIsAddModalOpen(true);
  };

  useEffect(() => {
    search();
  }, [debouncedSearchTerm, showActive]);

  return (
    <div className="flex flex-col h-full p-4 lg:p-4 mt-11 lg:mt-0 sm:px-4 sm:w-full md:-ml-11 sm:-ml-11 xs:-ml-11">
      <div className="mb-2">
        <div className="flex flex-col gap-1 mb-2">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-white">
            User management
          </h1>
        </div>
        <div className="flex items-center justify-between">
          <div 
            className="text-white font-medium flex items-center gap-2 cursor-pointer hover:text-gray-300 transition-colors duration-200"
            onClick={() => setShowActive(!showActive)}
          >
            All users <span className="text-gray-400 text-sm">{totalUsers}</span>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-[280px] xs:w-[40px] sm:w-[40px] md:w-[280px] h-9 px-8 rounded-md bg-white/5 border border-gray-700 text-white placeholder:text-gray-400 focus:outline-none focus:border-gray-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch
                  className="absolute left-2.5 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400"
                />
              </div>
              <button
                onClick={() => setShowActive(!showActive)}
                className="h-9 px-3 xs:px-2 sm:px-2 flex items-center gap-2 rounded-md border border-gray-600 bg-gray-600 hover:bg-communityBlue text-white cursor-pointer transition-colors duration-200"
              >
                <FaUserCheck className={`${showActive ? 'text-green-500' : 'text-red-500'}`} />
                <span className="xs:hidden sm:hidden md:inline">
                  {showActive ? 'Active Users' : 'Inactive Users'}
                </span>
              </button>
            </div>
            <button
              className="h-9 px-3 xs:px-2 sm:px-2 flex items-center gap-2 rounded-md bg-gray-600 text-gray-400 cursor-not-allowed relative group"
              disabled
              aria-label="Add new user - Coming soon"
            >
              <span className="xs:hidden sm:hidden md:inline">Add user</span>
              <FaPlus className="text-lg leading-none" />
              <div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                Coming Soon
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="bg-black/40 rounded-xl border border-gray-700 sm:mx-0 -mx-4">
        <div className="grid grid-cols-1 md:grid-cols-[2.5fr,1.5fr,1fr,1fr,40px] gap-4 p-4 border-b border-gray-700 text-sm text-gray-400">
          <div>User</div>
          <div className="hidden md:block">Access</div>
          <div className="hidden md:block">Last active</div>
          <div className="hidden md:block">Date added</div>
          <div></div>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center p-8">
            <LoadingSpinner />
          </div>
        ) : currentItems.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-8 text-gray-400">
            <p className="text-xl">No users found</p>
            <p className="text-sm">Try adjusting your search terms</p>
          </div>
        ) : (
          <div className="divide-y divide-white/5">
            {currentItems.map((user: User) => (
              <div key={user.id} className="grid grid-cols-[1fr,40px] md:grid-cols-[2.5fr,1.5fr,1fr,1fr,40px] gap-4 p-4 items-center hover:bg-gray-800/30 transition-colors duration-200" style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-gray-700">
                    {user.profile_image_url && (
                      <img 
                        src={user.profile_image_url} 
                        alt="" 
                        className="w-full h-full rounded-full object-cover"
                      />
                    )}
                  </div>
                  <div>
                    <div className="text-white">{`${user.first_name} ${user.last_name}`}</div>
                    <div className="text-sm text-gray-400">{user.email}</div>
                  </div>
                </div>
                <div className="hidden md:flex flex-wrap gap-2">
                  {renderRoleBadges(user.profile_roles)}
                </div>
                <div className="hidden md:block text-gray-400">
                  {formatLastActive(user.last_active)}
                </div>
                <div className="hidden md:block text-gray-400">{formatDate(user.created_at)}</div>
                <div className="relative">
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button className="p-2 appearance-none border-none bg-transparent hover:bg-transparent focus:bg-transparent focus:outline-none cursor-pointer">
                      <FaEllipsisV className="text-gray-400 hover:text-communityBlue transition-colors duration-200" />
                    </Menu.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-700 rounded-md bg-gray-600 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => handleEditUser(user.id)}
                                className={`${
                                  active ? 'bg-communityBlue text-white' : 'text-gray-200'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm bg-gray-800 transition-colors duration-200 cursor-pointer`}
                              >
                                <FaUserEdit className="mr-2 h-4 w-4" />
                                Edit User
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => handleDeactivateUser(user.id, user.active)}
                                disabled={isDeactivating}
                                className={`${
                                  active ? 'bg-communityBlue text-white' : 'text-gray-200'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm bg-gray-800 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer`}
                              >
                                <FaUserSlash className="mr-2 h-4 w-4" />
                                {isDeactivating 
                                  ? `${user.active ? 'Deactivating...' : 'Activating...'}`
                                  : `${user.active ? 'Deactivate User' : 'Activate User'}`
                                }
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active, close }) => (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteUser(user.id, close);
                                }}
                                disabled={isDeleting}
                                className={`${
                                  active ? 'bg-red-600 text-white' : 'text-red-400'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm bg-gray-800 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer`}
                              >
                                <FaTrash className={`mr-2 h-4 w-4 ${deleteConfirmationId === user.id ? 'animate-pulse' : ''}`} />
                                {isDeleting 
                                  ? 'Deleting...' 
                                  : deleteConfirmationId === user.id 
                                    ? 'Confirm Delete?' 
                                    : 'Delete User'
                                }
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Pagination */}
        {sortedData.length > 0 && (
          <div className="border-t border-gray-700 px-4 py-3 flex items-center justify-center gap-1">
            {getPageNumbers().map((number) => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={`
                  w-8 h-8 flex items-center justify-center rounded-md text-sm
                  transition-colors duration-200 cursor-pointer
                  ${currentPage === number 
                    ? 'bg-communityBlue text-white' 
                    : 'text-gray-400 hover:bg-communityBlue hover:text-white'
                  }
                `}
                aria-current={currentPage === number ? 'page' : undefined}
              >
                {number}
              </button>
            ))}
          </div>
        )}
      </div>

      <AddUserModal 
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </div>
  );
};

export default Users;
